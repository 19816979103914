import React, { useState, useEffect } from 'react'

import { UPDATE_STAGE3_COST } from '../../../utils/sockets'
import Button from '../../Button'
import Dialog from '../../Dialog'
import InputGroup from '../../InputGroup'

const ViewUploadChangeStage3CreditCostModal = (props) => {
  const { isOpen, setIsOpen, socket, upload, setUpload } = props

  const [newCost, setNewCost] = useState(upload.stage3CreditCost || 0)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setSuccess(null)
      setError(null)
    }
  }, [isOpen])

  const handleChange = (e) => {
    // Remove everything except 0-9
    const input = e.target.value.replace(/[^0-9.]/g, '')
    setNewCost(input)
  }

  const handleSubmit = () => {
    setIsLoading(true)

    socket.emit(
      UPDATE_STAGE3_COST,
      newCost,
      (err, { newBalance, newStatus }) => {
        setIsLoading(false)

        if (typeof newBalance === 'number') {
          setSuccess(true)
          setUpload({
            stage3CreditCost: newCost,
            status: newStatus,
            createdBy: {
              ...upload.createdBy,
              dealer: {
                ...upload.createdBy.dealer,
                credit: {
                  ...upload.createdBy.dealer.credit,
                  balance: newBalance,
                },
              },
            },
          })
          return
        }

        if (err) {
          setError(err)
          return
        }
      }
    )
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          {error.credits
            ? error.credits
            : 'There was an error saving the new cost.'}
        </p>
        <Button className="mt-4" onClick={() => setIsOpen(false)}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Upload cost updated"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          We have successfully updated the credit cost for this upload to{' '}
          {newCost} credit(s).
        </p>
        <Button className="mt-4" onClick={() => setIsOpen(false)}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Change stage 3 credit cost"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Saving...' : 'Save new cost',
        color: 'primary',
      }}
    >
      <div className="mt-2">
        <InputGroup
          required
          label="New cost"
          id="credit cost"
          disabled={isLoading}
          onChange={handleChange}
          value={newCost}
        />
      </div>
    </Dialog>
  )
}

export default ViewUploadChangeStage3CreditCostModal
