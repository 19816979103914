import React from 'react'

import InputGroup from '../../InputGroup'

const SystemStatus = (props) => {
  const { isSaving, onChange, status } = props

  return (
    <div className="border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          System status
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>The system status banner that appears at the top of every page.</p>
        </div>
      </div>
      <div className="flex-1 space-y-4">
        <div className="space-y-4 sm:space-y-0 sm:flex sm:flex-1 sm:space-x-3">
          <InputGroup
            required
            className="flex-1"
            type="select"
            label="Show banner?"
            id="showBanner"
            disabled={isSaving}
            onChange={(e) => onChange({ active: e.target.value === 'true' })}
            value={`${status.active}`}
            renderOptions={() => (
              <>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </>
            )}
          />
          <InputGroup
            required
            className="flex-1"
            type="select"
            label="Banner color"
            id="bannerColor"
            disabled={isSaving}
            onChange={(e) => onChange({ color: e.target.value || null })}
            value={status.color || ''}
            renderOptions={() => (
              <>
                <option value="GREEN">Green</option>
                <option value="ORANGE">Orange</option>
                <option value="RED">Red</option>
              </>
            )}
          />
        </div>
        <InputGroup
          required
          label="Banner text"
          id="bannerText"
          disabled={isSaving}
          onChange={(e) => onChange({ message: e.target.value })}
          value={status.message}
        />
      </div>
    </div>
  )
}

export default SystemStatus
