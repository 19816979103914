import React from 'react'
import cn from 'classnames'
import { colorKeys, grayscaleKeys } from './ColorPaletteKeys'

const ColorPaletteOption = ({ color, checked, active, isLast }) => {
  const colorLevels =
    color in colorKeys ? colorKeys[color] : grayscaleKeys[color]

  return (
    <div
      className={cn('pb-4 overflow-x-auto sm:overflow-visible', {
        'border-b border-gray-200': !isLast,
      })}
    >
      <span className="block uppercase text-xs text-gray-500 tracking-tight font-semibold">
        {color}
      </span>
      <div className="flex items-center mt-1">
        <input
          type="radio"
          className={cn('h-5 w-5 border border-gray-300 mr-6', {
            'ring-2 ring-offset-2 ring-blue-500': active,
          })}
          checked={checked}
          readOnly
        />

        <div className="flex items-center justify-start sm:space-x-1">
          {colorLevels.map((level) => (
            <div
              key={level}
              className={cn(level, 'h-8 w-5 sm:w-8 flex-none sm:rounded-md')}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ColorPaletteOption
