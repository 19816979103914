import React, { useState, useEffect, useContext } from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import AuthContext from '../../../context/AuthContext'
import Title from '../../../components/Title'
import CreditPackageItem from './Package'
import InputGroup from '../../../components/InputGroup'
import Button from '../../../components/Button'

const CreditPackages = () => {
  const [discountCode, setDiscountCode] = useState('')
  const [discount, setDiscount] = useState(null)
  const [discountCodeErr, setDiscountCodeErr] = useState(null)
  const [auth] = useContext(AuthContext)

  const [
    { success, res, error, isLoading },
    { sendRequest, resetSuccess, resetError },
  ] = usePrivateApi()

  useEffect(() => {
    if (success) {
      setDiscount(res.data.discount)
      resetSuccess()
    }
  }, [res, success, resetSuccess])

  useEffect(() => {
    if (error) {
      setDiscountCodeErr('Discount code is invalid')
      resetError()
    }
  }, [error, resetError])

  const { credit } = auth.membership.dealer

  const handleDiscountApply = () => {
    sendRequest({
      url: `/billing/discounts/${encodeURIComponent(discountCode)}`,
    })
  }

  const renderBalance = () => {
    if (credit.balance === 0) {
      return 'You have no credits in your account'
    }

    if (credit.balance === 1) {
      return 'You have 1 credit in your account'
    }

    return <>You have {credit.balance} credits in your account</>
  }

  return (
    <>
      <Title title="Credit packages" />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col shadow-sm">
          <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg px-6 py-4">
            <h5 className="font-semibold tracking-tighter text-lg">
              {renderBalance()}
            </h5>
            {credit.toolPremium && (
              <p className="text-red-600 text-sm">
                Your credit packages cost an additional 20% because you do not
                have an active tool subscription.
              </p>
            )}
          </div>
          <div className="divide-y divide-y-200">
            {credit.packages
              .sort((a, b) => (a.cost > b.cost ? 1 : -1))
              .map((creditPackage) => (
                <CreditPackageItem
                  creditPackage={creditPackage}
                  baseCost={credit.baseCost}
                  key={creditPackage.id}
                  discount={discount}
                />
              ))}
          </div>
          <div className="border-y border-gray-200 flex justify-end py-3 px-4">
            {discount ? (
              <p className="text-green-600 text-sm">
                Code {discount.code} applied - {discount.percent}% off
              </p>
            ) : (
              <>
                {discountCodeErr && (
                  <p className="text-red-600 mr-3">{discountCodeErr}</p>
                )}
                <InputGroup
                  label="Discount code"
                  labelHidden
                  className="w-[280px]"
                  disabled={isLoading}
                  placeholder="Enter discount code"
                  onChange={(e) =>
                    setDiscountCode(e.target.value.toUpperCase())
                  }
                  value={discountCode}
                />
                <Button
                  className="ml-1"
                  color="white"
                  disabled={isLoading}
                  onClick={handleDiscountApply}
                >
                  Apply
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CreditPackages
