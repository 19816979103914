import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'
import { useStripe } from '@stripe/react-stripe-js'

import history from '../../../history'
import Title from '../../../components/Title'
import Button from '../../../components/Button'
import usePrivateApi from '../../../hooks/usePrivateApi'
import AuthContext from '../../../context/AuthContext'
import CheckoutPaymentMethods from './PaymentMethodList'
import CheckoutReviewPurchase from './ReviewPurchase'
import CheckoutSuccessModal from './ModalSuccess'
import CheckoutErrorModal from './ModalError'

const Checkout = ({ location }) => {
  const purchase = _.get(location, 'state.purchase', false)
  const stripe = useStripe()

  const [fetchedMethods, setFetchedMethods] = useState(false)
  const [fetchMethodsError, setFetchMethodsError] = useState(null)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [stripeError, setStripeError] = useState(false)
  const [stripeSuccess, setStripeSuccess] = useState(false)
  const [payPalSuccess, setPayPalSuccess] = useState(false)

  const [clientSecret, setClientSecret] = useState(null)
  const [shouldChargeVat, setShouldChargeVat] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [subtotal, setSubtotal] = useState(null)
  const [vatTotal, setVatTotal] = useState(null)
  const [finalTotal, setFinalTotal] = useState(null)

  const [auth, setAuth] = useContext(AuthContext)

  const [{ res, success, error }, { sendRequest }] = usePrivateApi()

  useEffect(() => {
    if (!purchase) {
      history.push('/credits')
    }
  }, [purchase])

  useEffect(() => {
    sendRequest({
      url: '/billing/credits/checkout',
      method: 'post',
      data: {
        creditPackageId: purchase.creditPackage.id,
        discountId: purchase.discount ? purchase.discount.id : undefined,
      },
    })
  }, [sendRequest, purchase])

  useEffect(() => {
    if (success && !clientSecret) {
      setClientSecret(res.data.clientSecret)
      setShouldChargeVat(res.data.shouldChargeVat)
      setSubtotal(res.data.subtotal)
      setVatTotal(res.data.vatTotal)
      setFinalTotal(res.data.finalTotal)
    }
  }, [success, clientSecret, res])

  const addCreditsToDealer = () => {
    setAuth({
      ...auth,
      membership: {
        ...auth.membership,
        dealer: {
          ...auth.membership.dealer,
          credit: {
            ...auth.membership.dealer.credit,
            balance:
              auth.membership.dealer.credit.balance +
              purchase.creditPackage.credits,
          },
        },
      },
    })
  }

  const handleSubmit = async () => {
    setStripeLoading(true)
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod.id,
    })
    setStripeLoading(false)

    if (result.error) {
      setStripeError(result.error.message)
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        setStripeSuccess(true)
        addCreditsToDealer()
      }
    }
  }

  if (fetchMethodsError || error) {
    return (
      <>
        <Title
          title="Checkout"
          description={`Purchasing ${purchase.creditPackage.name}`}
        />
        <div className="max-w-5xl mx-auto px-4">
          <div className="flex items-center justify-center flex-1 flex-col p-18">
            <FontAwesomeIcon
              icon={faWifiSlash}
              className="text-gray-600"
              size="2x"
            />
            <h3 className="text-xl font-semibold tracking-tighter mt-4">
              Could not load checkout
            </h3>
            <p className="text-sm text-gray-600 mt-1 mb-6">
              Please refresh the page to try again.
            </p>
            <Button color="white" onClick={() => window.location.reload()}>
              Refresh page
            </Button>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Title
        title="Checkout"
        description={`Purchasing ${purchase.creditPackage.name}`}
      />
      <div className="max-w-5xl mx-auto px-4">
        <CheckoutPaymentMethods
          setPaymentMethod={setPaymentMethod}
          setFetchedMethods={setFetchedMethods}
          setFetchMethodsError={setFetchMethodsError}
          setPayPalSuccess={setPayPalSuccess}
          addCreditsToDealer={addCreditsToDealer}
          creditPackageId={purchase.creditPackage.id}
          discountId={purchase.discount ? purchase.discount.id : undefined}
        />
        {fetchedMethods && clientSecret && (
          <>
            <CheckoutReviewPurchase
              purchase={purchase}
              subtotal={subtotal}
              vatTotal={vatTotal}
              finalTotal={finalTotal}
              shouldChargeVat={shouldChargeVat}
              discount={purchase.discount}
            />
            <div className="mt-6 flex items-center justify-end space-x-4">
              <Button to="/credits">Cancel</Button>
              <Button
                color="primary"
                disabled={!stripe || stripeLoading}
                onClick={handleSubmit}
              >
                {stripeLoading ? 'Purchasing...' : 'Confirm purchase'}
              </Button>
            </div>
          </>
        )}
      </div>
      <CheckoutSuccessModal isOpen={stripeSuccess || payPalSuccess} />
      <CheckoutErrorModal
        isOpen={!!stripeError}
        onRetry={() => setStripeError(null)}
        stripeError={stripeError}
      />
    </>
  )
}

export default Checkout
