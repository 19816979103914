import { useState, useReducer, useEffect, useContext, useCallback } from 'react'
import axios from 'axios'

import useLocalStorage from './useLocalStorage'
import AuthContext, { initialAuth } from '../context/AuthContext'

const API_URL = import.meta.env.VITE_API_URL
const _dataMethods = ['delete', 'patch', 'post', 'put']

const initialState = {
  isLoading: false,
  success: null,
  error: null,
  res: { data: {} },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'request_init':
      return {
        ...initialState,
        isLoading: true,
      }

    case 'request_success':
      return {
        ...state,
        isLoading: false,
        success: true,
        res: action.payload,
      }

    case 'request_error':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      }

    case 'reset_success':
      return {
        ...state,
        success: initialState.success,
      }

    case 'reset_error':
      return {
        ...state,
        error: initialState.error,
      }

    default:
      throw new Error(`Invalid action type: ${action.type}`)
  }
}

const usePrivateApi = (initialConfig) => {
  const [config, setConfig] = useState(initialConfig)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [, setAuth] = useContext(AuthContext)
  const [getAuthToken, setAuthToken] = useLocalStorage('authToken')

  const resetError = useCallback(() => dispatch({ type: 'reset_error' }), [])
  const resetSuccess = useCallback(
    () => dispatch({ type: 'reset_success' }),
    []
  )
  const sendRequest = useCallback((newConfig) => setConfig(newConfig), [])

  useEffect(() => {
    let _isMounted = true

    // If we don't have an initialConfig then we don't send the request
    // until a config is set.
    if (!config) {
      return
    }

    if (!config.url) {
      throw new Error('Public api url is required')
    }

    if (config.data && !_dataMethods.includes(config.method)) {
      throw new Error('Data can only be sent with DELETE, PATCH, POST or PUT')
    }

    const _authToken = getAuthToken()

    if (!_authToken) {
      if (_isMounted) {
        setAuth(initialAuth)
      }
    }

    const _axiosConfig = {
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${_authToken}` },
      ...config,
    }

    const send = async () => {
      if (_isMounted) {
        dispatch({ type: 'request_init' })
      }

      try {
        const _res = await axios(_axiosConfig)

        if (_isMounted && _res.data.token) {
          setAuthToken(_res.data.token)
        }

        if (_isMounted) {
          dispatch({ type: 'request_success', payload: _res })
        }
      } catch (_err) {
        if (_err.response) {
          if (_err.response.status === 401) {
            if (_isMounted) {
              setAuthToken(null)
              setAuth(initialAuth)
            }
          }
        }

        if (_isMounted) {
          dispatch({ type: 'request_error', payload: _err })
        }
      }
    }

    send()

    return () => {
      _isMounted = false
    }
  }, [getAuthToken, setAuthToken, setAuth, config])

  return [
    { ...state, config },
    { sendRequest, resetError, resetSuccess },
  ]
}

export default usePrivateApi
