import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import Button from '../../../components/Button'
import Title from '../../../components/Title'
import BoxNav from '../../../components/BoxNav'
import SearchInput from '../../../components/SearchInput'
import DealerListItem from './ListItem'
import TablePagination from '../../../components/TablePagination'
import usePrivateSocket from '../../../hooks/usePrivateSocket'
import usePagination from '../../../hooks/usePagination'
import useObjState from '../../../hooks/useObjState'
import { ACTIVE, ON_HOLD, PENDING, ARCHIVED } from '../../../utils/dealers'
import {
  FETCH_DEALER_LIST,
  DEALER_STATUS_CHANGED,
  NEW_DEALER,
} from '../../../utils/sockets'

const DealerList = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState(null)
  const [memberships, setMemberships] = useState(null)
  const [showErr, setShowErr] = useState(false)
  const [hasSkippedFirst, setHasSkippedFirst] = useState(false)
  const [hasRestoredState, setHasRestoredState] = useState(false)

  const [filters, setFilters] = useObjState({
    search: null,
    status: null,
  })

  const [
    { page, pageSize, ...paginationValues },
    { goToFirst, goToPage, ...paginationActions },
  ] = usePagination(count, {
    page: location?.state?.page || 1,
    pageSizeKey: 'dealers.list.pageSize',
  })

  const [{ inRoom, err }, { socket, resetErr }] = usePrivateSocket(
    '/dealer-list',
    true
  )

  const handleStatusChange = useCallback(
    (status) => {
      if (status !== filters.status) {
        setFilters({ status })

        if (page !== 1) {
          goToPage(1)
        }
      }
    },
    [setFilters, filters.status, goToPage, page]
  )

  const handleSearchChange = useCallback(
    (search) => {
      if (search !== filters.search) {
        setFilters({ search })

        if (page !== 1) {
          goToPage(1)
        }
      }
    },
    [setFilters, filters.search, goToPage, page]
  )

  useEffect(() => {
    if (hasSkippedFirst) {
      if (!hasRestoredState && location.state?.page) {
        goToPage(location.state.page)
        handleStatusChange(location.state.filters.status)
        handleSearchChange(location.state.filters.search)
      }
      setHasRestoredState(true)
    } else {
      setHasSkippedFirst(true)
    }
  }, [
    location.state,
    goToPage,
    hasRestoredState,
    handleSearchChange,
    handleStatusChange,
    hasSkippedFirst,
  ])

  useEffect(() => {
    if (
      inRoom &&
      hasRestoredState &&
      filters.status !== null &&
      filters.search !== null
    ) {
      setMemberships(null)
      setIsLoading(false)

      socket.emit(
        FETCH_DEALER_LIST,
        { page, pageSize, filters },
        (err, { memberships, count }) => {
          setIsLoading(false)

          if (memberships) {
            setMemberships(memberships)
            setCount(count)
          } else {
            setShowErr(true)
          }
        }
      )
    }
  }, [
    inRoom,
    hasRestoredState,
    page,
    pageSize,
    socket,
    filters,
    setMemberships,
  ])

  useEffect(() => {
    if (socket && page === 1) {
      socket.on(NEW_DEALER, (membership) => {
        setMemberships([membership, ...memberships])
      })

      return () => socket.off(NEW_DEALER)
    }
  }, [socket, memberships, page, setMemberships])

  useEffect(() => {
    if (socket) {
      socket.on(DEALER_STATUS_CHANGED, ({ status, dealerId }) => {
        const dealerIndex = memberships.findIndex(
          (x) => x.dealer.id === dealerId
        )
        if (dealerIndex > -1) {
          const newMemberships = [...memberships]
          newMemberships[dealerIndex].dealer.status = status
          setMemberships(newMemberships)
        }
      })

      return () => socket.off(DEALER_STATUS_CHANGED)
    }
  }, [memberships, setMemberships, socket])

  useEffect(() => {
    if (err) {
      setShowErr(true)
      resetErr()
    }
  }, [err, resetErr])

  const boxNavLinks = useMemo(
    () => [
      { to: '/dealers/list/all', label: 'All', key: 'ALL' },
      { to: '/dealers/list/active', label: 'Active', key: ACTIVE },
      { to: '/dealers/list/pending', label: 'Pending', key: PENDING },
      { to: '/dealers/list/on-hold', label: 'On hold', key: ON_HOLD },
      { to: '/dealers/list/archived', label: 'Archived', key: ARCHIVED },
    ],
    []
  )

  const renderDealerList = () => {
    if (showErr) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faWifiSlash}
            className="text-gray-600"
            size="2x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not retrieve dealers
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Please refresh the page to try again.
          </p>
          <Button color="white" onClick={() => window.location.reload()}>
            Refresh page
          </Button>
        </div>
      )
    }

    if (isLoading || memberships === null) {
      return (
        <div className="flex items-center justify-center p-12 flex-1">
          <span className="spinner" />
        </div>
      )
    }

    if (memberships.length < 1) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-600"
            size="3x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not find any dealers
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Try changing your filters or search term.
          </p>
        </div>
      )
    }

    return (
      <>
        <div className="flex flex-wrap relative overflow-x-auto">
          {memberships.map((membership) => (
            <DealerListItem
              page={page}
              pathname={location.pathname}
              filters={filters}
              membership={membership}
              key={membership.id}
            />
          ))}
        </div>
        <div className="py-1">
          <TablePagination
            {...paginationValues}
            {...paginationActions}
            count={count}
            page={page}
            pageSize={pageSize}
            goToPage={goToPage}
            goToFirst={goToFirst}
            scrollOnChange
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Title title="Dealers" />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
            <BoxNav
              current={location.pathname}
              onChange={handleStatusChange}
              links={boxNavLinks}
            />
          </div>
          <div className="border-b border-gray-200 flex py-3 px-4">
            <SearchInput
              placeholder="Search by dealer name, TGT number or owner email"
              onChange={handleSearchChange}
              value={filters.search}
            />
          </div>
          {renderDealerList()}
        </div>
      </div>
    </>
  )
}

export default DealerList
