import React from 'react'
import currency from 'currency.js'

import Button from '../../../../components/Button'

const ViewDealerCreditPackageItem = (props) => {
  const {
    creditPackage,
    setDeletePackageId,
    setEditPackageId,
    baseCost,
    dealer,
  } = props

  const { cost, credits, name } = creditPackage

  const getSaving = () => {
    const pricePerFile = currency(cost).divide(credits)
    const savingPerFile = currency(baseCost).subtract(pricePerFile)
    const savingPercentage = savingPerFile.divide(baseCost).value
    return Math.round(savingPercentage * 100)
  }

  const saving = getSaving()

  return (
    <div className="rounded-lg shadow-sm border border-gray-200 px-4 py-4 sm:flex items-center">
      <h3 className="text-gray-900 text-sm font-medium flex-1">
        {name} {creditPackage.custom && '(Custom)'}
      </h3>
      <p className="text-gray-900 text-sm font-medium flex-1">
        £{currency(creditPackage.cost / 100).format()}{' '}
        <span className="mr-1 text-xs text-gray-600">
          (£
          {currency(
            creditPackage.cost / 100 / creditPackage.credits
          ).format()}{' '}
          PF)
        </span>
        <span className="text-xs text-gray-600">plus taxes</span>
      </p>
      <div className="flex-1">
        {saving ? (
          <p className="text-sm text-green-600 font-medium">Save {saving}%</p>
        ) : (
          <p className="text-sm text-gray-600">No saving</p>
        )}
      </div>
      <div className="mt-2 flex space-x-2 sm:mt-0">
        <Button size="xs" onClick={() => setEditPackageId(creditPackage.id)}>
          Edit
        </Button>
        {dealer.credit.packages.length > 1 && (
          <Button
            size="xs"
            onClick={() => setDeletePackageId(creditPackage.id)}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  )
}

export default ViewDealerCreditPackageItem
