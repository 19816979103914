import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from './Login'
import ResetPassword from './ResetPassword'
import NewPassword from './NewPassword'
import Signup from './Signup'
import SignupPending from './SignupPending'
import SubdealerAccept from './SubdealerAccept'
import BrandingContext from '../context/BrandingContext'

const Auth = () => {
  const [branding] = useContext(BrandingContext)

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/new-password" component={NewPassword} />
      <Route path="/subdealer-accept" component={SubdealerAccept} />
      {branding.isTgt && (
        <>
          <Route path="/signup" component={Signup} />
          <Route path="/signup-pending" component={SignupPending} />
        </>
      )}
    </Switch>
  )
}

export default Auth
