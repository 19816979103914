import React, { useState, useMemo } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import BoxNav from '../../BoxNav'
import ViewTicketTitle from './Title'
import ViewTicketMessages from './Messages'
import ViewTicketFiles from './Files'
import ViewTicketCloseModal from './ModalCloseTicket'
import ViewTicketOpenModal from './ModalOpenTicket'

const ViewTicket = (props) => {
  const {
    isAdmin,
    ticket,
    setTicket,
    hasInitialTicket,
    isLoading,
    isDisconnected,
    match,
    location,
    socket,
    setConnectionErr,
  } = props

  const [showOpenModal, setShowOpenModal] = useState(false)
  const [showCloseModal, setShowCloseModal] = useState(false)

  const boxNavLinks = useMemo(
    () => [
      { to: `${match.url}/messages`, label: 'Messages' },
      { to: `${match.url}/files`, label: 'Uploaded files' },
    ],
    [match.url]
  )

  return (
    <>
      <ViewTicketTitle
        isAdmin={isAdmin}
        ticket={ticket}
        location={location}
        setShowOpenModal={setShowOpenModal}
        setShowCloseModal={setShowCloseModal}
        isDisconnected={isDisconnected}
      />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          {!hasInitialTicket ? (
            <div className="flex items-center justify-center p-12 flex-1">
              <span className="spinner" />
            </div>
          ) : (
            <>
              <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
                <BoxNav current={location.pathname} links={boxNavLinks} />
              </div>
              <Switch>
                <Route
                  path={`${match.url}/messages`}
                  render={() => (
                    <ViewTicketMessages
                      ticket={ticket}
                      setTicket={setTicket}
                      socket={socket}
                      isDisconnected={isDisconnected}
                    />
                  )}
                />
                <Route
                  path={`${match.url}/files`}
                  render={() => (
                    <ViewTicketFiles
                      isAdmin={isAdmin}
                      ticket={ticket}
                      setTicket={setTicket}
                      socket={socket}
                      onError={() => setConnectionErr(true)}
                      isDisconnected={isDisconnected}
                    />
                  )}
                />
                <Redirect
                  to={{
                    pathname: `${match.url}/messages`,
                    state: location.state,
                  }}
                />
              </Switch>
            </>
          )}
        </div>
      </div>
      <ViewTicketOpenModal
        isOpen={showOpenModal}
        setIsOpen={setShowOpenModal}
        ticket={ticket}
        setTicket={setTicket}
        socket={socket}
      />
      <ViewTicketCloseModal
        isOpen={showCloseModal}
        setIsOpen={setShowCloseModal}
        ticket={ticket}
        setTicket={setTicket}
        socket={socket}
      />
    </>
  )
}

export default ViewTicket
