import React, { useState } from 'react'

import usePrivateApi from '../../hooks/usePrivateApi'
import Button from '../../components/Button'
import Dialog from '../../components/Dialog'
import InputGroup from '../../components/InputGroup'

const ConfirmPhone = ({ isOpen }) => {
  const [phone, setPhone] = useState('')

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: '/settings/phone',
      method: 'patch',
      data: { phone },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not save phone number. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Phone number saved"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Your phone number has been saved. Thank you.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      noDismiss
      title="Confirm phone number"
      buttonProps={{
        disabled: isLoading || !phone,
        onClick: handleSubmit,
        children: isLoading ? 'Saving...' : 'Save phone number',
        color: 'primary',
      }}
    >
      <div className="space-y-4 mt-2">
        <p className="text-gray-600 text-sm mt-2">
          Please confirm your phone number to continue using our portal. We
          require your phone number to improve the quality of our support. Thank
          you for your understanding.
        </p>
        <InputGroup
          required
          label="Phone number"
          id="phone"
          disabled={isLoading}
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
      </div>
    </Dialog>
  )
}

export default ConfirmPhone
