import React, { useState } from 'react'
import filesize from 'filesize'
import { format } from 'date-fns'

import Button from '../Button'
import { downloadFromUrl } from '../../utils/files'
import { DOWNLOAD_FILE } from '../../utils/sockets'

const FileListItem = (props) => {
  const { file, socket, isAdmin, isAdminFile } = props
  const [isLoading, setIsLoading] = useState(false)

  const handleOnDownload = () => {
    setIsLoading(true)

    socket.emit(DOWNLOAD_FILE, file.id, (err, url) => {
      if (url) {
        downloadFromUrl({ url, fileName: file.name })
        setIsLoading(false)
      }
    })
  }

  return (
    <div
      className="border border-gray-200 shadow-sm rounded-md px-4 py-3 mb-3 sm:flex sm:items-center sm:justify-between"
      key={file.id}
    >
      <div>
        <p className="font-medium text-sm mb-0.5 break-all pr-6">
          {file.name} ({filesize(file.size)})
        </p>
        <p className="text-xs text-gray-600 mb-4 sm:mb-0">
          Uploaded {format(new Date(file.createdAt), "dd MMM yyyy 'at' hh:mma")}{' '}
          {(!isAdminFile || isAdmin) && (
            <>
              by {file.createdBy.user.firstName} {file.createdBy.user.lastName}
            </>
          )}
        </p>
      </div>
      <Button
        color="white"
        size="xs"
        onClick={handleOnDownload}
        disabled={isLoading}
      >
        {isLoading ? 'Downloading...' : 'Download'}
      </Button>
    </div>
  )
}

export default FileListItem
