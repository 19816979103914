import React, { useState, useEffect } from 'react'

import usePrivateApi from '../../../../../hooks/usePrivateApi'
import PaymentMethod from '../../../../../components/PaymentMethod/PaymentMethod'

const ViewDealerBillingPaymentMethods = (props) => {
  const { dealerId, showContent, setFetchedMethods, setFetchError } = props

  const [paymentMethods, setPaymentMethods] = useState(null)

  const [{ res, success, error }, { sendRequest, resetSuccess, resetError }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({ url: `/admin/dealers/${dealerId}/payment-methods` })
  }, [sendRequest, dealerId])

  useEffect(() => {
    if (success && !showContent) {
      setFetchedMethods(true)
      resetSuccess()

      const { paymentMethods } = res.data

      // Make sure our primary method is always on top of list
      if (res.data.paymentMethods.length > 1) {
        const index = paymentMethods.findIndex((method) => method.primary)
        paymentMethods.splice(0, 0, paymentMethods.splice(index, 1)[0])
      }
      setPaymentMethods(paymentMethods)
    }
  }, [res, success, showContent, setFetchedMethods, resetSuccess])

  useEffect(() => {
    if (error) {
      setFetchedMethods(true)
      setFetchError(true)
      resetError()
    }
  }, [error, resetError, showContent, setFetchError, setFetchedMethods])

  const renderPaymentMethods = () => {
    if (paymentMethods === null) {
      return null
    }

    if (paymentMethods.length < 1) {
      return (
        <div className="mt-4 mb-6 bg-gray-50 border border-gray-100 rounded-md px-4 py-3">
          <p className="text-gray-600 text-sm">
            No payment methods are associated with this dealer.
          </p>
        </div>
      )
    }

    return (
      <div className="space-y-3 max-w-md">
        {paymentMethods.map((method) => (
          <PaymentMethod
            admin
            dealerId={dealerId}
            method={method}
            key={method.id}
          />
        ))}
      </div>
    )
  }

  return (
    <div
      className={
        !showContent
          ? 'hidden'
          : 'border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start'
      }
    >
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          Payment methods{' '}
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            Primary payment methods will be selected automatically when
            purchasing credits or add-ons, however the dealer may use any
            payment method added to their account.
          </p>
          <p>
            Only dealers can add payment methods to their account (for security
            purposes).
          </p>
        </div>
      </div>
      <div className="flex-1">{renderPaymentMethods()}</div>
    </div>
  )
}

export default ViewDealerBillingPaymentMethods
