import { objectToOptions } from './objects'

const OPEN = 'OPEN'
const CLOSED = 'CLOSED'

const statusLookup = {
  OPEN: 'Open',
  CLOSED: 'Closed'
}

const categoryLookup = {
  FILE_ISSUE: 'File issue',
  UPDATING_SUBSCRIPTION: 'Updating your subscription',
  WEBSITE_SUPPORT: 'Website support',
  TOOL_PROBLEM: 'Tool problem',
  FEEDBACK: 'Feedback & ideas',
  OTHER: 'Other'
}

const statusLabelColorLookup = {
  OPEN: 'green',
  CLOSED: 'red',
  ARCHIVED: 'gray'
}

export { statusLookup, categoryLookup, OPEN, CLOSED }

export const getStatus = key => statusLookup[key]
export const getStatusLabelColor = key => statusLabelColorLookup[key]
export const getCategory = key => categoryLookup[key]
export const getCategoryOptions = () => objectToOptions(categoryLookup)
