import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import DataItem from './DataItem'
import { getTool, getTune } from '../../../utils/uploads'
import BrandingContext from '../../../context/BrandingContext'

const toYN = (value) => (value ? 'Yes' : 'No')

const toYPN = (value, meta) => {
  return `${value ? `Yes (${meta || 'None entered'})` : 'No'}`
}

const ViewUploadData = ({ upload }) => {
  const {
    uploadType,
    originalUpload,
    vehicle,
    vehicleType,
    manualVehicle,
    vrm,
    reference,
    mileage,
    tune,
    mods,
    priority,
  } = upload

  const [branding] = useContext(BrandingContext)
  const { primaryColorPalette } = branding

  return (
    <div className="divide-y divide-gray-200">
      <div className="pt-6 pb-4 pl-6">
        <h4 className="font-semibold tracking-tight text-lg mb-0.5">
          Upload type
        </h4>
        <p className="text-gray-600 text-sm">
          Whether this is a full or adjustment remap
          {tune.tuneRequired === 'STAGE3' && ' and the stage 3 credit cost'}
        </p>
        <div className="divide-y divide-gray-200 mt-2">
          <DataItem
            label="Upload type"
            value={uploadType === 'FULL' ? 'Full remap' : 'Adjustment remap'}
          />
          {uploadType === 'ADJUST' && (
            <DataItem
              label="Original upload"
              renderValue={() => (
                <Link
                  className={`text-${primaryColorPalette}-600 hover:underline`}
                  to={`/uploads/view/${originalUpload.id}`}
                >
                  {originalUpload.uploadNumber}
                </Link>
              )}
            />
          )}
          <DataItem label="Priority file" value={toYN(priority)} />
          {tune.tuneRequired === 'STAGE3' && (
            <DataItem
              label="Stage 3 credit cost"
              value={upload.stage3CreditCost || 'To be agreed'}
            />
          )}
        </div>
      </div>
      <div className="pt-6 pb-4 pl-6">
        <h4 className="font-semibold tracking-tight text-lg mb-0.5">
          Vehicle information
        </h4>
        <p className="text-gray-600 text-sm">
          Details on the vehicle from our database
        </p>
        <div className="divide-y divide-gray-200 mt-2">
          <DataItem label="Vehicle type" value={vehicleType} />
          {reference && <DataItem label="Reference" value={reference} />}
          {vrm && <DataItem label="VRM" value={vrm} />}
          {vehicleType === 'CAR' && (
            <>
              <DataItem label="Make" value={vehicle.make} />
              <DataItem label="Model" value={vehicle.model} />
              <DataItem label="Fuel" value={vehicle.fuel} />
              <DataItem label="Engine size" value={`${vehicle.engineSize}cc`} />
              <DataItem label="Start year" value={vehicle.startYear} />
              <DataItem label="End year" value={vehicle.endYear} />
              <DataItem label="Mileage" value={mileage.toLocaleString()} />
              <DataItem label="Vehicle ID" value={vehicle.vehicleId} />
            </>
          )}
          {vehicleType !== 'CAR' && (
            <>
              <DataItem label="Make" value={manualVehicle.make} />
              <DataItem label="Model" value={manualVehicle.model} />
              <DataItem label="Engine size" value={manualVehicle.engineSize} />
              <DataItem label="HP model" value={manualVehicle.hpModel} />
              <DataItem label="Year" value={manualVehicle.year} />
            </>
          )}
        </div>
      </div>
      <div className="pt-6 pb-4 pl-6">
        <h4 className="font-semibold tracking-tight text-lg mb-0.5">
          ECU/Tune required
        </h4>
        <p className="text-gray-600 text-sm">
          Details on ECU, tuning tool and tune required
        </p>
        <div className="divide-y divide-gray-200 mt-2">
          <DataItem label="ECU type" value={tune.ecuType} />
          <DataItem label="Tuning tool used" value={getTool(tune.tuningTool)} />
          {tune.tuningTool === 'OTHER' && (
            <DataItem
              label="Tuning tool (other)"
              value={tune.tuningToolOther}
            />
          )}
          <DataItem label="Tune required" value={getTune(tune.tuneRequired)} />
          <DataItem
            label="Tune required details"
            value={tune.tuneRequiredCustom}
          />
        </div>
      </div>
      <div className="pt-6 pb-4 pl-6">
        <h4 className="font-semibold tracking-tight text-lg mb-0.5">
          Modifications
        </h4>
        <p className="text-gray-600 text-sm">
          Modifications made to the vehicle and any P codes
        </p>
        <div className="divide-y divide-gray-200 mt-2">
          <DataItem label="Air intake" value={toYN(mods.airIntake)} />
          <DataItem label="Decat/DPF delete" value={toYN(mods.decat)} />
          <DataItem label="PPF/OPF/GPF delete" value={toYN(mods.ppfDelete)} />
          <DataItem label="Intercooler" value={toYN(mods.intercooler)} />
          <DataItem label="VMAX off" value={toYN(mods.vmax)} />
          <DataItem label="Rev limiter raised" value={toYN(mods.revLimiter)} />
          <DataItem label="DPF off" value={toYPN(mods.dpf, mods.dpfPCodes)} />
          <DataItem
            label="PPF/OPF/GPF off"
            value={toYPN(mods.ppf, mods.ppfPCodes)}
          />
          <DataItem label="EGR off" value={toYPN(mods.egr, mods.egrPCodes)} />
          <DataItem label="O2 off" value={toYPN(mods.o2, mods.o2PCodes)} />
          <DataItem
            label="Adblue delete"
            value={toYPN(mods.adblue, mods.adbluePCodes)}
          />
          <DataItem
            label="Hardcut limiter"
            value={toYPN(mods.hardcut, `${mods.hardcutRpm} RPM`)}
          />
          <DataItem label="EGT off" value={toYPN(mods.egt, mods.egtPCodes)} />
          <DataItem
            label="Swirl flap off"
            value={toYPN(mods.swirlFlap, mods.swirlFlapPCodes)}
          />
        </div>
      </div>
    </div>
  )
}

export default ViewUploadData
