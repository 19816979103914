import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AuthContext from '../../context/AuthContext'
import TicketList from '../../components/support/list'
import NewTicket from '../../components/support/new'
import ViewTicket from '../../components/support/view'
import SupportFaqs from './faqs'
import { ON_HOLD } from '../../utils/dealers'

const Support = ({ location }) => {
  const [auth] = useContext(AuthContext)

  return (
    <Switch>
      <Route
        path={[
          '/support/list/open',
          '/support/list/closed',
          '/support/list/archived',
          '/support/list/all',
        ]}
        render={(props) => <TicketList {...props} />}
      />
      <Route path="/support/faqs" component={SupportFaqs} />
      <Route path="/support/view/:ticketId" component={ViewTicket} />
      {auth.membership.dealer.status !== ON_HOLD && (
        <Route path="/support/new" component={NewTicket} />
      )}
      <Redirect to={{ pathname: '/support/list/all', state: location.state }} />
    </Switch>
  )
}

export default Support
