import React from 'react'

import InputGroup from '../../InputGroup'

const AccountPassword = ({
  passwords,
  setPasswords,
  disabled,
  currentPasswordError,
  newPasswordError,
  confPasswordError,
}) => {
  return (
    <div className="border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          Your password
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>Change the password you use to login to your account.</p>
        </div>
      </div>
      <div className="flex-1 space-y-4">
        <InputGroup
          label="Current password"
          id="currentPassword"
          type="password"
          error={currentPasswordError}
          disabled={disabled}
          onChange={(e) => setPasswords({ current: e.target.value })}
          value={passwords.current}
        />
        <InputGroup
          label="New password"
          id="newPassword"
          type="password"
          error={newPasswordError}
          disabled={disabled}
          onChange={(e) => setPasswords({ new: e.target.value })}
          value={passwords.new}
        />
        <InputGroup
          label="Confirm new password"
          id="newPasswordConf"
          type="password"
          error={confPasswordError}
          disabled={disabled}
          onChange={(e) => setPasswords({ conf: e.target.value })}
          value={passwords.conf}
        />
      </div>
    </div>
  )
}

export default AccountPassword
