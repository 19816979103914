import React, { useState, useEffect, useContext } from 'react'
import {
  faCreditCard,
  faUpload,
  faTicketAlt,
} from '@fortawesome/pro-regular-svg-icons'

import { FETCH_AUTO_POP_UP } from '../../utils/sockets'
import usePrivateSocket from '../../hooks/usePrivateSocket'
import useSessionStorage from '../../hooks/useSessionStorage'
import AuthContext from '../../context/AuthContext'
import DashboardAction from './DashboardAction'
import RecentUploadList from './RecentUploadList'
import AutoPopUp from '../../components/AutoPopUp'
import BrandingContext from '../../context/BrandingContext'

const Dashboard = () => {
  const [hasFetched, setHasFetched] = useState(false)
  const [autoPopUp, setAutoPopUp] = useState(null)
  const [getAutoPopUpViewed, setAutoPopUpViewed] =
    useSessionStorage('autoPopUpViewed')
  const [autoPopUpOpen, setAutoPopUpOpen] = useState(false)
  const [auth] = useContext(AuthContext)
  const [branding] = useContext(BrandingContext)
  const { isTgt } = branding
  const isMaster = auth.membership.dealer.type === 'MASTER'

  const [{ inRoom }, { socket }] = usePrivateSocket('/system', true)

  useEffect(() => {
    if (!hasFetched && inRoom && isTgt) {
      setHasFetched(true)

      socket.emit(FETCH_AUTO_POP_UP, (err, { autoPopUp }) => {
        setAutoPopUp(autoPopUp)
        setAutoPopUpOpen(getAutoPopUpViewed() !== 'true' && autoPopUp.active)
      })
    }
  }, [socket, hasFetched, inRoom, getAutoPopUpViewed, isTgt])

  return (
    <div>
      <div
        className={
          isMaster
            ? 'bg-white border-b border-gray-200 pt-4 pb-8 mb-4'
            : 'bg-white border-b border-gray-200 py-4'
        }
      >
        <div className="max-w-5xl mx-auto px-4">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">
            Dashboard
          </h1>
          {isMaster && (
            <div className="space-y-3 pt-4 md:space-y-0 md:flex md:space-x-4 ">
              <DashboardAction
                title="Purchase credits"
                desc="Get started by purchasing one of our credit bundles."
                icon={faCreditCard}
                to="/credits"
                linkText="Purchase"
              />
              <DashboardAction
                title="Upload a file"
                desc="Upload an ECU file and choose your remap options."
                icon={faUpload}
                to="/uploads/new"
                linkText="Upload"
              />
              <DashboardAction
                title="Create a support ticket"
                desc="Raise an issue or ask for help by creating a ticket."
                icon={faTicketAlt}
                to="/support/faqs"
                linkText="Create"
              />
            </div>
          )}
        </div>
      </div>
      <RecentUploadList isMaster={isMaster} />
      {autoPopUp && (
        <AutoPopUp
          isOpen={autoPopUpOpen}
          autoPopUp={autoPopUp}
          setIsOpen={() => {
            setAutoPopUpViewed('true')
            setAutoPopUpOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default Dashboard
