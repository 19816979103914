import React from 'react'
import cn from 'classnames'
import { Popover as HeadlessPopover, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'

const Popover = (props) => {
  const {
    renderButton,
    buttonText,
    renderPanel,
    renderContent,
    panelSize = 'md',
    customPanelSize,
  } = props

  const panelCn = cn(
    'absolute right-4 left-4 z-10 mt-3 origin-right transform shadow-lg',
    'bg-white rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none',
    'sm:right-0 sm:left-auto',
    {
      [customPanelSize]: customPanelSize,
      'sm:max-w-xs sm:w-screen': panelSize === 'sm' && !customPanelSize,
      'sm:max-w-sm sm:w-screen': panelSize === 'md' && !customPanelSize,
    }
  )

  return (
    <HeadlessPopover className="sm:relative">
      {({ open }) => (
        <>
          {renderButton ? (
            renderButton({ PopoverButton: HeadlessPopover.Button, open })
          ) : (
            <HeadlessPopover.Button className="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              {buttonText}
              <FontAwesomeIcon
                icon={faChevronDown}
                className="ml-2 -mr-1 h-5 w-5 text-blue-200 hover:text-blue-100"
                aria-hidden="true"
              />
            </HeadlessPopover.Button>
          )}
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            {renderPanel ? (
              renderPanel({ PopoverPanel: HeadlessPopover.Panel, open })
            ) : (
              <HeadlessPopover.Panel className={panelCn}>
                {({ close }) => renderContent({ open, close })}
              </HeadlessPopover.Panel>
            )}
          </Transition>
        </>
      )}
    </HeadlessPopover>
  )
}

export default Popover
