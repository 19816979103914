import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'

import history from '../../../history'
import useObjState from '../../../hooks/useObjState'
import usePrivateSocket from '../../../hooks/usePrivateSocket'
import ViewUploadData from './Upload'
import ViewUploadError from './Error'
import AuthContext from '../../../context/AuthContext'
import {
  IN_PROGRESS,
  READY,
  DOWNLOADED,
  RE_CREDITED,
  ON_HOLD,
} from '../../../utils/uploads'
import {
  FETCH_UPLOAD,
  NEW_MESSAGE,
  NEW_FILES,
  UPLOAD_IN_PROGRESS,
  UPLOAD_RE_CREDITED,
  UPLOAD_READY,
  UPLOAD_DOWNLOADED,
  UPLOAD_ON_HOLD,
  NEW_ASSIGNMENT,
  UPDATE_STAGE3_COST,
} from '../../../utils/sockets'

const ViewUpload = ({ location, match, isAdmin }) => {
  const [connectionErr, setConnectionErr] = useState(null)
  const [hasInitialUpload, setHasInitialUpload] = useState(false)
  const [hasFetchedUpload, setHasFetchedUpload] = useState(false)
  const [isFetchingUpload, setIsFetchingUpload] = useState(true)
  const [auth] = useContext(AuthContext)

  const [upload, setUpload] = useObjState({
    id: match.params.uploadId,
    vehicle: {
      vehicleId: _.get(location, 'state.upload.vehicle.vehicleId', ''),
      make: _.get(location, 'state.upload.vehicle.make', ''),
      model: _.get(location, 'state.upload.vehicle.model', ''),
      fuel: _.get(location, 'state.upload.vehicle.fuel', ''),
      startYear: _.get(location, 'state.upload.vehicle.startYear', ''),
      endYear: _.get(location, 'state.upload.vehicle.endYear', ''),
      engineSize: _.get(location, 'state.upload.vehicle.engineSize', ''),
    },
    vehicleType: _.get(location, 'state.upload.vehicleType', ''),
    uploadType: _.get(location, 'state.upload.uploadType', ''),
    manualVehicle: {
      make: _.get(location, 'state.upload.manualVehicle.make', ''),
      model: _.get(location, 'state.upload.manualVehicle.model', ''),
      engineSize: _.get(location, 'state.upload.manualVehicle.engineSize', ''),
      hpModel: _.get(location, 'state.upload.manualVehicle.hpModel', ''),
      year: _.get(location, 'state.upload.manualVehicle.year', ''),
    },
    vrm: _.get(location, 'state.upload.vrm', ''),
    reference: _.get(location, 'state.upload.reference', ''),
    mileage: _.get(location, 'state.upload.vehicle.mileage', ''),
    tune: {
      ecuType: _.get(location, 'state.upload.tune.ecuType', ''),
      tuningTool: _.get(location, 'state.upload.tune.tuningTool', null),
      tuningToolOther: _.get(location, 'state.upload.tune.tuningToolOther', ''),
      tuneRequired: _.get(location, 'state.upload.tune.tuneRequired', null),
      tuneRequiredCustom: _.get(
        location,
        'state.upload.tune.tuneRequiredCustom',
        ''
      ),
    },
    mods: {
      airIntake: _.get(location, 'state.upload.mods.airIntake', false),
      decat: _.get(location, 'state.upload.mods.decat', false),
      intercooler: _.get(location, 'state.upload.mods.intercooler', false),
      vmax: _.get(location, 'state.upload.mods.vmax', false),
      revLimiter: _.get(location, 'state.upload.mods.revLimiter', false),
      dpf: _.get(location, 'state.upload.mods.dpf', false),
      dpfPCodes: _.get(location, 'state.upload.mods.dpfPCodes', ''),
      egr: _.get(location, 'state.upload.mods.egr', false),
      egrPCodes: _.get(location, 'state.upload.mods.egrPCodes', ''),
      o2: _.get(location, 'state.upload.mods.o2', false),
      o2PCodes: _.get(location, 'state.upload.mods.o2PCodes', ''),
      adblue: _.get(location, 'state.upload.mods.adblue', false),
      adbluePCodes: _.get(location, 'state.upload.mods.adbluePCodes', ''),
    },
    addons: _.get(location, 'state.upload.addons', []),
    ecuWarranty: _.get(location, 'state.upload.ecuWaranty', null),
    priority: _.get(location, 'state.upload.priority', []),
    files: _.get(location, 'state.upload.files', []),
    messages: _.get(location, 'state.upload.messages', []),
    uploadNumber: _.get(location, 'state.upload.uploadNumber', ''),
    status: _.get(location, 'state.upload.status', null),
    createdBy: _.get(location, 'state.upload.createdBy', ''),
  })

  const [{ inRoom, err, connErr }, { socket, resetErr }] = usePrivateSocket(
    '/upload',
    match.params.uploadId
  )

  useEffect(() => {
    // match.params.uploadId has changed
    if (!inRoom && !connErr && hasFetchedUpload) {
      setHasFetchedUpload(false)
      setIsFetchingUpload(true)

      if (upload.id !== match.params.uploadId) {
        setHasInitialUpload(false)
      }
    }
  }, [inRoom, connErr, hasFetchedUpload, upload, match.params.uploadId])

  useEffect(() => {
    if (inRoom && !hasFetchedUpload) {
      setHasFetchedUpload(true)

      socket.emit(FETCH_UPLOAD, (err, _upload) => {
        if (_upload) {
          setUpload({ ...upload, ..._upload })
          setIsFetchingUpload(false)
          setHasInitialUpload(true)
        } else {
          setConnectionErr(true)
        }
      })
    }
  }, [inRoom, socket, upload, setUpload, hasFetchedUpload])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_MESSAGE, (message) => {
        setUpload({ messages: [...upload.messages, message] })
      })

      return () => socket.off(NEW_MESSAGE)
    }
  }, [socket, upload, setUpload])

  useEffect(() => {
    if (socket && isAdmin && auth.membership.accountType === 'ADMIN') {
      socket.on(NEW_ASSIGNMENT, (assignedTo) => {
        setUpload({ assignedTo })
      })

      return () => socket.off(NEW_ASSIGNMENT)
    }
  }, [socket, upload, setUpload, isAdmin, auth])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_FILES, (files) => {
        setUpload({ files })
      })

      return () => socket.off(NEW_FILES)
    }
  }, [socket, setUpload])

  useEffect(() => {
    if (socket) {
      socket.on(UPLOAD_IN_PROGRESS, () => {
        setUpload({ status: IN_PROGRESS })
      })

      return () => socket.off(UPLOAD_IN_PROGRESS)
    }
  }, [socket, setUpload])

  useEffect(() => {
    if (socket) {
      socket.on(UPLOAD_READY, () => {
        setUpload({ status: READY })
      })

      return () => socket.off(UPLOAD_READY)
    }
  }, [socket, setUpload])

  useEffect(() => {
    if (socket) {
      socket.on(UPLOAD_DOWNLOADED, () => {
        setUpload({ status: DOWNLOADED })
      })

      return () => socket.off(UPLOAD_DOWNLOADED)
    }
  }, [socket, setUpload])

  useEffect(() => {
    if (socket) {
      socket.on(UPLOAD_RE_CREDITED, (addons) => {
        setUpload({ status: RE_CREDITED, addons })
      })

      return () => socket.off(UPLOAD_RE_CREDITED)
    }
  }, [socket, setUpload])

  useEffect(() => {
    if (socket) {
      socket.on(UPDATE_STAGE3_COST, ({ newCost, newStatus }) => {
        setUpload({
          status: newStatus,
          stage3CreditCost: newCost,
        })
      })

      return () => socket.off(UPDATE_STAGE3_COST)
    }
  }, [socket, setUpload])

  useEffect(() => {
    if (socket) {
      socket.on(UPLOAD_ON_HOLD, () => {
        setUpload({ status: ON_HOLD })
      })

      return () => socket.off(UPLOAD_ON_HOLD)
    }
  }, [socket, setUpload])

  useEffect(() => {
    if (err) {
      // We couldn't connect to server
      setConnectionErr(true)
      resetErr()
    }
  }, [err, resetErr])

  useEffect(() => {
    const interceptBackButton = () => {
      if (document.location.pathname.includes('/uploads/list')) {
        if (location?.state?.listState) {
          const { page, pathname, filters } = location.state.listState
          history.push(pathname, { page, filters })
        }
      }
      window.removeEventListener('popstate', interceptBackButton)
    }

    window.addEventListener('popstate', interceptBackButton)
  }, [location])

  if (connectionErr) {
    return <ViewUploadError upload={upload} />
  }

  return (
    <ViewUploadData
      isAdmin={isAdmin}
      upload={upload}
      setUpload={setUpload}
      hasInitialUpload={hasInitialUpload}
      isLoading={isFetchingUpload}
      isDisconnected={connErr}
      match={match}
      location={location}
      socket={socket}
      setConnectionErr={setConnectionErr}
    />
  )
}

export default ViewUpload
