import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import cn from 'classnames'
import { format, isToday, isYesterday } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faDownload } from '@fortawesome/pro-solid-svg-icons'
import Linkify from 'linkifyjs/react'
import {
  faUpload,
  faCameraAlt,
  faLaughBeam,
} from '@fortawesome/pro-light-svg-icons'

import AuthContext from '../context/AuthContext'
import Textarea from './Textarea'
import { EmojiPicker } from './Emoji'
import Button from './Button'
import BrandingContext from '../context/BrandingContext'
import FileUploadModal from './FileUploadModal'
import { UPLOAD_FILES, DOWNLOAD_FILE } from '../utils/sockets'
import { downloadFromUrl } from '../utils/files'

const getFormattedDate = (date) => {
  if (isToday(date)) {
    return 'Today'
  }

  if (isYesterday(date)) {
    return 'Yesterday'
  }

  return format(date, 'dd MMMM yyy')
}

const getPosition = (fromDealer, accountType) => {
  if (accountType === 'USER') {
    if (fromDealer) {
      return 'right'
    } else {
      return 'left'
    }
  }

  if (fromDealer) {
    return 'left'
  } else {
    return 'right'
  }
}

const MessageItem = (props) => {
  const {
    position,
    fromAdmin,
    isAdmin,
    message,
    messageType,
    fileId,
    socket,
    fileName,
    createdAt,
    createdBy,
    primaryColorPalette,
  } = props

  const [isDownloading, setIsDownloading] = useState(false)
  const [branding] = useContext(BrandingContext)
  const { masterDealerName, isTgt } = branding

  const handleOnDownload = () => {
    setIsDownloading(true)

    socket.emit(DOWNLOAD_FILE, fileId, (err, url) => {
      if (url) {
        downloadFromUrl({ url, fileName: fileName })
        setIsDownloading(false)
      }
    })
  }

  const className = cn('flex flex-col max-w-[75%] flex-1 flex-shrink-0 mb-3', {
    'mr-auto justify-start': position === 'left',
    'ml-auto justify-end': position === 'right',
  })

  if (messageType === 'SYSTEM') {
    return (
      <div className={className}>
        <div
          className={cn(
            'rounded-md shadow-sm border-2 whitespace-pre-wrap leading-5 text-sm',
            {
              'bg-gray-600 border-gray-600 text-white': position === 'left',
              [`bg-${primaryColorPalette}-600 border-${primaryColorPalette}-600 text-white`]:
                position === 'right',
            }
          )}
          type="button"
        >
          <button
            className={cn(
              'appearance-none text-left w-full min-w-0 select-none',
              'flex flex-col min-h-[100px] rounded-[4px] min-w-[125px]',
              {
                'bg-gray-100 hover:bg-gray-200 focus:bg-gray-200':
                  position === 'left',
                [`bg-${primaryColorPalette}-100 hover:bg-${primaryColorPalette}-200 focus:bg-${primaryColorPalette}-200`]:
                  position === 'right',
              }
            )}
            type="button"
            onClick={handleOnDownload}
          >
            <p
              className={cn('text-xs rounded-[4px] font-medium pl-1 pt-1', {
                'text-gray-800': position === 'left',
                [`text-${primaryColorPalette}-700`]: position === 'right',
              })}
            >
              File uploaded
            </p>
            <span className="w-full flex items-center justify-center flex-col flex-1 px-4">
              <FontAwesomeIcon
                icon={faDownload}
                className={cn('text-2xl', {
                  'text-gray-800': position === 'left',
                  [`text-${primaryColorPalette}-600`]: position === 'right',
                })}
              />
              <p
                className={cn(
                  'w-full text-center text-xs pt-2 truncate relative',
                  {
                    'text-gray-800': position === 'left',
                    [`text-${primaryColorPalette}-700`]: position === 'right',
                  }
                )}
              >
                <span className={isDownloading ? 'opacity-0' : ''}>
                  {fileName}
                </span>
                {isDownloading && (
                  <span className="absolute left-0 right-0">
                    Downloading...
                  </span>
                )}
              </p>
            </span>
          </button>
        </div>
        <div
          className={cn('flex text-xs mt-1', {
            'flex-row-reverse': position === 'right',
          })}
        >
          <p>{format(createdAt, 'hh:mma')}</p>
          <p className="mx-1 text-gray-500 mb-0.5">•</p>
          {fromAdmin && !isAdmin ? (
            <p>Sent by {isTgt ? 'Topgear Support' : masterDealerName}</p>
          ) : (
            <p>
              Sent by {createdBy.user.firstName} {createdBy.user.lastName}
            </p>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      <Linkify
        className={cn(
          'rounded-md shadow-sm py-1.5 pr-5 pl-3 whitespace-pre-wrap leading-5 text-sm',
          {
            'bg-gray-600 text-white': position === 'left',
            [`bg-${primaryColorPalette}-600 text-white`]: position === 'right',
          }
        )}
        tagName="span"
        options={{ className: 'text-white underline' }}
      >
        {message}
      </Linkify>
      <div
        className={cn('flex text-xs mt-1', {
          'flex-row-reverse': position === 'right',
        })}
      >
        <p>{format(createdAt, 'hh:mma')}</p>
        <p className="mx-1 text-gray-500 mb-0.5">•</p>
        {fromAdmin && !isAdmin ? (
          <p>Sent by {isTgt ? 'Topgear Support' : masterDealerName}</p>
        ) : (
          <p>
            Sent by {createdBy.user.firstName} {createdBy.user.lastName}
          </p>
        )}
      </div>
    </div>
  )
}

export const Messages = (props) => {
  const {
    currentMessage,
    setCurrentMessage,
    messages = [],
    onMessageSend,
    isMessageLoading,
    isDisconnected,
    isDisabled,
    isDisabledMessage,
    isSupport,
    socket,
    remoteTyping,
    onChangeText,
    onNewFiles,
    // onNewFilesError,
  } = props

  const [auth] = useContext(AuthContext)
  const [branding] = useContext(BrandingContext)

  const { accountType } = auth.membership
  const isAdmin = accountType !== 'USER'

  const { primaryColorPalette, isTgt, masterDealerName } = branding

  const [cursorPos, setCursorPos] = useState(null)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const textareaRef = useRef(null)

  // Preserve the cursor position in the textarea.
  // We cannot do this onChange as react resets the cursor position
  useEffect(() => {
    if (cursorPos) {
      textareaRef.current.selectionStart = cursorPos.start + 2
      textareaRef.current.selectionEnd = cursorPos.end + 2
    }
  }, [cursorPos])

  const groupedMessages = useMemo(() => {
    return messages
      .map((message) => {
        return {
          ...message,
          position: getPosition(message.createdBy.dealer, accountType),
          fromAdmin: !message.createdBy.dealer,
          isAdmin,
          createdAt: new Date(message.createdAt),
        }
      })
      .sort((a, b) => b.createdAt - a.createdAt)
      .reduce((acc, message) => {
        const date = getFormattedDate(message.createdAt)

        const dateIndex = acc.findIndex((x) => x.date === date)

        if (dateIndex > -1) {
          const accCopy = [...acc]
          accCopy[dateIndex].messages.unshift(message)
          return accCopy
        }

        return [...acc, { date, messages: [message] }]
      }, [])
  }, [messages, isAdmin, accountType])

  const handleOnSubmit = (e) => {
    if (e) {
      e.preventDefault()
    }

    if (currentMessage !== '' && !isMessageLoading) {
      onMessageSend()
    }
  }

  const handleKeyPress = (e) => {
    // Enter => Send, Enter + SHIFT => New line
    if (e.which === 13 && !e.shiftKey) {
      handleOnSubmit()
      e.preventDefault()
    }
  }

  const handleEmojiOpen = () => {
    textareaRef.current.focus()
  }

  const handleEmojiSelect = (emoji) => {
    const { selectionStart, selectionEnd } = textareaRef.current

    const textareaStrParts = [
      `${currentMessage.substring(0, selectionStart)}`,
      `${emoji.native}`,
      `${currentMessage.substring(selectionEnd, currentMessage.length)}`,
    ]
    setCurrentMessage(textareaStrParts.join(''))

    textareaRef.current.focus()
    setCursorPos({ start: selectionStart, end: selectionEnd })
  }

  const getBucket = () => {
    if (isSupport) {
      return 'SUPPORT'
    }

    return 'UPLOADS'
  }

  const handleFileUploadComplete = (files) => {
    if (files.length > 0) {
      const fileIds = files.map((file) => file.id)

      return socket.emit(UPLOAD_FILES, fileIds, (err, allFiles) => {
        if (allFiles) {
          onNewFiles(allFiles)
          setUploadModalOpen(false)
        }

        if (err) {
          //onNewFilesError()
        }
      })
    }

    return setUploadModalOpen(false)
  }

  return (
    <div>
      <div className="message-bg">
        {groupedMessages.length > 0 ? (
          groupedMessages.map((group) => {
            return (
              <div key={group.date}>
                <div className="flex flex-col max-w-[850px] mx-auto w-full items-center px-4">
                  <span
                    className={`bg-${primaryColorPalette}-50 rounded-md shadow-sm border-2 border-${primaryColorPalette}-600 text-${primaryColorPalette}-800 my-5 text-xs px-2 py-1 text-center`}
                  >
                    {group.date}
                  </span>
                  {group.messages.map((message) => (
                    <MessageItem
                      {...message}
                      key={message.id}
                      primaryColorPalette={primaryColorPalette}
                      socket={socket}
                    />
                  ))}
                </div>
              </div>
            )
          })
        ) : (
          <div className="flex items-center flex-col">
            <span
              className={`bg-${primaryColorPalette}-50 rounded-md shadow-sm border-2 border-${primaryColorPalette}-600 text-${primaryColorPalette}-800 my-5 text-xs px-2 py-1 text-center`}
            >
              No message history
            </span>
          </div>
        )}
      </div>
      <div className="border-t border-gray-200 px-4 py-3">
        {isDisconnected && (
          <span className="text-xs text-red-700 block mb-2">
            You are currently disconnected. Trying to connect...
          </span>
        )}
        {remoteTyping?.isTyping &&
          (remoteTyping.isAdmin && !isAdmin ? (
            <span className="text-xs text-gray-600 block mb-2">
              {isTgt ? 'Topgear Support' : masterDealerName} is typing...
            </span>
          ) : (
            <span className="text-xs text-gray-600 block mb-2">
              {remoteTyping.user.firstName} {remoteTyping.user.lastName} is
              typing...
            </span>
          ))}
        {!isDisabled ? (
          <form
            className="sm:flex sm:items-end sm:space-x-3"
            onSubmit={handleOnSubmit}
          >
            <div className="hidden sm:space-x-2 sm:flex sm:items-start">
              <div className="relative">
                <EmojiPicker
                  delayMount
                  onOpen={handleEmojiOpen}
                  onSelect={handleEmojiSelect}
                >
                  <Button color="white" type="button">
                    <FontAwesomeIcon
                      icon={faLaughBeam}
                      className="text-gray-600 hover:text-gray-700"
                      size="lg"
                    />
                  </Button>
                </EmojiPicker>
              </div>
              <Button
                color="white"
                type="button"
                onClick={() => setUploadModalOpen(true)}
              >
                <FontAwesomeIcon
                  icon={faUpload}
                  className="text-gray-600 hover:text-gray-700"
                  size="lg"
                />
              </Button>
            </div>
            <div className="flex-1">
              <Textarea
                autoFocus
                autoComplete="off"
                spellCheck
                onChange={(e) => {
                  setCurrentMessage(e.target.value)

                  if (onChangeText) {
                    onChangeText(e.target.value)
                  }
                }}
                onKeyPress={handleKeyPress}
                placeholder="Type a message"
                type="text"
                label="Type message"
                labelHidden
                minRows={1}
                inputRef={textareaRef}
                value={currentMessage}
              />
            </div>
            <Button
              color="primary"
              className="w-full justify-center mt-3 sm:mt-0 sm:w-auto"
              disabled={isMessageLoading || currentMessage === ''}
              type="submit"
              icon={faArrowUp}
              iconTrailing
            >
              {isMessageLoading ? 'Sending...' : 'Send'}
            </Button>
            <div className="sm:hidden">
              <Button
                className="w-full justify-center mt-3"
                icon={faCameraAlt}
                type="button"
                onClick={() => setUploadModalOpen(true)}
              >
                Add attachment/Upload photo
              </Button>
            </div>
          </form>
        ) : (
          <p className="text-gray-600 text-sm">{isDisabledMessage}</p>
        )}
      </div>
      <FileUploadModal
        isOpen={uploadModalOpen}
        setIsOpen={() => setUploadModalOpen(false)}
        onUploadComplete={handleFileUploadComplete}
        bucket={getBucket()}
      />
    </div>
  )
}
