import React, { useState } from 'react'

import usePrivateApi from '../../../../../hooks/usePrivateApi'
import Button from '../../../../../components/Button'
import Dialog from '../../../../../components/Dialog'
import InputGroup from '../../../../../components/InputGroup'

const EditToolModal = ({ tool, isOpen, setIsOpen, dealer }) => {
  const [serialNumber, setSerialNumber] = useState(tool.serialNumber)
  const [nextExpiry, setNextExpiry] = useState(new Date(tool.nextExpiry))

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/admin/dealers/${dealer.id}/tools/${tool.id}`,
      method: 'patch',
      data:
        tool.autoRenewOn || tool.key === 'AUTOTUNER'
          ? { serialNumber }
          : { serialNumber, nextExpiry },
    })
  }

  const renderError = () => {
    if (error) {
      return (
        <p className="text-red-600 mt-2 text-sm">
          Could not save tool. Please try again later.
        </p>
      )
    }
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        {renderError()}
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Changes saved"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          The changes to the tool details have been saved.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Edit tool"
      buttonProps={{
        disabled: isLoading || !serialNumber || !nextExpiry,
        onClick: handleSubmit,
        children: isLoading ? 'Saving...' : 'Save tool',
        color: 'primary',
      }}
    >
      <div className="space-y-4 mt-2">
        {tool.key !== 'AUTOTUNER' ? (
          <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
            <InputGroup
              required
              className="flex-1"
              label="Serial number"
              id="serialNumber"
              disabled={isLoading}
              onChange={(e) => setSerialNumber(e.target.value)}
              value={serialNumber}
            />
            <InputGroup
              required
              inModal
              className="flex-1"
              type="date"
              label="Next subscription expiry date"
              id="nextExpiry"
              help={
                tool.autoRenewOn
                  ? 'Turn off auto-renew to edit'
                  : 'Must be a date in the future'
              }
              disabled={isLoading || tool.autoRenewOn}
              onChange={(date) => setNextExpiry(date)}
              value={nextExpiry}
            />
          </div>
        ) : (
          <InputGroup
            required
            label="Serial number"
            id="serialNumber"
            disabled={isLoading}
            onChange={(e) => setSerialNumber(e.target.value)}
            value={serialNumber}
          />
        )}
      </div>
    </Dialog>
  )
}

export default EditToolModal
