import React, { useState, useEffect } from 'react'

import { DELETE_DEALER_CREDIT_PACKAGE } from '../../../../utils/sockets'
import Button from '../../../../components/Button'
import Dialog from '../../../../components/Dialog'

const ViewDealerCreditDeletePackageModal = (props) => {
  const { isOpen, setIsOpen, socket, creditPackage, onSuccess } = props

  const [packageName, setPackageName] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setError(null)
      setSuccess(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && !success && creditPackage) {
      setPackageName(creditPackage.name)
    }
  }, [isOpen, creditPackage, success])

  const handleSubmit = () => {
    setIsLoading(true)

    socket.emit(
      DELETE_DEALER_CREDIT_PACKAGE,
      creditPackage.id,
      (err, dealerCredit) => {
        setIsLoading(false)

        if (dealerCredit) {
          setSuccess(true)
          onSuccess(dealerCredit)
          return
        }

        if (err) {
          setError(err)
          return
        }
      }
    )
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          There was an error deleting the credit package.
        </p>
        <Button className="mt-4" onClick={setIsOpen}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Delete credit package"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Deleting...' : 'Delete package',
        color: 'red',
      }}
    >
      <p className="text-gray-600 text-sm mt-2">
        <strong>
          Are you sure you wish to delete the credit package: {packageName}?
        </strong>
      </p>
      <p className="text-gray-600 text-sm mt-2">
        The dealer won't be able to purchase this credit package once deleted.
      </p>
      <p className="text-red-600 text-sm mt-2">This action is irreversible.</p>
    </Dialog>
  )
}

export default ViewDealerCreditDeletePackageModal
