import { useState, useCallback } from 'react'

const useObjState = (initialState = {}) => {
  const [state, _setState] = useState(initialState)

  const setState = useCallback(
    (obj) => _setState((prevState) => ({ ...prevState, ...obj })),
    []
  )

  return [state, setState]
}

export default useObjState
