import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import useEditableForm from '../../../../../hooks/useEditableForm'
import { getCountryOptions } from '../../../../../utils/address'
import Button from '../../../../../components/Button'
import InputGroup from '../../../../../components/InputGroup'

const ViewDealerBusinessInfo = ({ dealerId }) => {
  const mapResData = useCallback(
    (data) => ({
      id: data.dealer.id,
      name: data.dealer.name,
      phone: data.dealer.phone,
      logoUrl: data.dealer.logoUrl,
      address: {
        line1: data.dealer.address?.line1,
        line2: data.dealer.address?.line2,
        city: data.dealer.address?.city,
        region: data.dealer.address?.region,
        postcode: data.dealer.address?.postcode,
        country: data.dealer.address?.country,
      },
    }),
    []
  )

  const [
    {
      formValues,
      hasFetched,
      showNoChanges,
      showChangesMade,
      showChangesSaving,
      showChangesSaved,
      saveError,
      connectionError,
    },
    { setFormValues, resetFormValues, saveFormValues },
  ] = useEditableForm({
    initialValues: {
      id: null,
      name: '',
      phone: '',
      logoUrl: null,
      address: {
        line1: '',
        line2: '',
        city: '',
        region: '',
        postcode: '',
        country: null,
      },
    },
    axiosConfig: { url: `/admin/dealers/${dealerId}/info` },
    mapResData,
  })

  const handleCancel = () => {
    resetFormValues()
  }

  const handleSubmit = () => {
    saveFormValues({
      url: `/admin/dealers/${dealerId}/info`,
      method: 'patch',
      data: formValues,
    })
  }

  const setAddress = (change) =>
    setFormValues({ address: { ...formValues.address, ...change } })

  const {
    name,
    phone,
    address: { line1, line2, city, region, postcode, country },
  } = formValues

  if (connectionError) {
    return (
      <div className="flex items-center justify-center flex-1 flex-col p-18">
        <FontAwesomeIcon
          icon={faWifiSlash}
          className="text-gray-600"
          size="2x"
        />
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Could not retrieve account settings
        </h3>
        <p className="text-sm text-gray-600 mt-1 mb-6">
          Please refresh the page to try again.
        </p>
        <Button color="white" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </div>
    )
  }

  if (!hasFetched) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <>
      <div className="border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
        <div className="max-w-sm flex-1">
          <h2 className="text-lg font-semibold tracking-tight mb-2">
            Business profile
          </h2>
          <div className="text-gray-600 text-sm space-y-2 max-w-prose">
            <p>Update the dealer's name and phone number.</p>
          </div>
        </div>
        <div className="flex-1 space-y-4">
          <InputGroup
            required
            label="Business name"
            id="businessName"
            disabled={showChangesSaving}
            onChange={(e) => setFormValues({ name: e.target.value })}
            value={name}
          />
          <InputGroup
            required
            className="md:max-w-xs"
            label="Phone number"
            id="phone"
            disabled={showChangesSaving}
            onChange={(e) => setFormValues({ phone: e.target.value })}
            value={phone}
          />
        </div>
      </div>
      <div className="border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
        <div className="max-w-sm flex-1">
          <h2 className="text-lg font-semibold tracking-tight mb-2">
            Business address
          </h2>
          <div className="text-gray-600 text-sm space-y-2 max-w-prose">
            <p>
              This is the address where the business is based. This may also be
              your shipping address.
            </p>
          </div>
        </div>
        <div className="flex-1 space-y-4">
          <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
            <InputGroup
              required
              className="flex-1"
              label="Address line one"
              id="addressLine1"
              disabled={showChangesSaving}
              onChange={(e) => setAddress({ line1: e.target.value })}
              value={line1}
            />
            <InputGroup
              className="flex-1"
              label="Address line two"
              id="addressLine2"
              disabled={showChangesSaving}
              onChange={(e) => setAddress({ line2: e.target.value })}
              value={line2}
            />
          </div>
          <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
            <InputGroup
              className="flex-1"
              label="City/town"
              id="city"
              disabled={showChangesSaving}
              onChange={(e) => setAddress({ city: e.target.value })}
              value={city}
            />
            <InputGroup
              className="flex-1"
              label="Region"
              id="region"
              disabled={showChangesSaving}
              onChange={(e) => setAddress({ region: e.target.value })}
              value={region}
            />
          </div>
          <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
            <InputGroup
              required
              className="flex-1"
              label="Postcode"
              id="postcode"
              disabled={showChangesSaving}
              onChange={(e) =>
                setAddress({ postcode: e.target.value.toUpperCase() })
              }
              value={postcode}
            />
            <InputGroup
              required
              className="flex-1"
              type="select"
              disabled={showChangesSaving}
              label="Country"
              id="country"
              onChange={(e) => setAddress({ country: e.target.value })}
              renderOptions={getCountryOptions}
              value={country || 'GB'}
            />
          </div>
        </div>
      </div>
      <div className="py-4 px-6 sm:flex sm:items-center sm:justify-between">
        <div className="text-sm">
          {saveError ? (
            <p className="text-red-600">
              Error saving changes - please try again
            </p>
          ) : (
            <p className="text-gray-600">
              {showChangesSaved && 'Your changes have been saved'}
              {showChangesSaving && 'Saving your changes...'}
              {showChangesMade && 'Please save your changes'}
              {showNoChanges && 'No changes have made'}
            </p>
          )}
        </div>
        <div className="mt-4 flex flex-col-reverse sm:block sm:mt-0 sm:space-x-3">
          <Button
            className="mt-3 w-full justify-center sm:w-auto sm:mt-0"
            disabled={!showChangesMade}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="w-full justify-center sm:w-auto"
            disabled={
              !name || !phone || !line1 || !postcode || !showChangesMade
            }
            onClick={handleSubmit}
          >
            {showChangesSaved && 'Changes saved'}
            {showChangesSaving && 'Saving...'}
            {(showNoChanges || showChangesMade) && 'Save changes'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default ViewDealerBusinessInfo
