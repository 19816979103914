import React, { useState } from 'react'

import { RE_CREDIT_UPLOAD } from '../../../utils/sockets'
import { RE_CREDITED } from '../../../utils/uploads'
import Button from '../../Button'
import Dialog from '../../Dialog'

const ViewUploadRecreditWithPriorityModal = (props) => {
  const { isOpen, setIsOpen, socket, setUpload } = props

  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const handleSubmit = () => {
    setIsLoading(true)

    socket.emit(RE_CREDIT_UPLOAD, { refundPriority: true }, (err, addons) => {
      setIsLoading(false)

      if (addons) {
        setSuccess(true)
        setUpload({ addons, status: RE_CREDITED })
      }

      if (err) {
        setError(err)
      }
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not re-credit upload. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => setIsOpen(false)}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        title="Successfully re-credited upload"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          This upload has been re-credited and any warranty has been refunded.
        </p>
        <Button className="mt-4" onClick={() => setIsOpen(false)}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Re-credit upload & priority"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Refunding...' : 'Refund',
        color: 'red',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        <strong>Are you sure you wish to re-credit this upload?</strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        This upload will enter a "Re-credited" status and the dealer's credit
        balance will increase by the original credit cost of the upload, plus
        the priority add-on.
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        If the dealer purchased a warranty add-on we will refund this via
        Stripe. Please note that a refund can take between 5-10 days depending
        on the dealer's bank.
      </p>
      <p className="mt-2 text-sm text-red-600">This action is irreversible.</p>
    </Dialog>
  )
}

export default ViewUploadRecreditWithPriorityModal
