import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Title from '../../components/Title'
import BoxNav from '../../components/BoxNav'
import System from '../../components/settings/system'
import Admins from './admins'
import Writers from './writers'
import Account from '../../components/settings/account'

const boxNavLinks = [
  { to: '/settings/system', label: 'System' },
  { to: '/settings/admins', label: 'Admins' },
  { to: '/settings/writers', label: 'Writers' },
  { to: '/settings/account', label: 'Account' },
]

const Settings = ({ location }) => {
  return (
    <>
      <Title title="System settings" />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
            <BoxNav current={location.pathname} links={boxNavLinks} />
          </div>
          <Switch>
            <Route path="/settings/system" component={System} />
            <Route path="/settings/admins" component={Admins} />
            <Route path="/settings/writers" component={Writers} />
            <Route path="/settings/account" component={Account} />
            <Redirect to="/settings/system" />
          </Switch>
        </div>
      </div>
    </>
  )
}

export default Settings
