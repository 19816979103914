import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'

const ExternalLink = ({ to, children, className, noIcon, ...rest }) => {
  return (
    <a
      className={className}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}{' '}
      {!noIcon && <FontAwesomeIcon size="sm" icon={faExternalLink} />}
    </a>
  )
}

export default ExternalLink
