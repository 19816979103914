import React from 'react'
import cn from 'classnames'
import isEmail from 'validator/lib/isEmail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'

import Button from '../components/Button'
import InputGroup from '../components/InputGroup'

const SubdealerUser = (props) => {
  const {
    isLoading,
    emailError,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    emailRef,
    setEmail,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    onNext,
    generalError,
  } = props

  const emailCn = cn('form-group', { errored: emailError })

  const btnDisabled =
    firstName === '' ||
    lastName === '' ||
    !isEmail(email) ||
    password.length < 8 ||
    passwordConfirm !== password ||
    emailError ||
    isLoading

  return (
    <form onSubmit={onNext}>
      <h2 className="text-lg md:text-xl font-medium tracking-tight">
        Your name and login details
      </h2>
      {generalError && (
        <p className="text-red-600 text-sm mt-3">{generalError}</p>
      )}
      <div className="space-y-5">
        <div className="space-y-5 mt-4 sm:space-y-0 sm:flex sm:space-x-3">
          <InputGroup
            autoFocus
            required
            className="flex-1"
            disabled={isLoading}
            label="First name"
            id="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <InputGroup
            required
            className="flex-1"
            disabled={isLoading}
            label="Last name"
            id="lastName"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </div>
        <InputGroup
          autoCapitalize="none"
          autoComplete="email"
          autoCorrect="none"
          required
          className={emailCn}
          error={emailError}
          disabled={isLoading}
          ref={emailRef}
          label="Email address"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          value={email}
        />
        <div className="space-y-5 sm:space-y-0 sm:flex sm:space-x-3">
          <InputGroup
            autoCapitalize="none"
            autoComplete="new-password"
            autoCorrect="none"
            className="flex-1"
            disabled={isLoading}
            required
            label="Password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            value={password}
          />
          <InputGroup
            autoCapitalize="none"
            autoComplete="new-password"
            autoCorrect="none"
            className="flex-1"
            disabled={isLoading}
            required
            label="Confirm password"
            id="passwordConfirm"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            type="password"
            value={passwordConfirm}
          />
        </div>
      </div>
      <div className="mt-5 space-y-1">
        <div className="flex space-x-2 items-center">
          {password.length < 8 ? (
            <FontAwesomeIcon className="text-gray-500" icon={faTimesCircle} />
          ) : (
            <FontAwesomeIcon className="text-green-500" icon={faCheckCircle} />
          )}{' '}
          <span className="text-gray-600 text-sm">
            Password must be at least 8 characters.
          </span>
        </div>
        <div className="flex space-x-2 items-center">
          {password.length < 8 || password !== passwordConfirm ? (
            <FontAwesomeIcon className="text-gray-500" icon={faTimesCircle} />
          ) : (
            <FontAwesomeIcon className="text-green-500" icon={faCheckCircle} />
          )}{' '}
          <span className="text-gray-600 text-sm">Passwords must match.</span>
        </div>
      </div>
      <Button
        block
        color="primary"
        className="mt-6"
        disabled={btnDisabled}
        size="xl"
        type="submit"
      >
        Next
      </Button>
    </form>
  )
}

export default SubdealerUser
