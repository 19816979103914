import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useStripe } from '@stripe/react-stripe-js'

import history from '../../../history'
import Title from '../../Title'
import Button from '../../Button'
import NewUploadPaymentMethodModal from './addons/PaymentMethodModal'

const NewUploadStripeError = ({ location }) => {
  const {
    stripeError,
    paymentMethods,
    chosenMethodIndex,
    clientSecret,
    upload,
  } = _.get(location, 'state', {})

  const stripe = useStripe()
  const [newPaymentMethodIndex, setNewPaymentMethodIndex] = useState(null)
  const [paymentError, setPaymentError] = useState(false)
  const [paymentAttempted, setPaymentAttempted] = useState(false)
  const [chooseMethodModalOpen, setChooseMethodModalOpen] = useState(false)
  const [isStripeLoading, setIsStripeLoading] = useState(false)

  useEffect(() => {
    if (!stripeError) {
      history.push('/dashboard')
    }
  }, [stripeError])

  useEffect(() => {
    if (newPaymentMethodIndex !== null && !paymentAttempted) {
      const takePayment = async () => {
        setPaymentAttempted(true)
        setIsStripeLoading(true)

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethods[newPaymentMethodIndex].id,
        })

        setIsStripeLoading(false)

        if (result.error) {
          setPaymentError(result.error.message)
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            history.push({
              pathname: '/uploads/new/success',
              state: { upload },
            })
          }
        }
      }

      takePayment()
    }
  }, [
    newPaymentMethodIndex,
    stripe,
    clientSecret,
    paymentMethods,
    paymentAttempted,
    upload,
  ])

  const handleNewPaymentMethod = async (paymentMethodIndex) => {
    setChooseMethodModalOpen(false)
    setNewPaymentMethodIndex(paymentMethodIndex)
    setPaymentAttempted(false)
  }

  const card =
    newPaymentMethodIndex !== null
      ? paymentMethods[newPaymentMethodIndex].card
      : null

  // NOTE: Must keep NewUploadPaymentMethodModal mounted otherwise we lose
  // our fade-out effect which results in a bad UX.
  return (
    <>
      {!isStripeLoading ? (
        <>
          <Title title="Payment for add-ons failed" />
          <div className="max-w-5xl mx-auto px-4 py-1">
            <h3 className="text-xl font-semibold tracking-tighter mt-4">
              Different payment method needed
            </h3>
            <div className="text-sm text-gray-600 mt-2 mb-6 space-y-2">
              <p>We couldn't process your payment for the following reason:</p>
              <p>
                <strong>{paymentError ? paymentError : stripeError}</strong>
              </p>
              <p>
                We have still created your upload so you don't lose any work.
              </p>
              {paymentMethods.length > 1 ? (
                <>
                  <p>
                    To purchase the add-ons you selected please choose another
                    payment method. Alternatively you can continue without
                    purchasing your add-ons.
                  </p>
                  <div className="new-upload-error-actions">
                    <Button
                      color="primary"
                      onClick={() => setChooseMethodModalOpen(true)}
                    >
                      Change card
                    </Button>
                    <Button to={`/uploads/view/${upload.id}`}>
                      Continue without addons
                    </Button>
                  </div>
                </>
              ) : (
                <Button color="primary" to={`/uploads/view/${upload.id}`}>
                  Continue without addons
                </Button>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <Title title="Attempting to take payment" />
          <div className="max-w-5xl mx-auto px-4">
            <p className="text-sm text-gray-600 mt-2 mb-6">
              Attempting to take payment using{' '}
              <strong>
                {card.brand.toUpperCase()} ending in {card.last4}
              </strong>
              ...
            </p>
          </div>
        </>
      )}
      <NewUploadPaymentMethodModal
        isOpen={chooseMethodModalOpen}
        setIsOpen={() => setChooseMethodModalOpen(false)}
        paymentMethods={paymentMethods}
        chosenMethodIndex={
          card !== null ? newPaymentMethodIndex : chosenMethodIndex
        }
        onConfirm={handleNewPaymentMethod}
      />
    </>
  )
}

export default NewUploadStripeError
