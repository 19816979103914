import React, { useState, useEffect } from 'react'

import SelectMenu from '../../../components/SelectMenu'

const typeList = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Upcoming',
    value: 'UPCOMING',
  },
  {
    label: 'Expired',
    value: 'EXPIRED',
  },
]

const DiscountListStatusSelect = ({ setFilters }) => {
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setFilters(selected.map((item) => item.value))
  }, [selected, setFilters])

  return (
    <SelectMenu
      buttonText="Status"
      items={typeList}
      selected={selected}
      setSelected={setSelected}
      multiple
    />
  )
}

export default DiscountListStatusSelect
