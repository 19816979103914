import React, { useState, useEffect } from 'react'

import BillingDocItem from './DocItem'
import usePrivateApi from '../../../hooks/usePrivateApi'

const BillingDocs = (props) => {
  const { showContent } = props
  const [docs, setDocs] = useState(null)

  const [{ res, success, error }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({ url: '/billing/docs' })
  }, [sendRequest])

  useEffect(() => {
    if (success) {
      resetSuccess()
      setDocs(res.data.docs)
    }
  }, [res, success, resetSuccess])

  const renderDocs = () => {
    if (error) {
      return (
        <p className="text-red-600 text-sm">
          Could not retrieve invoices/credit notes.
        </p>
      )
    }

    if (docs === null) {
      return (
        <div className="flex items-center justify-center p-12 flex-1">
          <span className="spinner" />
        </div>
      )
    }

    if (docs.length < 1) {
      return (
        <div className="mt-4 mb-6 bg-gray-50 border border-gray-100 rounded-md px-4 py-3">
          <p className="text-gray-600 text-sm">
            You have not been issued any invoices or credit notes.
          </p>
        </div>
      )
    }

    return (
      <div className="border border-gray-200 rounded-md divide-y divide-y-200">
        {docs.map((doc) => {
          const docId = doc.isInvoice ? doc.invoiceId : doc.creditNoteId
          const reference = doc.isInvoice
            ? doc.invoiceNumber
            : doc.creditNoteNumber

          return (
            <BillingDocItem
              docId={docId}
              key={docId}
              reference={reference}
              isInvoice={doc.isInvoice}
              date={doc.date}
              total={doc.total}
            />
          )
        })}
      </div>
    )
  }

  return (
    <div
      className={
        !showContent
          ? 'hidden'
          : 'px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start'
      }
    >
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          Invoices & credit notes
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            Invoices and credit notes for credit packages and add-on purchases.
          </p>
          <p>
            Invoices and credit notes are available to download for 12 months
            from the date of the document. If you require older documents please
            raise a support ticket.
          </p>
          <p>
            We recommend you download your invoices and credit notes as soon as
            they're available.
          </p>
        </div>
      </div>
      <div className="flex-1">{renderDocs()}</div>
    </div>
  )
}

export default BillingDocs
