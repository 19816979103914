import React, { useState, useEffect } from 'react'
import isEmail from 'validator/lib/isEmail'
import { Link } from 'react-router-dom'

import usePublicApi from '../hooks/usePublicApi'
import AuthTemplate from '../templates/AuthTemplate'
import InputGroup from '../components/InputGroup'
import Button from '../components/Button'

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [generalError, setGeneralError] = useState(null)

  const [{ isLoading, error, success }, { sendRequest, resetError }] =
    usePublicApi()

  useEffect(() => {
    if (error) {
      if (error.response) {
        if (error.response.data.email) {
          setEmailError('No account exists with that email.')
        } else {
          setGeneralError(`An error occurred [${error.response.status}].`)
        }
      } else if (error.request) {
        setGeneralError('Please check your internet connection.')
      } else {
        setGeneralError('An error occurred [900].')
      }

      resetError()
    }
  }, [error, resetError])

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    setEmailError(null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setGeneralError(null)

    if (!isEmail(email)) {
      setEmailError('Please enter a valid email address.')
    } else {
      sendRequest({
        method: 'post',
        url: '/auth/reset-password',
        data: { email },
      })
    }
  }

  return (
    <AuthTemplate
      title="Reset your password"
      alertSuccess={success && 'We have sent you a reset password email.'}
      alertError={generalError}
    >
      <form onSubmit={handleSubmit}>
        <p className="text-gray-600 text-sm">
          Enter your email address below and we'll email you a link to reset
          your password.
        </p>
        <InputGroup
          autoCapitalize="none"
          autoComplete="email"
          autoCorrect="none"
          autoFocus
          className="flex-1 mt-3"
          error={emailError}
          label="Email address"
          disabled={isLoading || success}
          id="email"
          onChange={handleEmailChange}
          value={email}
        />
        <Button
          block
          color="primary"
          className="mt-4"
          disabled={emailError || isLoading || success}
          type="submit"
        >
          {success && 'Email sent'}
          {isLoading && !success && 'Sending...'}
          {!isLoading && !success && 'Send reset password link'}
        </Button>
        <Link
          className="mt-6 block text-gray-600 text-sm hover:underline"
          to="/login"
        >
          ⟵ Go back to login
        </Link>
      </form>
    </AuthTemplate>
  )
}

export default ResetPassword
