import React, { useState, useEffect, useContext } from 'react'
import cn from 'classnames'

import { FETCH_STATUS, STATUS_UPDATED } from '../utils/sockets'
import usePrivateSocket from '../hooks/usePrivateSocket'
import BrandingContext from '../context/BrandingContext'

const SystemStatus = () => {
  const [hasFetched, setHasFetched] = useState(false)
  const [status, setStatus] = useState(null)
  const [turnaround, setTurnaround] = useState(null)
  const [branding] = useContext(BrandingContext)
  const { primaryColorPalette } = branding

  const [{ inRoom }, { socket }] = usePrivateSocket('/system', true)

  useEffect(() => {
    if (!hasFetched && inRoom) {
      setHasFetched(true)

      socket.emit(FETCH_STATUS, (err, { status, turnaround }) => {
        setStatus(status)
        setTurnaround(9)
      })
    }
  }, [socket, hasFetched, inRoom])

  useEffect(() => {
    if (socket) {
      socket.on(STATUS_UPDATED, (status) => {
        setStatus(status)
      })

      return () => socket.off(STATUS_UPDATED)
    }
  }, [socket])

  if (!hasFetched || !status || !status.active) {
    return null
  }

  const colorTextCn = cn('block sm:inline font-semibold', {
    'text-green-700': status.color === 'GREEN',
    'text-yellow-600': status.color === 'ORANGE',
    'text-red-600': status.color === 'RED',
  })

  return (
    <div
      className={`bg-${primaryColorPalette}-50 py-2 text-xs border-y border-${primaryColorPalette}-100`}
    >
      <div className="max-w-5xl mx-auto px-4 space-x-3 flex">
        <div>
          <span className="text-gray-800">Average file turnaround time: </span>
          <span className={colorTextCn}>
            {turnaround || '15'} mins (24hr avg)
          </span>
        </div>
        <div>
          <span className="text-gray-800">System status: </span>
          <span className={colorTextCn}>{status.message}</span>
        </div>
      </div>
    </div>
  )
}

export default SystemStatus
