import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash, faRocketLaunch } from '@fortawesome/pro-regular-svg-icons'

import AuthContext from '../../../context/AuthContext'
import usePrivateApi from '../../../hooks/usePrivateApi'
import Subdealer from './Subdealer'
import SubdealerInviteModal from './ModalInviteSubdealer'
import Button from '../../../components/Button'

const SubdealerList = () => {
  const [subdealers, setSubdealers] = useState(null)
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [auth] = useContext(AuthContext)

  const [{ res, success, error }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({ url: '/settings/subdealers' })
  }, [sendRequest])

  useEffect(() => {
    if (success) {
      setSubdealers(res.data.subdealers)
      resetSuccess()
    }
  }, [success, resetSuccess, res, auth])

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 flex-col p-18">
        <FontAwesomeIcon
          icon={faWifiSlash}
          className="text-gray-600"
          size="2x"
        />
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Could not retrieve subdealers
        </h3>
        <p className="text-sm text-gray-600 mt-1 mb-6">
          Please refresh the page to try again.
        </p>
        <Button color="white" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </div>
    )
  }

  if (subdealers === null) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <div className="px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          Subdealers
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            Use our system to manage your own subdealer network and become a
            master dealer.
          </p>
          <p>
            As a master dealer, you purchase credits directly from Topgear
            Tuning and "transfer" these credits to your subdealers. How you
            charge your subdealers for these credits is entirely up to you.
          </p>
          <p>
            Subdealers have a special type of account that allows them to manage
            their own uploads and use our vehicle lookup tool, however they
            cannot view/purchase credit packages or create support tickets.
          </p>
        </div>
      </div>
      <div className="flex-1">
        {subdealers.length === 0 && (
          <div className="bg-gray-50 rounded-md px-8 py-6">
            <FontAwesomeIcon
              icon={faRocketLaunch}
              className="text-blue-600"
              size="lg"
            />
            <h5 className="font-medium mt-2 mb-1">
              Launch your own dealer network
            </h5>
            <p className="text-gray-600 text-sm mb-4">
              Invite your first subdealer to get started
            </p>
            <Button color="primary" onClick={() => setInviteModalOpen(true)}>
              Invite subdealer
            </Button>
          </div>
        )}
        {subdealers.length > 0 && (
          <div className="space-y-3">
            {subdealers.map((subdealer) => (
              <Subdealer subdealer={subdealer} auth={auth} key={subdealer.id} />
            ))}
            <Button
              color="primary"
              className="mt-5"
              onClick={() => setInviteModalOpen(true)}
            >
              Invite subdealer
            </Button>
          </div>
        )}
      </div>
      <SubdealerInviteModal
        isOpen={inviteModalOpen}
        setIsOpen={() => setInviteModalOpen(false)}
      />
    </div>
  )
}

export default SubdealerList
