import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../../Button'

const NewUploadFormActions = (props) => {
  const { isCreatingUpload, handleOnUpload, isDisabled } = props

  return (
    <div>
      <div className="mt-4 space-y-4 md:space-y-0 md:space-x-3 md:flex md:items-center md:justify-between">
        <div className="flex-1">
          {isCreatingUpload && (
            <p className="text-gray-600 text-sm">
              Creating your upload - this may take a few seconds...
            </p>
          )}
        </div>
        <div className="space-x-4">
          {!isCreatingUpload && (
            <Button as={Link} to="/uploads/list">
              Cancel
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => handleOnUpload()}
            disabled={isDisabled}
          >
            {isCreatingUpload ? 'Creating...' : 'Create upload'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NewUploadFormActions
