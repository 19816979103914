import React, { useState, useEffect } from 'react'
import currency from 'currency.js'

import { CHANGE_DEALER_CREDIT } from '../../../../utils/sockets'
import Button from '../../../../components/Button'
import Dialog from '../../../../components/Dialog'
import InputGroup from '../../../../components/InputGroup'

const ViewDealerCreditChangeBaseCostModal = (props) => {
  const { isOpen, setIsOpen, socket, baseCost, onSuccess } = props

  const [newBaseCostStr, setNewBaseCostStr] = useState(
    currency(baseCost / 100).format()
  )
  const [newBaseCost, setNewBaseCost] = useState(baseCost)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setSuccess(null)
      setError(null)
    }
  }, [isOpen])

  const handleChange = (e) => {
    // Remove everything except 0-9, '.' and ','
    const input = e.target.value.replace(/[^0-9.,]/g, '')
    const inputCurrency = currency(input)
    setNewBaseCost(
      inputCurrency.value ? Math.round(inputCurrency.value * 100) : null
    )
    setNewBaseCostStr(input)
  }

  const handleSubmit = () => {
    setIsLoading(true)

    socket.emit(
      CHANGE_DEALER_CREDIT,
      { baseCost: newBaseCost },
      (err, dealerCredit) => {
        setIsLoading(false)

        if (dealerCredit) {
          setSuccess(true)
          onSuccess(dealerCredit)
          return
        }

        if (err) {
          setError(err)
          return
        }
      }
    )
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          There was an error saving the new base cost.
        </p>
        <Button className="mt-4" onClick={setIsOpen}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Dealer base cost changed"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          We have successfully changed the dealer base cost to £
          {currency(newBaseCost / 100).format()}.
        </p>
        <Button className="mt-4" onClick={setIsOpen}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Change dealer base cost"
      buttonProps={{
        disabled: isLoading || !newBaseCost,
        onClick: handleSubmit,
        children: isLoading ? 'Saving...' : 'Save base cost',
        color: 'primary',
      }}
    >
      <div className="mt-2">
        <InputGroup
          required
          label="New base cost"
          id="baseCost"
          disabled={isLoading}
          onChange={handleChange}
          value={newBaseCostStr}
          help={`New base cost will be: £${currency(
            newBaseCost / 100
          ).format()}`}
        />
      </div>
    </Dialog>
  )
}

export default ViewDealerCreditChangeBaseCostModal
