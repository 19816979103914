import React, { useState, useEffect, useRef, useContext } from 'react'

import AuthContext from '../../../context/AuthContext'
import { Messages } from '../../Message'
import {
  SEND_MESSAGE,
  USER_TYPING,
  UPDATE_USER_TYPING,
} from '../../../utils/sockets'

const LOCAL_TYPING_DEBOUNCE_DELAY = 1000 * 8
const REMOTE_TYPING_RESET_DELAY = 1000 * 12

const ViewTicketMessages = (props) => {
  const { ticket, setTicket, socket, isDisconnected } = props
  const [auth] = useContext(AuthContext)

  const [currentMessage, setCurrentMessage] = useState('')
  const [isMessageLoading, setIsMessageLoading] = useState(false)

  const remoteTypingTimerId = useRef(null)
  const [localTyping, setLocalTyping] = useState(false)
  const [remoteTyping, setRemoteTyping] = useState({
    isTyping: false,
    user: null,
    isAdmin: null,
  })

  const updateUserTyping = (text) => {
    if (!localTyping && text) {
      setLocalTyping(true)
      return socket.emit(UPDATE_USER_TYPING, true)
    }

    if (!text) {
      setLocalTyping(false)
      return socket.emit(UPDATE_USER_TYPING, false)
    }
  }

  const handleOnMessageSend = () => {
    setIsMessageLoading(true)

    socket.emit(SEND_MESSAGE, currentMessage, (err, message) => {
      if (message) {
        setTicket({ ...ticket, messages: [...ticket.messages, message] })
        setCurrentMessage('')
        setIsMessageLoading(false)
      }
    })
  }

  useEffect(() => {
    if (socket) {
      socket.on(USER_TYPING, ({ isTyping, user, isAdmin }) => {
        if (user && user.id === auth.membership.user.id) return

        if (remoteTypingTimerId.current) {
          clearTimeout(remoteTypingTimerId.current)
        }

        setRemoteTyping({ isTyping, user, isAdmin })

        if (isTyping) {
          const timerId = setTimeout(
            () =>
              setRemoteTyping({ isTyping: false, user: null, isAdmin: null }),
            REMOTE_TYPING_RESET_DELAY
          )

          remoteTypingTimerId.current = timerId
        }
      })

      return () => socket.off(USER_TYPING)
    }
  }, [socket, auth.membership])

  useEffect(() => {
    if (localTyping) {
      const timerId = setTimeout(
        () => setLocalTyping(false),
        LOCAL_TYPING_DEBOUNCE_DELAY
      )
      return () => clearTimeout(timerId)
    }
  }, [localTyping])

  return (
    <Messages
      currentMessage={currentMessage}
      setCurrentMessage={setCurrentMessage}
      messages={ticket.messages}
      onMessageSend={handleOnMessageSend}
      isMessageLoading={isMessageLoading}
      isDisconnected={isDisconnected}
      remoteTyping={remoteTyping}
      onChangeText={updateUserTyping}
      isSupport
      socket={socket}
      onNewFiles={(files) => setTicket({ files })}
      isDisabled={ticket.status !== 'OPEN'}
      isDisabledMessage="Ticket is closed. No more messages can be sent."
    />
  )
}

export default ViewTicketMessages
