import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import DealerList from './list'
import ViewDealer from './view'

const Dealers = ({ location }) => {
  return (
    <Switch>
      <Route
        path={[
          '/dealers/list/active',
          '/dealers/list/on-hold',
          '/dealers/list/pending',
          '/dealers/list/archived',
          '/dealers/list/all',
        ]}
        render={(props) => <DealerList {...props} />}
      />
      <Route path="/dealers/view/:dealerId" component={ViewDealer} />
      <Redirect to={{ pathname: '/dealers/list/all', state: location.state }} />
    </Switch>
  )
}

export default Dealers
