import React from 'react'

import FileList from '../../FileList'
import { OPEN } from '../../../utils/support'

const ViewTicketFiles = (props) => {
  const { ticket, setTicket, socket, onError, isAdmin, isDisconnected } = props

  if (isAdmin) {
    return (
      <div className="divide-y divide-gray-200">
        <FileList
          isAdmin
          description="Files uploaded by the dealer"
          files={ticket.files}
          heading="Dealer files"
          noFilesText="The dealer has not uploaded any files."
          socket={socket}
          isSupport
        />
        <FileList
          isAdmin
          isAdminFileList
          description="Files uploaded by Topgear Support"
          files={ticket.files}
          heading="Topgear files"
          noFilesText="We have not uploaded any files to this ticket."
          onNewFiles={(files) => setTicket({ files })}
          onNewFilesError={onError}
          socket={socket}
          isDisabled={ticket.status !== OPEN}
          isSupport
          isDisconnected={isDisconnected}
        />
      </div>
    )
  }

  return (
    <div className="divide-y divide-gray-200">
      <FileList
        description="Files uploaded by you or your team"
        files={ticket.files}
        heading="Dealer files"
        noFilesText="You have not uploaded any files."
        onNewFiles={(files) => setTicket({ files })}
        onNewFilesError={onError}
        socket={socket}
        isDisabled={ticket.status !== OPEN}
        isSupport
        isDisconnected={isDisconnected}
      />
      <FileList
        isAdminFileList
        description="Files uploaded by Topgear Support"
        files={ticket.files}
        heading="Topgear files"
        noFilesText="Topgear Support have not uploaded any files to this support ticket."
        socket={socket}
        isSupport
      />
    </div>
  )
}

export default ViewTicketFiles
