import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const DeleteToolModal = ({ isOpen, setIsOpen, tool }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/tools/${tool.id}`,
      method: 'delete',
    })
  }

  if (tool.autoRenewOn) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Cannot remove tool"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          You cannot remove this tool whilst auto-renew is enabled. Please
          disable auto-renew and try again.
        </p>
        <Button className="mt-4" onClick={setIsOpen}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not remove tool from your account. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Tool removed from your account"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          This tool has been removed from your account.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Remove tool"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Removing...' : 'Remove tool',
        color: 'red',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        <strong>
          Are you sure you wish to remove this tool from your account?
        </strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        If this is your only tool in your account you may lose access to our
        portal in the future.
      </p>
    </Dialog>
  )
}

export default DeleteToolModal
