import React from 'react'

export const objectToOptions = obj =>
  Object.entries(obj).map(([key, value]) => (
    <option key={key} value={key}>
      {value}
    </option>
  ))

export const objectCompare = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b)
}
