import React, { useState } from 'react'

import Dialog from '../../Dialog'
import { OPEN_TICKET } from '../../../utils/sockets'
import { OPEN } from '../../../utils/support'

const ViewTicketOpenModal = (props) => {
  const { isOpen, setIsOpen, socket, setTicket } = props
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)

    socket.emit(OPEN_TICKET, (err, success) => {
      if (success) {
        setIsLoading(false)
        setTicket({ status: OPEN })
        setIsOpen(false)
      }
    })
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Re-open ticket"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Opening...' : 'Re-open ticket',
        color: 'primary',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        Are you sure you want to re-open this ticket?
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        You should only re-open a ticket when the issue previously discussed is
        not resolved. For new issues please create a new support ticket.
      </p>
    </Dialog>
  )
}

export default ViewTicketOpenModal
