import React, { useState, useEffect, useRef, useContext } from 'react'

import history from '../history'
import AuthContext from '../context/AuthContext'
import useLocalStorage from '../hooks/useLocalStorage'
import SignupTemplate from '../templates/SignupTemplate'
import SignupUser from './SignupUser'
import SignupDealer from './SignupDealer'
import usePublicApi from '../hooks/usePublicApi'

const Signup = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [phone, setPhone] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [addressCity, setAddressCity] = useState('')
  const [addressRegion, setAddressRegion] = useState('')
  const [addressPostcode, setAddressPostcode] = useState('')
  const [addressCountry, setAddressCountry] = useState('GB')
  const [emailError, setEmailError] = useState(null)
  const [generalError, setGeneralError] = useState(null)
  const [step, setStep] = useState(1)
  const [emailConfirmed, setEmailConfirmed] = useState(false)
  const emailRef = useRef(null)
  const [, setAuthToken] = useLocalStorage('authToken')
  const [, setAuth] = useContext(AuthContext)

  const [
    { isLoading, res, success, error },
    { sendRequest, resetSuccess, resetError },
  ] = usePublicApi()

  useEffect(() => {
    if (res.data.membership) {
      history.push({
        pathname: '/signup-pending',
        state: { membership: res.data.membership },
      })
    }
  }, [res, setAuth, setAuthToken])

  useEffect(() => {
    if (success && !emailConfirmed) {
      setStep(2)
      setEmailConfirmed(true)
      resetSuccess()
    }
  }, [success, emailConfirmed, resetSuccess])

  useEffect(() => {
    if (error && !emailConfirmed) {
      if (error.response) {
        setEmailError('Email is already taken.')
        emailRef.current.focus()
      } else {
        setGeneralError('Please check your internet connection.')
      }
      resetError()
    } else if (error && emailConfirmed) {
      if (error.response) {
        setGeneralError('Error creating account - please contact support')
      } else if (error.request) {
        setGeneralError('Please check your internet connection')
      } else {
        setGeneralError('Error creating account - please contact support')
      }
    }
  }, [emailConfirmed, error, resetError])

  const handleEmailChange = (email) => {
    setEmailError(null)
    setEmailConfirmed(false)
    setEmail(email)
  }

  const handleOnNext = (e) => {
    e.preventDefault()
    setGeneralError(null)
    sendRequest({ url: `/auth/email/${email}` })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setGeneralError(null)

    sendRequest({
      url: '/auth/signup',
      method: 'post',
      data: {
        email,
        password,
        firstName,
        lastName,
        businessName,
        phone,
        addressLine1,
        addressLine2,
        addressCity,
        addressRegion,
        addressPostcode,
        addressCountry,
      },
    })
  }

  return (
    <SignupTemplate title="Create your dealer account">
      {step === 1 && (
        <SignupUser
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          emailRef={emailRef}
          emailError={emailError}
          setEmail={handleEmailChange}
          password={password}
          setPassword={setPassword}
          passwordConfirm={passwordConfirm}
          setPasswordConfirm={setPasswordConfirm}
          isLoading={isLoading}
          onNext={handleOnNext}
          generalError={generalError}
        />
      )}
      {step === 2 && (
        <SignupDealer
          businessName={businessName}
          setBusinessName={setBusinessName}
          phone={phone}
          setPhone={setPhone}
          addressLine1={addressLine1}
          setAddressLine1={setAddressLine1}
          addressLine2={addressLine2}
          setAddressLine2={setAddressLine2}
          addressCity={addressCity}
          setAddressCity={setAddressCity}
          addressRegion={addressRegion}
          setAddressRegion={setAddressRegion}
          addressPostcode={addressPostcode}
          setAddressPostcode={setAddressPostcode}
          addressCountry={addressCountry}
          setAddressCountry={setAddressCountry}
          onPrevious={() => setStep(1)}
          onSubmit={handleSubmit}
          generalError={generalError}
          isLoading={isLoading}
        />
      )}
    </SignupTemplate>
  )
}

export default Signup
