import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import useObjState from './hooks/useObjState'
import AuthContext, { initialAuth } from './context/AuthContext'
import NotificationContext from './context/NotificationContext'
import Router from './Router'
import BrandingContext, { initialBranding } from './context/BrandingContext'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUB_KEY)

const App = () => {
  const authValue = useObjState(initialAuth)
  const brandingValue = useObjState(initialBranding)
  const notificationValue = useState([])

  return (
    <Elements stripe={stripePromise}>
      <AuthContext.Provider value={authValue}>
        <BrandingContext.Provider value={brandingValue}>
          <NotificationContext.Provider value={notificationValue}>
            <Router />
          </NotificationContext.Provider>
        </BrandingContext.Provider>
      </AuthContext.Provider>
    </Elements>
  )
}

export default App
