import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import ViewTicketTitle from './Title'
import Button from '../../Button'

const ViewTicketError = ({ ticket }) => {
  return (
    <>
      <ViewTicketTitle ticket={ticket} />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          <div className="flex items-center justify-center flex-1 flex-col p-18">
            <FontAwesomeIcon
              icon={faWifiSlash}
              className="text-gray-600"
              size="2x"
            />
            <h3 className="text-xl font-semibold tracking-tighter mt-4">
              Could not connect to ticket
            </h3>
            <p className="text-sm text-gray-600 mt-1 mb-6">
              Please refresh the page to try again.
            </p>
            <Button color="white" onClick={() => window.location.reload()}>
              Refresh page
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewTicketError
