import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'

import history from '../history'
import AuthContext from '../context/AuthContext'
import SignupTemplate from '../templates/SignupTemplate'
import SubdealerAcceptUser from './SubdealerAcceptUser'
import SubdealerAcceptDealer from './SubdealerAcceptDealer'
import usePrivateApi from '../hooks/usePrivateApi'
import useLocalStorage from '../hooks/useLocalStorage'

const SubdealerAccept = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [invitedEmail, setInvitedEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [addressCity, setAddressCity] = useState('')
  const [addressRegion, setAddressRegion] = useState('')
  const [addressPostcode, setAddressPostcode] = useState('')
  const [addressCountry, setAddressCountry] = useState('GB')
  const [masterName, setMasterName] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [generalError, setGeneralError] = useState(null)
  const [step, setStep] = useState(1)
  const [emailConfirmed, setEmailConfirmed] = useState(false)
  const [, setAuth] = useContext(AuthContext)
  const [, setAuthToken] = useLocalStorage('authToken')
  const emailRef = useRef(null)

  const inviteToken = useMemo(() => {
    const params = new URLSearchParams(document.location.search.substring(1))
    return params.get('t')
  }, [])

  const [
    { isLoading, res, success, error },
    { sendRequest, resetSuccess, resetError },
  ] = usePrivateApi()

  useEffect(() => {
    if (inviteToken) {
      sendRequest({
        url: `/settings/subdealers/invite/${inviteToken}`,
        method: 'get',
      })
    } else {
      history.push({
        pathname: '/login',
        state: { incorrectSetupLink: true },
      })
    }
  }, [inviteToken, sendRequest])

  useEffect(() => {
    if (success && res.data.user) {
      setFirstName(res.data.user.firstName)
      setLastName(res.data.user.lastName)
      setEmail(res.data.user.email)
      setInvitedEmail(res.data.user.email)
      setBusinessName(res.data.dealer.name)
      setMasterName(res.data.master.name)
      resetSuccess()
    }
  }, [success, resetSuccess, res])

  useEffect(() => {
    if (success && !emailConfirmed && !res.data.user) {
      setStep(2)
      setEmailConfirmed(true)
      resetSuccess()
    }
  }, [success, emailConfirmed, resetSuccess, res])

  useEffect(() => {
    if (success && res.data.membership) {
      setAuthToken(res.data.token)

      setAuth({
        isLoggedIn: true,
        membership: { ...res.data.membership },
      })
    }
  }, [res, setAuth, success, setAuthToken])

  useEffect(() => {
    if (error) {
      if (error.response) {
        if (!firstName) {
          history.push({
            pathname: '/login',
            state: { incorrectSetupLink: true },
          })
        } else if (!emailConfirmed) {
          setEmailError('Email is already taken.')
          emailRef.current.focus()
        } else if (emailConfirmed) {
          setGeneralError('Error completing set up - please contact support')
        }
      } else if (error.request) {
        setGeneralError('Please check your internet connection')
      } else {
        setGeneralError('Error completing set up - please contact support')
      }
    }

    resetError()
  }, [firstName, emailConfirmed, error, resetError])

  const handleEmailChange = (email) => {
    setEmailError(null)
    setEmailConfirmed(false)
    setEmail(email)
  }

  const handleOnNext = (e) => {
    e.preventDefault()
    setGeneralError(null)

    if (email !== invitedEmail) {
      sendRequest({ url: `/auth/email/${email}` })
    } else {
      setStep(2)
      setEmailConfirmed(true)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setGeneralError(null)

    sendRequest({
      url: '/settings/subdealers/accept',
      method: 'post',
      data: {
        email,
        password,
        firstName,
        lastName,
        businessName,
        addressLine1,
        addressLine2,
        addressCity,
        addressRegion,
        addressPostcode,
        addressCountry,
        token: inviteToken,
      },
    })
  }

  if (!firstName) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <SignupTemplate title={`Become a subdealer of ${masterName}`}>
      {step === 1 && (
        <SubdealerAcceptUser
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          emailRef={emailRef}
          emailError={emailError}
          setEmail={handleEmailChange}
          password={password}
          setPassword={setPassword}
          passwordConfirm={passwordConfirm}
          setPasswordConfirm={setPasswordConfirm}
          isLoading={isLoading}
          onNext={handleOnNext}
          generalError={generalError}
        />
      )}
      {step === 2 && (
        <SubdealerAcceptDealer
          businessName={businessName}
          setBusinessName={setBusinessName}
          addressLine1={addressLine1}
          setAddressLine1={setAddressLine1}
          addressLine2={addressLine2}
          setAddressLine2={setAddressLine2}
          addressCity={addressCity}
          setAddressCity={setAddressCity}
          addressRegion={addressRegion}
          setAddressRegion={setAddressRegion}
          addressPostcode={addressPostcode}
          setAddressPostcode={setAddressPostcode}
          addressCountry={addressCountry}
          setAddressCountry={setAddressCountry}
          onPrevious={() => setStep(1)}
          onSubmit={handleSubmit}
          generalError={generalError}
          isLoading={isLoading}
        />
      )}
    </SignupTemplate>
  )
}

export default SubdealerAccept
