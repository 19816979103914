import React, { useMemo, useCallback } from 'react'
import cn from 'classnames'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import InputGroup from './InputGroup'
import Button from './Button'
import ButtonGroup from './ButtonGroup'

const itemsPerPageOptions = [5, 10, 25, 50, 100, 200]

const TablePagination = (props) => {
  const {
    page,
    pageSize,
    count,
    maxPage,
    pageOptions,
    goToPage,
    goToNext,
    goToPrev,
    setPageSize,
    scrollOnChange,
  } = props

  const paginate = useCallback(
    (page) => {
      if (page === 'PREVIOUS') {
        goToPrev()
      } else if (page === 'NEXT') {
        goToNext()
      } else {
        goToPage(page)
      }

      if (scrollOnChange) {
        window.scrollTo({ left: 0, top: 0 })
      }
    },
    [scrollOnChange, goToNext, goToPrev, goToPage]
  )

  const jumpToPageOptions = useMemo(() => {
    return [...Array(maxPage + 1).keys()].slice(1)
  }, [maxPage])

  const items = useMemo(() => {
    if (maxPage && pageOptions) {
      return [
        {
          component: 'button',
          componentProps: {
            onClick: () => paginate('PREVIOUS'),
            disabled: page === 1,
          },
          icon: faChevronLeft,
          iconOnly: true,
        },
        ...pageOptions.map((pageNumber) => ({
          component: 'button',
          componentProps: {
            onClick: () => paginate(pageNumber),
            children: pageNumber,
            disabled: pageNumber === page,
          },
        })),
        {
          component: 'button',
          componentProps: {
            onClick: () => paginate('NEXT'),
            disabled: page === maxPage,
          },
          icon: faChevronRight,
          iconOnly: true,
        },
      ]
    }
  }, [maxPage, page, pageOptions, paginate])

  if (!maxPage || !pageOptions) {
    return null
  }

  const paginationCn = cn([
    'px-4',
    'py-3',
    'flex',
    'items-center',
    'justify-between',
    'sm:px-6',
  ])

  const itemCountCn = cn([
    'hidden',
    'sm:flex-1',
    'sm:flex',
    'sm:items-center',
    'sm:justify-between',
  ])

  return (
    <div className={paginationCn}>
      <div className="flex-1 flex justify-between sm:hidden">
        <Button onClick={() => paginate('PREVIOUS')}>Previous</Button>
        <Button className="ml-3" onClick={() => paginate('NEXT')}>
          Next
        </Button>
      </div>
      <div className={itemCountCn}>
        <div className="flex">
          <div className="text-xs leading-5 text-black flex items-center md:pr-2">
            Page{' '}
            <InputGroup
              noFormik
              label="Jump to page"
              labelHidden
              className="mx-1"
              inputClassName="pl-2 pr-8 py-1"
              type="select"
              value={page}
              onChange={(e) => goToPage(parseInt(e.target.value, 10))}
              renderOptions={() =>
                jumpToPageOptions.map((page) => (
                  <option value={page} key={`jump-page-${page}`}>
                    {page}
                  </option>
                ))
              }
            />{' '}
            of <span className="font-medium mx-1">{maxPage}</span> ({count}{' '}
            items)
          </div>
          <div className="hidden md:flex md:items-center border-l border-gray-200 pl-2">
            <p className="text-xs leading-5 text-black">Items per page</p>
            <InputGroup
              noFormik
              label="Change page size"
              labelHidden
              className="mx-1"
              inputClassName="pl-2 pr-8 py-1"
              type="select"
              value={pageSize}
              onChange={(e) => setPageSize(parseInt(e.target.value, 10))}
              renderOptions={() =>
                itemsPerPageOptions.map((page) => (
                  <option value={page} key={`per-page-${page}`}>
                    {page}
                  </option>
                ))
              }
            />
          </div>
        </div>
        <div>
          <ButtonGroup items={items} />
        </div>
      </div>
    </div>
  )
}

export default TablePagination
