import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import UploadList from '../../components/uploads/list'
import ViewUpload from '../../components/uploads/view'

const Upload = ({ location }) => {
  return (
    <Switch>
      <Route
        path={[
          '/uploads/list/ready',
          '/uploads/list/in-progress',
          '/uploads/list/downloaded',
          '/uploads/list/re-credited',
          '/uploads/list/pending',
          '/uploads/list/on-hold',
          '/uploads/list/all',
        ]}
        render={(props) => <UploadList isAdmin {...props} />}
      />
      <Route
        path="/uploads/view/:uploadId"
        render={(props) => <ViewUpload isAdmin {...props} />}
      />
      <Redirect to={{ pathname: '/uploads/list/all', state: location.state }} />
    </Switch>
  )
}

export default Upload
