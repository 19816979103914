import React, { useState, useEffect } from 'react'

import useManualLookup from '../../hooks/useManualLookup'
import Button from '../Button'
import InputGroup from '../InputGroup'

const ManualLookup = (props) => {
  const { checked, onVehicleChange, hideDetails } = props
  const [showLoading, setShowLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)

  const [
    {
      make,
      makeList,
      model,
      modelList,
      year,
      yearList,
      fuel,
      fuelList,
      engine,
      engineList,
      power,
      powerList,
      vehicle,
      otherVehicles,
      isFetchingVehicle,
      error,
      requestUrl,
    },
    {
      setMake,
      setModel,
      setYear,
      setFuel,
      setEngine,
      setPower,
      lookupVehicle,
      resetError,
    },
  ] = useManualLookup()

  useEffect(() => {
    if (vehicle) {
      onVehicleChange({ vehicle, otherVehicles })
    }
  }, [vehicle, otherVehicles, onVehicleChange])

  useEffect(() => {
    if (isFetchingVehicle && !showLoading) {
      setShowLoading(true)
    }

    if (!isFetchingVehicle && showLoading) {
      setShowLoading(false)
    }
  }, [requestUrl, isFetchingVehicle, showLoading])

  useEffect(() => {
    if (error) {
      if (error?.response?.status) {
        return setGeneralError(`Error code [${error.response.status}]`)
      }

      return setGeneralError('Please check your internet connection')
    }
    resetError()
  }, [error, resetError])

  if (!checked || hideDetails) {
    return null
  }

  return (
    <div className="mt-4 pb-3 space-y-3">
      <div className="space-y-3 sm:space-y-0 sm:flex sm:space-x-3">
        <InputGroup
          autoFocus
          type="select"
          label="Make"
          id="make"
          className="flex-1"
          onChange={(e) => setMake(e.target.value || null)}
          value={make || ''}
          renderOptions={() => (
            <>
              <option>Select make</option>
              {makeList.map((make) => (
                <option key={make} value={make}>
                  {make}
                </option>
              ))}
            </>
          )}
        />
        <InputGroup
          type="select"
          label="Model"
          id="model"
          className="flex-1"
          disabled={modelList < 1}
          onChange={(e) => setModel(e.target.value || null)}
          value={model || ''}
          renderOptions={() => (
            <>
              <option>Select model</option>
              {modelList.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </>
          )}
        />
      </div>
      <div className="space-y-3 sm:space-y-0 sm:flex sm:space-x-3">
        <InputGroup
          type="select"
          label="Year"
          id="year"
          className="flex-1"
          disabled={yearList < 1}
          onChange={(e) => setYear(e.target.value || null)}
          value={year || ''}
          renderOptions={() => (
            <>
              <option>Select year</option>
              {yearList.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </>
          )}
        />
        <InputGroup
          type="select"
          label="Fuel"
          id="fuel"
          className="flex-1"
          disabled={fuelList < 1}
          onChange={(e) => setFuel(e.target.value || null)}
          value={fuel || ''}
          renderOptions={() => (
            <>
              <option>Select fuel</option>
              {fuelList.map((fuel) => (
                <option key={fuel} value={fuel}>
                  {fuel}
                </option>
              ))}
            </>
          )}
        />
      </div>
      <div className="space-y-3 sm:space-y-0 sm:flex sm:space-x-3">
        <InputGroup
          type="select"
          label="Engine size (cc)"
          id="engine"
          className="flex-1"
          disabled={engineList < 1}
          onChange={(e) => setEngine(e.target.value || null)}
          value={engine || ''}
          renderOptions={() => (
            <>
              <option>Select engine</option>
              {engineList.map((engine) => (
                <option key={engine} value={engine}>
                  {engine}
                </option>
              ))}
            </>
          )}
        />
        <InputGroup
          type="select"
          label="Power (bhp)"
          id="power"
          className="flex-1"
          disabled={powerList < 1}
          onChange={(e) => setPower(e.target.value || null)}
          value={power || ''}
          renderOptions={() => (
            <>
              <option>Select model</option>
              {powerList.map((power) => (
                <option key={power} value={power}>
                  {power}
                </option>
              ))}
            </>
          )}
        />
      </div>
      <div>
        <Button
          className="mt-2"
          color="primary"
          disabled={
            showLoading ||
            !make ||
            !model ||
            !year ||
            !fuel ||
            !engine ||
            !power
          }
          onClick={lookupVehicle}
        >
          {showLoading ? 'Searching...' : 'Search vehicle'}
        </Button>
      </div>
      {generalError && (
        <p className="py-3 text-red-600 text-sm">{generalError}</p>
      )}
    </div>
  )
}

export default ManualLookup
