import React from 'react'

import InputGroup from '../../InputGroup'
import Textarea from '../../Textarea'

const AutoMessage = (props) => {
  const { isSaving, onChange, autoMessage } = props

  return (
    <div className="border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          Upload auto-message
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>Automatically send a message on each upload created.</p>
        </div>
      </div>
      <div className="flex-1 space-y-4">
        <InputGroup
          required
          className="max-w-xs"
          type="select"
          label="Enable upload auto-message?"
          id="enableAutoMessage"
          disabled={isSaving}
          onChange={(e) => onChange({ active: e.target.value === 'true' })}
          value={`${autoMessage.active}`}
          renderOptions={() => (
            <>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </>
          )}
        />
        <Textarea
          label="Message"
          id="autoMessage"
          placeholder="Enter message..."
          onChange={(e) => onChange({ message: e.target.value })}
          value={autoMessage.message}
          disabled={isSaving}
          required
        />
      </div>
    </div>
  )
}

export default AutoMessage
