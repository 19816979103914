import React, { useState, useEffect, useContext } from 'react'
import cn from 'classnames'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-solid-svg-icons'

import notifAlertSrc from '../assets/m4a/notif.m4a?url'
import history from '../history'
import TextButton from './TextButton'
import usePrivateSocket from '../hooks/usePrivateSocket'
import NotificationContext from '../context/NotificationContext'
import Popover from './Popover'
import {
  FETCH_NOTIFICATIONS,
  NEW_NOTIFICATION,
  NOTIFICATION_READ,
  CLEAR_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
} from '../utils/sockets'
import BrandingContext from '../context/BrandingContext'

export const Notifications = () => {
  const [hasFetchedNotifs, setHasFetchedNotifs] = useState(false)
  const [canPlayAlert, setCanPlayAlert] = useState(false)
  const [audioError, setAudioError] = useState(false)
  const [notifications, setNotifications] = useContext(NotificationContext)
  const [branding] = useContext(BrandingContext)
  const [{ inRoom }, { socket }] = usePrivateSocket('/notifications', true)

  const { primaryColorPalette } = branding

  useEffect(() => {
    if (!canPlayAlert && !audioError) {
      const prepareAlertPlay = () => {
        try {
          const notifAlert = new Audio()
          notifAlert.play()
          setCanPlayAlert(true)
        } catch (err) {
          setAudioError(true)
        }
      }

      document.addEventListener('click', prepareAlertPlay)
      return () => document.removeEventListener('click', prepareAlertPlay)
    }
  }, [canPlayAlert, audioError])

  useEffect(() => {
    if (!hasFetchedNotifs && inRoom) {
      setHasFetchedNotifs(true)

      socket.emit(FETCH_NOTIFICATIONS, (err, notifs) => {
        setNotifications(notifs)
      })
    }
  }, [socket, hasFetchedNotifs, setNotifications, inRoom])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_NOTIFICATION, (newNotif) => {
        setNotifications([newNotif, ...notifications])

        if (canPlayAlert) {
          const notifAlert = new Audio(notifAlertSrc)
          notifAlert.play()
        }
      })

      return () => socket.off(NEW_NOTIFICATION)
    }
  }, [socket, notifications, setNotifications, canPlayAlert])

  const handleOnView = ({ notif, close }) => {
    const linkToLookup = {
      NEW_UPLOAD: `/uploads/view/${notif.resourceId}`,
      UPLOAD_IN_PROGRESS: `/uploads/view/${notif.resourceId}`,
      UPLOAD_READY: `/uploads/view/${notif.resourceId}`,
      UPLOAD_MESSAGE: `/uploads/view/${notif.resourceId}`,
      UPLOAD_RE_CREDITED: `/uploads/view/${notif.resourceId}`,
      UPLOAD_ON_HOLD: `/uploads/view/${notif.resourceId}`,
      UPLOAD_FILE: `/uploads/view/${notif.resourceId}/files`,
      NEW_TICKET: `/support/view/${notif.resourceId}`,
      SUPPORT_MESSAGE: `/support/view/${notif.resourceId}`,
      SUPPORT_FILE: `/support/view/${notif.resourceId}/files`,
      NEW_ASSIGNMENT: `/uploads/view/${notif.resourceId}`,
      UPLOAD_COST_CHANGED: `/uploads/view/${notif.resourceId}`,
    }

    history.push(linkToLookup[notif.type])
    close(false)

    socket.emit(NOTIFICATION_READ, notif.id, (err, newNotifs) => {
      if (newNotifs) {
        setNotifications(newNotifs)
      }
    })
  }

  const handleOnClear = ({ notif }) => {
    socket.emit(CLEAR_NOTIFICATION, notif.id, (err, newNotifs) => {
      if (newNotifs) {
        setNotifications(newNotifs)
      }
    })
  }

  const handleOnClearAll = () => {
    socket.emit(CLEAR_ALL_NOTIFICATIONS, (err, success) => {
      if (success) {
        setNotifications([])
      }
    })
  }

  const getNotificationMessageColor = (notif) => {
    if (notif.read) return 'secondary'
    if (notif.type === 'NEW_ASSIGNMENT') return 'accent'
    return 'primary'
  }

  const showUnread = notifications.reduce(
    (result, notif) => result || !notif.read,
    false
  )

  return (
    <Popover
      panelSize="sm"
      renderButton={({ PopoverButton }) => (
        <PopoverButton
          className={`relative bg-none rounded-full text-${primaryColorPalette}-200 ml-6 w-10 h-10 p-0 hover:bg-${primaryColorPalette}-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white`}
        >
          <span className="sr-only">Notifications</span>
          <FontAwesomeIcon
            className={`text-${primaryColorPalette}-300`}
            size="lg"
            icon={faBell}
          />
          {showUnread && (
            <span className="absolute top-2 right-2 flex h-3 w-3 items-center justify-center">
              <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
              <span className="relative inline-flex h-2 w-2 bg-red-500 rounded-full" />
            </span>
          )}
        </PopoverButton>
      )}
      renderContent={({ close }) => (
        <div className="max-h-[600px] overflow-y-auto">
          <div className="py-3 px-4 border-b border-gray-100">
            <h3 className="text-gray-900 font-medium text-sm">Notifications</h3>
          </div>
          {notifications.length < 1 ? (
            <p className="px-4 py-4 text-gray-600 text-sm">
              No unread notifications, good job!{' '}
              <span className="ml-1" role="img" aria-label="check-mark">
                ✅
              </span>
            </p>
          ) : (
            <>
              <ul className="divide-y divide-gray-100">
                {notifications.map((notif) => {
                  const { title, message, createdAt, read } = notif

                  const titleCn = cn('text-gray-900 text-sm', {
                    'font-regular': read,
                    'font-semibold': !read,
                  })

                  return (
                    <li
                      className="px-4 py-3 flex flex-col items-start relative"
                      key={notif.id}
                    >
                      <div className="flex items-center justify-between w-full">
                        <h4 className={titleCn}>{title}</h4>
                        <TextButton
                          size="xs"
                          theme="secondary"
                          onClick={() => handleOnClear({ notif })}
                        >
                          Clear
                        </TextButton>
                      </div>
                      <TextButton
                        size="xs"
                        theme={getNotificationMessageColor(notif)}
                        className="text-left mt-0.5"
                        onClick={() => handleOnView({ notif, close })}
                      >
                        {message}
                      </TextButton>
                      <p className="text-gray-500 text-xs mt-0.5">
                        Created at{' '}
                        {format(new Date(createdAt), "dd MMMM yyy 'at' hh:mma")}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <div className="px-4 py-2 flex justify-end items-center border-t border-gray-100">
                <TextButton
                  size="xs"
                  theme="secondary"
                  onClick={handleOnClearAll}
                >
                  Clear all
                </TextButton>
              </div>
            </>
          )}
        </div>
      )}
    />
  )
}
