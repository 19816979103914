import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import MainTemplate from '../templates/MainTemplate'
import Uploads from './uploads'
import Support from './support'
import Dealers from './dealers'
import Credits from './credits'
import VehicleData from '../components/VehicleData'
import Reports from './reports'
import Settings from './settings'

const Admin = ({ location }) => {
  return (
    <MainTemplate admin location={location}>
      <Switch>
        <Route path="/uploads" component={Uploads} />
        <Route path="/support" component={Support} />
        <Route path="/dealers" component={Dealers} />
        <Route path="/credits" component={Credits} />
        <Route path="/vehicle-data" component={VehicleData} />
        <Route path="/reports" component={Reports} />
        <Route path="/settings" component={Settings} />
        <Redirect to="/uploads" />
      </Switch>
    </MainTemplate>
  )
}

export default Admin
