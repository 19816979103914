import { isFuture } from 'date-fns'

const getStatus = (start, end) => {
  if (!isFuture(new Date(end))) {
    return 'Expired'
  } else if (isFuture(new Date(start))) {
    return 'Upcoming'
  } else {
    return 'Active'
  }
}

const getDiscountLabelColor = (start, end) => {
  if (!isFuture(new Date(end))) {
    return 'red'
  } else if (isFuture(new Date(start))) {
    return 'gray'
  } else {
    return 'green'
  }
}

export { getStatus, getDiscountLabelColor }
