import React from 'react'

export const initialBranding = {
  isTgt: null,
  primaryColorPalette: null,
  secondaryColorPalette: null,
  logoUrl: null,
  masterDealerId: null,
  masterDealerName: null,
}

export default React.createContext(initialBranding)
