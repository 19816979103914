export const toDateOrNull = (str) => (str ? new Date(str) : null)

export const getFileExtension = (name) => {
  const position = name.lastIndexOf('.')
  if (name === '' || position < 1) {
    return ''
  } else {
    return name.slice(position + 1)
  }
}

export const toCapitalCase = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
