import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import {
  faFireAlt,
  faBoxFull,
  faWrench,
  faTag,
} from '@fortawesome/pro-regular-svg-icons'

import Title from '../../Title'
import Breadcrumbs from '../../Breadcrumbs'
import Label from '../../Label'
import Badge from '../../Badge'
import { toCapitalCase } from '../../../utils/strings'
import usePrivateApi from '../../../hooks/usePrivateApi'
import SelectMenu from '../../SelectMenu'
import AuthContext from '../../../context/AuthContext'
import { UPDATE_ASSIGNED_TO } from '../../../utils/sockets'
import {
  getStatus,
  getUploadLabelColor,
  getTune,
  getTuneIcon,
  getVehicleTypeIcon,
} from '../../../utils/uploads'

const ViewUploadTitle = ({
  isAdmin,
  upload,
  socket,
  location,
  isDisconnected,
}) => {
  const [memberships, setMemberships] = useState(null)
  const [auth] = useContext(AuthContext)
  const [{ res, success }, { sendRequest, resetSuccess }] = usePrivateApi()

  useEffect(() => {
    if (isAdmin && auth.membership.accountType === 'ADMIN') {
      sendRequest({ url: '/admin/admins' })
    }
  }, [isAdmin, auth.membership, sendRequest])

  useEffect(() => {
    if (success) {
      const { memberships } = res.data

      // Make sure the current user is always on top of list
      if (memberships.length > 1) {
        const index = memberships.findIndex(
          (membership) => membership.id === auth.membership.id
        )

        memberships.splice(0, 0, memberships.splice(index, 1)[0])
      }
      setMemberships(
        memberships
          .filter((membership) => membership.status === 'ACTIVE')
          .map((membership) => ({
            ...membership,
            label:
              membership.id === auth.membership.id
                ? 'You'
                : `${membership.user.firstName} ${membership.user.lastName}`,
          }))
      )
      resetSuccess()
    }
  }, [success, resetSuccess, res, auth])

  const onAssignChange = async (selected) => {
    socket.emit(
      UPDATE_ASSIGNED_TO,
      selected.map((membership) => membership.id)
    )
  }

  const renderBtns = () => {
    if (
      isAdmin &&
      auth.membership.accountType === 'ADMIN' &&
      memberships &&
      upload.assignedTo
    ) {
      return (
        <SelectMenu
          disabled={isDisconnected}
          menuLeft
          selected={upload.assignedTo}
          by="id"
          setSelected={onAssignChange}
          buttonText="Assign upload"
          items={memberships}
          multiple
        />
      )
    }

    return null
  }

  const breadcrumbLinks = useMemo(() => {
    const uploadList = { label: 'Upload list', to: '/uploads' }

    if (location?.state?.listState) {
      const { pathname, page, filters } = location.state.listState
      uploadList.to = { pathname, state: { page, filters } }
    }

    return [
      uploadList,
      {
        label: (
          <>
            Upload number {upload.uploadNumber}{' '}
            <Label color={getUploadLabelColor(upload.status)} className="ml-2">
              {getStatus(upload.status)}
            </Label>
          </>
        ),
      },
    ]
  }, [upload, location])

  const getTitle = () => {
    const { vrm, vehicle, manualVehicle, vehicleType } = upload

    if (vehicleType === 'CAR') {
      const { make, model, startYear } = vehicle

      if (isAdmin && make && upload.createdBy?.dealer) {
        const { name, tgtNumber } = upload.createdBy.dealer
        return `${name} (${tgtNumber}) - ${make} ${model} ${startYear}`
      }

      // If VRM lookup vehicle, show VRM
      if (vrm) {
        return `${make} ${model} ${startYear} - ${vrm}`
      }

      // We may not have fetched our vehicle yet
      if (make) {
        return `${make} ${model} ${startYear}`
      }
    }

    if (vehicleType && vehicleType !== 'CAR') {
      const { make, model, year } = manualVehicle

      if (isAdmin && upload.createdBy?.dealer) {
        const { name, tgtNumber } = upload.createdBy.dealer

        return `${name} (${tgtNumber}) - ${make.toUpperCase()} ${model.toUpperCase()} ${year}`
      }

      return `${make.toUpperCase()} ${model.toUpperCase()} ${year}`
    }

    return '-'
  }

  const renderAssignmentTags = useCallback(() => {
    if (
      !auth.membership.accountType === 'ADMIN' ||
      !upload.assignedTo?.length
    ) {
      return
    }

    const assignees = [...upload.assignedTo]

    // Make sure the current user is always on top of list
    const index = assignees.findIndex(
      (assignee) => assignee.id === auth.membership.id
    )

    if (index >= 0) {
      assignees.splice(0, 0, assignees.splice(index, 1)[0])
    }

    return assignees.map((assignee) =>
      assignee.id === auth.membership.id ? (
        <Badge icon={faTag} color="orange" key={assignee.id}>
          You
        </Badge>
      ) : (
        <Badge icon={faTag} color="blue" key={assignee.id}>
          {assignee.user.firstName} {assignee.user.lastName}
        </Badge>
      )
    )
  }, [auth.membership, upload])

  return (
    <Title
      title={getTitle()}
      renderBreadcrumbs={() => <Breadcrumbs links={breadcrumbLinks} />}
      renderBtns={renderBtns}
    >
      <div className="mt-3 flex flex-wrap items-center justify-start gap-2 whitespace-nowrap">
        {renderAssignmentTags()}
        {upload.priority && (
          <Badge icon={faFireAlt} color="pink">
            Priority file
          </Badge>
        )}
        <Badge icon={getVehicleTypeIcon(upload.vehicleType)} color="gray">
          {toCapitalCase(upload.vehicleType)}
        </Badge>
        <Badge icon={getTuneIcon(upload.tune.tuneRequired)} color="gray">
          {getTune(upload.tune.tuneRequired)}
        </Badge>
        <Badge
          icon={upload.uploadType === 'FULL' ? faBoxFull : faWrench}
          color="gray"
        >
          {upload.uploadType === 'FULL' ? 'Full' : 'Adjustment'} remap
        </Badge>
      </div>
    </Title>
  )
}

export default ViewUploadTitle
