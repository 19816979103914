import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const UserCancelInviteModal = ({ isOpen, setIsOpen, membership }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/users/invite/${membership.id}`,
      method: 'delete',
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not cancel user invite. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Invitation cancelled"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Successfully cancelled the invite for{' '}
          <strong>
            {membership.user.firstName} {membership.user.lastName}
          </strong>{' '}
          ({membership.user.email}).
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Cancel user invitation"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Cancelling...' : 'Cancel invite',
        color: 'red',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        <strong>
          Are you sure you wish to cancel the invite for{' '}
          {membership.user.firstName} {membership.user.lastName}?
        </strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        This user won't be able to login or carry out any actions in the system
        until they accept an invite. You can send another invite in the future
        if required.
      </p>
    </Dialog>
  )
}

export default UserCancelInviteModal
