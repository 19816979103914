import React, { useState, useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import cn from 'classnames'

import usePrivateApi from '../../../../hooks/usePrivateApi'
import Button from '../../../../components/Button'
import WhiteLabelStatus from './Status'
import WhiteLabelDomain from './Domain'
import WhiteLabelEmail from './Email'
import WhiteLabelLogo from './Logo'
import WhiteLabelColorPalettePrimary from './ColorPalettePrimary'
import WhiteLabelColorPaletteSecondary from './ColorPaletteSecondary'

const WhiteLabel = (props) => {
  const { match, location, dealer } = props
  const [whiteLabel, setWhiteLabel] = useState(null)

  const links = useMemo(
    () => [
      { to: `${match.url}/status`, label: 'Status' },
      { to: `${match.url}/domain`, label: 'Domain' },
      { to: `${match.url}/email`, label: 'Email' },
      { to: `${match.url}/logo`, label: 'Logo' },
      { to: `${match.url}/primary-colors`, label: 'Primary colours' },
      { to: `${match.url}/secondary-colors`, label: 'Secondary colours' },
    ],
    [match.url]
  )

  const [{ res, success, error }, { sendRequest }] = usePrivateApi()

  useEffect(() => {
    sendRequest({
      url: `/admin/dealers/${dealer.id}/white-label`,
    })
  }, [sendRequest, dealer.id])

  useEffect(() => {
    if (success && whiteLabel === null) {
      setWhiteLabel(res.data.whiteLabel)
    }
  }, [success, res, whiteLabel, sendRequest])

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 flex-col p-18">
        <FontAwesomeIcon
          icon={faWifiSlash}
          className="text-gray-600"
          size="2x"
        />
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Could not retrieve tools
        </h3>
        <p className="text-sm text-gray-600 mt-1 mb-6">
          Please refresh the page to try again.
        </p>
        <Button color="white" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </div>
    )
  }

  if (whiteLabel === null) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <div className="flex-1 flex-col flex">
      <div className="border-b border-gray-200 pt-3 pb-3 overflow-x-auto">
        <nav className="flex items-center min-h-[40px] pl-4">
          {links.map((link) => (
            <Link
              className={cn(
                'rounded-md text-sm font-medium py-2 px-3 mr-3 no-underline whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500',
                {
                  'bg-blue-100 text-blue-900': location.pathname.startsWith(
                    link.to
                  ),
                  'text-gray-600 hover:bg-gray-50 hover:text-gray-800':
                    !location.pathname.startsWith(link.to),
                }
              )}
              key={link.to}
              to={link.to}
            >
              {link.label}
            </Link>
          ))}
        </nav>
      </div>
      <div className="flex flex-1 flex-col">
        <Switch>
          <Route
            path={`${match.url}/status`}
            render={() => (
              <WhiteLabelStatus
                dealer={dealer}
                whiteLabel={whiteLabel}
                setWhiteLabel={setWhiteLabel}
              />
            )}
          />
          <Route
            path={`${match.url}/domain`}
            render={() => (
              <WhiteLabelDomain
                dealer={dealer}
                whiteLabel={whiteLabel}
                setWhiteLabel={setWhiteLabel}
              />
            )}
          />
          <Route
            path={`${match.url}/email`}
            render={() => (
              <WhiteLabelEmail
                dealer={dealer}
                whiteLabel={whiteLabel}
                setWhiteLabel={setWhiteLabel}
              />
            )}
          />
          <Route
            path={`${match.url}/logo`}
            render={() => (
              <WhiteLabelLogo
                dealer={dealer}
                whiteLabel={whiteLabel}
                setWhiteLabel={setWhiteLabel}
              />
            )}
          />
          <Route
            path={`${match.url}/primary-colors`}
            render={() => (
              <WhiteLabelColorPalettePrimary
                dealer={dealer}
                whiteLabel={whiteLabel}
                setWhiteLabel={setWhiteLabel}
              />
            )}
          />
          <Route
            path={`${match.url}/secondary-colors`}
            render={() => (
              <WhiteLabelColorPaletteSecondary
                dealer={dealer}
                whiteLabel={whiteLabel}
                setWhiteLabel={setWhiteLabel}
              />
            )}
          />
          <Redirect
            to={{ pathname: `${match.url}/status`, state: location.state }}
          />
        </Switch>
      </div>
    </div>
  )
}

export default WhiteLabel
