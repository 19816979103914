import React from 'react'

const TechnicalItem = ({ label, value, hide }) => {
  if (hide) {
    return null
  }

  return (
    <div className="flex pb-1 mb-2 justify-between items-center text-sm border-b border-gray-200">
      <span>{label}:</span>
      <span className="font-semibold">
        {value === '' ? 'Not available' : value}
      </span>
    </div>
  )
}

const TechnicalInfo = ({ vehicle }) => {
  return (
    <div>
      <h4 className="text-base font-medium">Technical information</h4>
      <div className="mt-4">
        <TechnicalItem label="Model generation" value={vehicle.modelGen} />
        <TechnicalItem label="Model code" value={vehicle.modelCode} />
        <TechnicalItem label="Engine code" value={vehicle.engineCode} />
        <TechnicalItem label="ECU brand" value={vehicle.ecuBrand} />
        <TechnicalItem label="ECU version" value={vehicle.ecuVersion} />
        <TechnicalItem
          label="KTAG group"
          value={vehicle.ktagGroup}
          hide={vehicle.ktagGroup === ''}
        />
        <TechnicalItem
          label="KTAG protocol"
          value={vehicle.ktagProtocol}
          hide={vehicle.ktagProtocol === ''}
        />
        <TechnicalItem
          label="Communication"
          value={vehicle.communication}
          hide={vehicle.communication === ''}
        />
        <TechnicalItem
          label="CMD"
          value={vehicle.cmd}
          hide={vehicle.cmd === ''}
        />
        <TechnicalItem
          label="X17"
          value={vehicle.x17}
          hide={vehicle.x17 === ''}
        />
        <TechnicalItem
          label="KESSv2 protocol"
          value={vehicle.kessProtocol}
          hide={vehicle.kessProtocol === ''}
        />
        <TechnicalItem
          label="KESSv2 method"
          value={vehicle.kessMethod}
          hide={vehicle.kessMethod === ''}
        />
        <TechnicalItem label="Vehicle ref" value={`VDR-${vehicle.vehicleId}`} />
      </div>
    </div>
  )
}

export default TechnicalInfo
