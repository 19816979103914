import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AuthContext from '../../context/AuthContext'
import { ON_HOLD } from '../../utils/dealers'
import UploadList from '../../components/uploads/list'
import NewUpload from '../../components/uploads/new'
import ViewUpload from '../../components/uploads/view'

const Upload = ({ location }) => {
  const [auth] = useContext(AuthContext)

  return (
    <Switch>
      <Route
        path={[
          '/uploads/list/ready',
          '/uploads/list/in-progress',
          '/uploads/list/downloaded',
          '/uploads/list/re-credited',
          '/uploads/list/pending',
          '/uploads/list/on-hold',
          '/uploads/list/all',
        ]}
        render={(props) => <UploadList {...props} />}
      />
      {auth.membership.dealer.status !== ON_HOLD && (
        <Route path="/uploads/new" component={NewUpload} />
      )}
      <Route path="/uploads/view/:uploadId" component={ViewUpload} />
      <Redirect to={{ pathname: '/uploads/list/all', state: location.state }} />
    </Switch>
  )
}

export default Upload
