import React, { useCallback, useEffect } from 'react'
import { format } from 'date-fns'

import InputGroup from '../../../../components/InputGroup'
import usePrivateApi from '../../../../hooks/usePrivateApi'
import useEditableForm from '../../../../hooks/useEditableForm'
import Button from '../../../../components/Button'
import Label from '../../../../components/Label'

const WhiteLabelDomain = ({ whiteLabel, setWhiteLabel, dealer }) => {
  const [
    { res, success, error, isLoading },
    { sendRequest, resetSuccess, resetError },
  ] = usePrivateApi()

  const mapResData = useCallback(
    (data) => ({
      domain: data.domain,
    }),
    []
  )

  const [
    {
      formValues,
      hasSaved,
      showNoChanges,
      showChangesMade,
      showChangesSaving,
      showChangesSaved,
      saveError,
    },
    { setFormValues, resetFormValues, saveFormValues },
  ] = useEditableForm({
    initialValues: {
      domain: whiteLabel.domain,
    },
    mapResData,
  })

  const handleSubmit = () => {
    saveFormValues({
      url: `/admin/dealers/${dealer.id}/white-label/domain`,
      method: 'patch',
      data: formValues,
    })
  }

  const handleCheckDns = async () => {
    resetError()
    sendRequest({
      url: `/admin/dealers/${dealer.id}/white-label/domain/verify`,
    })
  }

  const handleCancel = () => {
    resetFormValues()
  }

  useEffect(() => {
    if (hasSaved) {
      setWhiteLabel((prevState) => ({
        ...prevState,
        domain: { ...prevState.domain, ...formValues.domain },
      }))
    }
  }, [hasSaved, setWhiteLabel, formValues])

  useEffect(() => {
    if (success) {
      setWhiteLabel((prevState) => ({
        ...prevState,
        domain: { ...prevState.domain, ...res.data.domain },
      }))

      resetSuccess()
    }
  }, [setWhiteLabel, success, res, resetSuccess])

  return (
    <>
      <div
        className={
          'border-b border-gray-200 px-6 py-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start flex-1'
        }
      >
        <div className="max-w-sm flex-1">
          <h2 className="text-lg font-semibold tracking-tight">
            Custom domain
          </h2>
          <div className="text-gray-600 text-sm space-y-2 max-w-prose">
            <p>
              Enter the domain where the white-labelled portal should be hosted.
              Please note that the domain should contain a subdomain, for
              example: portal.dealerdomain.com.
            </p>
            <p>
              Once you have added the domain the dealer must add the new DNS
              record in their domain settings to make the portal work.
            </p>
            <p>
              Once the dealer has confirmed the DNS record has been changed you
              need to verify that the domain has been set up correctly.
            </p>
          </div>
        </div>
        <div className="flex-1">
          <InputGroup
            required
            label="Domain"
            id="domain"
            value={formValues.domain.name}
            onChange={(e) =>
              setFormValues({ domain: { name: e.target.value } })
            }
          />
          {whiteLabel.domain.name && (
            <>
              <div className="my-6">
                <div className="flex items-center space-x-4">
                  <p className="text-sm font-semibold tracking-tight text-gray-900">
                    DNS status:
                  </p>
                  <Label color={whiteLabel.domain.verified ? 'green' : 'pink'}>
                    {whiteLabel.domain.verified ? 'Verified' : 'Not verified'}
                  </Label>
                </div>
                {error && (
                  <p className="text-sm text-red-600 mt-2">
                    Error whilst checking DNS
                  </p>
                )}
                {!whiteLabel.domain.verified && (
                  <>
                    <p className="text-sm text-gray-700 mt-2">
                      Last checked:{' '}
                      {
                        //prettier-ignore
                        whiteLabel.domain.lastChecked
                          ? format(
                            new Date(whiteLabel.domain.lastChecked),
                            "dd MMM yyyy 'at' h:mma"
                          )
                          : 'Never'
                      }
                    </p>
                    <p className="text-xs text-gray-700 mt-2">
                      Note it may take up to 24 hours for DNS changes to take
                      effect.
                    </p>
                    <Button
                      color="white"
                      disabled={isLoading}
                      onClick={handleCheckDns}
                      size="sm"
                      className="mt-3"
                    >
                      {isLoading ? 'Checking...' : 'Check DNS'}
                    </Button>
                  </>
                )}
              </div>
              {!whiteLabel.domain.verified && (
                <div className="mt-4 rounded-md p-4 bg-blue-50 border border-blue-200">
                  <h3 className="font-semibold text-sm tracking-tight text-gray-900 mb-2">
                    DNS record to add (by dealer)
                  </h3>
                  <h4 className="text-xs tracking-tight font-semibold text-gray-900 uppercase">
                    Type:
                  </h4>
                  <p className="text-sm font-semibold tracking-tight text-blue-600">
                    CNAME
                  </p>
                  <h4 className="text-xs tracking-tight font-semibold text-gray-900 uppercase mt-3">
                    Name:
                  </h4>
                  <p className="text-sm font-semibold tracking-tight text-blue-600">
                    {whiteLabel.domain.name}
                  </p>
                  <h4 className="text-xs tracking-tight font-semibold text-gray-900 uppercase mt-3">
                    Target:
                  </h4>
                  <p className="text-sm font-semibold tracking-tight text-blue-600">
                    topgear-web-ckd.pages.dev
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="py-4 px-6 sm:flex sm:items-center sm:justify-between">
        <div className="text-sm">
          {saveError ? (
            <p className="text-red-600">
              {saveError.response.status === 409
                ? 'Domain is already assigned to dealer'
                : 'Error saving changes - please try again'}
            </p>
          ) : (
            <p className="text-gray-600">
              {showChangesSaved && 'Your changes have been saved'}
              {showChangesSaving && 'Saving your changes...'}
              {showChangesMade && 'Please save your changes'}
              {showNoChanges && 'No changes have made'}
            </p>
          )}
        </div>
        <div className="mt-4 flex flex-col-reverse sm:block sm:mt-0 sm:space-x-3">
          <Button
            className="mt-3 w-full justify-center sm:w-auto sm:mt-0"
            disabled={!showChangesMade}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="w-full justify-center sm:w-auto"
            disabled={!showChangesMade || showChangesSaving}
            onClick={handleSubmit}
          >
            {showChangesSaved && 'Changes saved'}
            {showChangesSaving && 'Saving...'}
            {(showNoChanges || showChangesMade) && 'Save changes'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default WhiteLabelDomain
