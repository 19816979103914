import React, { useState, useCallback, useEffect, useContext } from 'react'
import isEmail from 'validator/lib/isEmail'

import useEditableForm from '../../../hooks/useEditableForm'
import useObjState from '../../../hooks/useObjState'
import AuthContext from '../../../context/AuthContext'
import Button from '../../Button'
import AccountProfile from './Profile'
import AccountEmail from './Email'
import AccountPassword from './Password'

const Account = () => {
  const [auth] = useContext(AuthContext)
  const { user } = auth.membership

  const [passwords, setPasswords] = useObjState({
    current: '',
    new: '',
    conf: '',
  })

  const [emailError, setEmailError] = useState(null)
  const [currentPasswordError, setCurrentPasswordError] = useState(null)
  const [newPasswordError, setNewPasswordError] = useState(null)
  const [confPasswordError, setConfPasswordError] = useState(null)
  const [generalError, setGeneralError] = useState(null)

  const mapResData = useCallback(
    (data) => ({
      id: data.user.id,
      firstName: data.user.firstName,
      lastName: data.user.lastName,
      email: data.user.email,
    }),
    []
  )

  const [
    {
      formValues,
      showNoChanges,
      showChangesMade,
      showChangesSaving,
      showChangesSaved,
      saveError,
    },
    { setFormValues, saveFormValues, resetFormValues, setHasEdits },
  ] = useEditableForm({
    initialValues: {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    },
    mapResData,
  })

  useEffect(() => {
    if (
      passwords.current !== '' ||
      passwords.new !== '' ||
      passwords.conf !== ''
    ) {
      setHasEdits(true)
    } else {
      setHasEdits(false)
    }
  }, [passwords, setHasEdits])

  useEffect(() => {
    if (saveError) {
      if (saveError.response) {
        if (saveError.response.data.email) {
          setEmailError('An account already exists with this email.')
        } else if (saveError.response.data.password) {
          setCurrentPasswordError('Password is incorrect.')
        } else {
          setGeneralError(true)
        }
      } else {
        setGeneralError(true)
      }
    }
  }, [saveError])

  const { firstName, lastName, email } = formValues

  const resetErrors = () => {
    setEmailError(null)
    setCurrentPasswordError(null)
    setNewPasswordError(null)
    setConfPasswordError(null)
    setGeneralError(null)
  }

  const handleCancel = () => {
    resetErrors()
    resetFormValues()
    setPasswords({ current: '', new: '', conf: '' })
  }

  const handleSave = () => {
    resetErrors()

    if (passwords.current.length > 0 && passwords.current.length < 8) {
      return setCurrentPasswordError(
        'Password must be at least 8 characters long.'
      )
    }

    if (passwords.new.length > 0 && passwords.new.length < 8) {
      return setNewPasswordError('Password must be at least 8 characters long.')
    }

    if (passwords.new !== passwords.conf) {
      return setConfPasswordError('Must match new password.')
    }

    saveFormValues({
      url: '/settings/user',
      method: 'patch',
      data: {
        firstName,
        lastName,
        email,
        currentPassword: passwords.current,
        newPassword: passwords.new,
      },
    })
  }

  return (
    <div>
      <AccountProfile
        formValues={formValues}
        setFormValues={setFormValues}
        disabled={showChangesSaving}
      />
      <AccountEmail
        formValues={formValues}
        setFormValues={setFormValues}
        disabled={showChangesSaving}
        emailError={emailError}
        resetEmailError={() => setEmailError(null)}
      />
      <AccountPassword
        passwords={passwords}
        setPasswords={setPasswords}
        disabled={showChangesSaving}
        currentPasswordError={currentPasswordError}
        newPasswordError={newPasswordError}
        confPasswordError={confPasswordError}
      />
      <div className="py-4 px-6 sm:flex sm:items-center sm:justify-between">
        <div className="text-sm">
          {generalError ? (
            <p className="text-red-600">
              Error saving changes - please try again
            </p>
          ) : (
            <p className="text-gray-600">
              {showChangesSaved && 'Your changes have been saved'}
              {showChangesSaving && 'Saving your changes...'}
              {showChangesMade && 'Please save your changes'}
              {showNoChanges && 'No changes have made'}
            </p>
          )}
        </div>
        <div className="mt-4 flex flex-col-reverse sm:block sm:mt-0 sm:space-x-3">
          <Button
            className="mt-3 w-full justify-center sm:w-auto sm:mt-0"
            disabled={!showChangesMade}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="w-full justify-center sm:w-auto"
            disabled={
              !firstName || !lastName || !isEmail(email) || !showChangesMade
            }
            onClick={handleSave}
          >
            {showChangesSaved && 'Changes saved'}
            {showChangesSaving && 'Saving...'}
            {(showNoChanges || showChangesMade) && 'Save changes'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Account
