import React, { useEffect } from 'react'
import { format } from 'date-fns'
import currency from 'currency.js'

import usePrivateApi from '../../../hooks/usePrivateApi'
import { downloadBuffer } from '../../../utils/files'
import TextButton from '../../../components/TextButton'

const BillingDocItem = (props) => {
  const { reference, date, total, docId, isInvoice } = props

  const [{ res, success, isLoading }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    if (success) {
      resetSuccess()
      downloadBuffer({
        buffer: res.data,
        filename: `${reference}.pdf`,
        type: 'application/pdf',
      })
    }
  }, [success, resetSuccess, res, reference])

  const handleDownload = () => {
    if (isInvoice) {
      sendRequest({
        url: `/billing/docs/invoices/${docId}/pdf`,
        responseType: 'arraybuffer',
      })
    } else {
      sendRequest({
        url: `/billing/docs/credit-notes/${docId}/pdf`,
        responseType: 'arraybuffer',
      })
    }
  }

  return (
    <div className="flex items-center justify-between py-3 px-4 text-sm">
      <p className="text-gray-900">{format(new Date(date), 'dd/MM/yyyy')}</p>
      <p className="text-gray-600">{reference}</p>
      <p className="font-medium">£{currency(total).format()}</p>
      <TextButton disabled={isLoading} onClick={handleDownload}>
        {isLoading ? 'Downloading...' : 'Download'}
      </TextButton>
    </div>
  )
}

export default BillingDocItem
