import React, { useState } from 'react'
import axios from 'axios'

import Button from '../../../../components/Button'
import ImageUploadModal from '../../../../components/ImageUploadModal'
import useLocalStorage from '../../../../hooks/useLocalStorage'

const API_URL = import.meta.env.VITE_API_URL

const DealerWhiteLabelLogo = ({ dealer, whiteLabel, setWhiteLabel }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isPreparing, setIsPreparing] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(null)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [getAuthToken] = useLocalStorage('authToken')
  const authToken = getAuthToken()

  const handleSave = async (canvasScaled) => {
    try {
      setIsUploading(true)
      setIsPreparing(true)

      const getRes = await axios({
        baseURL: API_URL,
        url: `/admin/dealers/${dealer.id}/white-label/logo`,
        method: 'get',
        headers: { Authorization: `Bearer ${authToken}` },
      })

      setIsPreparing(false)

      canvasScaled.toBlob(async (blob) => {
        await axios({
          url: getRes.data.url,
          data: blob,
          method: 'put',
          onUploadProgress: (e) => {
            setUploadProgress(Math.round((e.loaded * 100) / e.total))
          },
        })

        const patchRes = await axios({
          baseURL: API_URL,
          url: `/admin/dealers/${dealer.id}/white-label/logo`,
          method: 'patch',
          headers: { Authorization: `Bearer ${authToken}` },
          data: { key: getRes.data.key },
        })

        setSuccess(true)
        setIsUploading(false)
        setWhiteLabel((prevState) => ({
          ...prevState,
          logoUrl: patchRes.data.url,
        }))

        setModalOpen(false)
      })
    } catch (err) {
      setError(err)
    }
  }

  return (
    <div
      className={
        'px-6 py-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start'
      }
    >
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight">Dealer logo</h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            The dealer logo is used on various pages on the site, such as the
            sign-in page and the reset password page.
          </p>
          <p>
            Transparent PNG files work best. The logo will be displayed with a
            height of 72 pixels and a maximum width of 300 pixels. We therefore
            recommend a resolution height of 144 pixels with a maximum width of
            600 pixels.
          </p>
          <p>Example recommended resolution: 400x144 pixels</p>
        </div>
      </div>
      <div className="flex-1">
        {!whiteLabel.logoUrl && (
          <>
            <div className="border border-gray-200 bg-gray-50 rounded-md mt-2 w-60 text-center px-4 py-4">
              <p className="text-gray-500 font-medium text-sm tracking-tight">
                No logo uploaded
              </p>
            </div>
            <Button
              className="mt-6"
              size="sm"
              color="primary"
              onClick={() => setModalOpen(true)}
            >
              Upload logo
            </Button>
          </>
        )}
        {whiteLabel.logoUrl && (
          <>
            <div className="bg-gray-50 rounded-md px-4 py-3 w-[250px] flex justify-center">
              <img
                alt="Dealer logo"
                className="h-[72px] w-auto"
                src={whiteLabel.logoUrl}
                height={64}
                width={200}
              />
            </div>
            <Button
              className="mt-6"
              size="sm"
              color="white"
              onClick={() => setModalOpen(true)}
            >
              Change logo
            </Button>
          </>
        )}
        <ImageUploadModal
          isOpen={modalOpen}
          isUploading={isUploading}
          isPreparing={isPreparing}
          uploadProgress={uploadProgress}
          handleSave={handleSave}
          error={error}
          success={success}
          setIsOpen={() => setModalOpen(false)}
          btnText={isUploading ? 'Saving...' : 'Save logo'}
          title="Upload dealer logo"
          editorInline={false}
          avatarEditorProps={{
            height: 144,
            width: 400,
            className: 'bg-gray-50 rounded-md',
            borderRadius: 0,
          }}
        />
      </div>
    </div>
  )
}

export default DealerWhiteLabelLogo
