import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Badge = (props) => {
  const { color, icon, children } = props

  const containerCn = cn('px-2 py-1 rounded-md flex items-center border', {
    'bg-pink-100 border-transparent': color === 'pink',
    'bg-white border-gray-200': color === 'white',
    'bg-gray-100 border-transparent': color === 'gray',
    'bg-teal-100 border-transparent': color === 'teal',
    'bg-blue-100 border-transparent': color === 'blue',
    'bg-yellow-100 border-transparent': color === 'yellow',
    'bg-purple-100 border-transparent': color === 'purple',
    'bg-orange-100 border-transparent': color === 'orange',
  })

  const iconCn = cn({
    'text-pink-600': color === 'pink',
    'text-gray-600': color === 'gray',
    'text-teal-600': color === 'teal',
    'text-blue-600': color === 'blue',
    'text-yellow-600': color === 'yellow',
    'text-purple-600': color === 'purple',
    'text-orange-600': color === 'orange',
  })

  const textCn = cn('text-xs font-semibold tracking-tight ml-2', {
    'text-pink-700': color === 'pink',
    'text-gray-700': color === 'gray',
    'text-teal-700': color === 'teal',
    'text-blue-700': color === 'blue',
    'text-yellow-700': color === 'yellow',
    'text-purple-700': color === 'purple',
    'text-orange-700': color === 'orange',
  })

  return (
    <div className={containerCn}>
      <FontAwesomeIcon icon={icon} className={iconCn} size="sm" />
      <p className={textCn}>{children}</p>
    </div>
  )
}

export default Badge
