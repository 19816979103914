import React, { useState, useEffect } from 'react'
import ReactSwitch from 'react-switch'

const Switch = ({ checked, onChange, disabled, id, small }) => {
  const [color, setColor] = useState('#fff')

  useEffect(() => {
    const style = window.getComputedStyle(document.body)
    setColor(style.getPropertyValue('--switch-on-color').replace(/\s/g, ''))
  }, [])

  return (
    <ReactSwitch
      id={id}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      offColor="#A0AEC0"
      onColor={color}
      uncheckedIcon={false}
      checkedIcon={false}
      height={small ? 18 : 24}
      width={small ? 32 : 48}
      handleDiameter={small ? 12 : 16}
    />
  )
}

export default Switch
