import React from 'react'
import currency from 'currency.js'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

const CreditPackageItem = ({ creditPackage, baseCost, discount }) => {
  const { name, cost, credits } = creditPackage

  const getDiscountedAmount = () => {
    if (!discount) {
      return cost
    } else {
      const multiplier = (100 - discount.percent) / 100
      return Math.round(cost * multiplier)
    }
  }

  const getSaving = () => {
    const pricePerFile = currency(getDiscountedAmount()).divide(credits)
    const savingPerFile = currency(baseCost).subtract(pricePerFile)
    const savingPercentage = savingPerFile.divide(baseCost).value
    return Math.round(savingPercentage * 100)
  }

  const saving = getSaving()

  return (
    <Link
      className="block py-5 px-6 relative hover:bg-gray-50 sm:flex sm:items-center sm:space-x-3"
      to={{
        pathname: '/credits/checkout',
        state: { purchase: { creditPackage, saving, discount } },
      }}
    >
      <p className="font-medium tracking-tight sm:flex-1 sm:text-sm">{name}</p>
      <p className="font-medium text-sm sm:flex-[2] sm:text-right">
        £{currency(getDiscountedAmount() / 100).format()}{' '}
        <span className="text-xs text-gray-600">plus taxes</span>
      </p>
      <div className="flex-[2] sm:text-right">
        {saving ? (
          <p className="text-green-600 font-medium text-sm">Save {saving}%</p>
        ) : (
          <p className="text-gray-600 text-sm">No saving</p>
        )}
      </div>
      <div className="pr-6">
        <FontAwesomeIcon
          className="absolute right-5 top-1/2 -translate-y-1/2 text-gray-400"
          icon={faAngleRight}
          size="lg"
        />
      </div>
    </Link>
  )
}

export default CreditPackageItem
