import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NewUploadFormIndex from './FormIndex'
import NewUploadSuccess from './Success'
import NewUploadError from './Error'
import NewUploadStripeError from './ErrorStripe'
import NewUploadPaymentIntentError from './ErrorPaymentIntent'

const Upload = () => {
  return (
    <Switch>
      <Route exact path="/uploads/new" component={NewUploadFormIndex} />
      <Route path="/uploads/new/error" component={NewUploadError} />
      <Route
        path="/uploads/new/payment-error"
        component={NewUploadStripeError}
      />
      <Route
        path="/uploads/new/payment-intent-error"
        component={NewUploadPaymentIntentError}
      />
      <Route path="/uploads/new/success" component={NewUploadSuccess} />
      <Redirect to="/uploads/new" />
    </Switch>
  )
}

export default Upload
