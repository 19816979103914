import { useState, useEffect, useContext, useCallback } from 'react'

import usePublicApi from './usePublicApi'
import useLocalStorage from './useLocalStorage'
import BrandingContext from '../context/BrandingContext'

const tgtHostNames = ['localhost', '192.168.1.205', 'files.topgear-tuning.com']

const ENABLE_CACHE = false

const useWhiteLabel = () => {
  const [ready, setReady] = useState(null)
  const [, setBranding] = useContext(BrandingContext)
  const [{ res, error, success }, { sendRequest }] = usePublicApi()
  const [getLocalBranding, setLocalBranding] = useLocalStorage('branding')

  const addCss = useCallback((color) => {
    const link = document.createElement('link')
    link.href = `/colors/${color}.css`
    link.type = 'text/css'
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }, [])

  const removeFavicon = useCallback(() => {
    const links = document.querySelectorAll('link[rel*="icon"]')
    links.forEach((link) => link.remove())

    const manifest = document.querySelector('link[rel*="manifest"]')

    if (manifest) {
      manifest.remove()
    }

    const mstiles = document.querySelectorAll('meta[name*="msapplication"]')
    mstiles.forEach((tile) => tile.remove())
  }, [])

  const updateTitle = useCallback((title) => {
    document.title = title
  }, [])

  useEffect(() => {
    if (tgtHostNames.includes(window.location.hostname)) {
      setBranding({
        isTgt: true,
        primaryColorPalette: 'blue',
        secondaryColorPalette: 'gray',
      })

      addCss('blue')
      addCss('gray')
      updateTitle('Topgear Tuning')
      return setReady(true)
    }

    if (ENABLE_CACHE) {
      const localBranding = JSON.parse(getLocalBranding())

      if (localBranding) {
        setBranding(localBranding)
        addCss(localBranding.primaryColorPalette)
        addCss(localBranding.secondaryColorPalette)
        return setReady(true)
      }
    }

    sendRequest({
      url: `/branding/${window.location.hostname}`,
    })
  }, [setBranding, getLocalBranding, sendRequest, addCss, updateTitle])

  useEffect(() => {
    if (success) {
      setBranding({ isTgt: false, ...res.data.branding })

      if (ENABLE_CACHE) {
        setLocalBranding(JSON.stringify({ isTgt: false, ...res.data.branding }))
      }

      addCss(res.data.branding.primaryColorPalette)
      addCss(res.data.branding.secondaryColorPalette)
      removeFavicon()
      updateTitle(res.data.branding.masterDealerName)
      return setReady(true)
    }
  }, [
    res,
    setLocalBranding,
    success,
    setBranding,
    addCss,
    removeFavicon,
    updateTitle,
  ])

  return [ready, error]
}

export default useWhiteLabel
