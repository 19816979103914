import React from 'react'

import InputGroup from '../../InputGroup'
import Avatar from './Avatar'

const AccountProfile = ({ formValues, setFormValues, disabled }) => {
  return (
    <div className="border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          Your profile
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>Change your name and profile photo.</p>
        </div>
      </div>
      <div className="flex-1 space-y-4">
        <div className="space-y-4 sm:space-y-0 sm:flex sm:flex-1 sm:space-x-3">
          <InputGroup
            required
            className="flex-1"
            label="First name"
            id="firstName"
            disabled={disabled}
            onChange={(e) => setFormValues({ firstName: e.target.value })}
            value={formValues.firstName}
          />
          <InputGroup
            required
            className="flex-1"
            label="Last name"
            id="lastName"
            disabled={disabled}
            onChange={(e) => setFormValues({ lastName: e.target.value })}
            value={formValues.lastName}
          />
        </div>
        <Avatar />
      </div>
    </div>
  )
}

export default AccountProfile
