import { useCallback } from 'react'

const useLocalStorage = key => {
  const getValue = useCallback(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? item : null
    } catch (err) {
      throw err
    }
  }, [key])

  const setValue = useCallback(
    value => {
      try {
        if (value) {
          window.localStorage.setItem(key, value)
        } else {
          window.localStorage.removeItem(key)
        }
      } catch (err) {
        throw err
      }
    },
    [key]
  )

  return [getValue, setValue]
}

export default useLocalStorage
