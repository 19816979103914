import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import SystemStatus from '../components/SystemStatus'
import AuthContext from '../context/AuthContext'
import BrandingContext from '../context/BrandingContext'

const MainTemplate = ({ admin, writer, location, children }) => {
  const [auth] = useContext(AuthContext)
  const [branding] = useContext(BrandingContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  if (!auth.isLoggedIn) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  if (admin) {
    return (
      <>
        <Route render={(props) => <NavBar admin {...props} />} />
        <SystemStatus />
        <div className="bg-gray-50 min-h-[calc(100vh-210px)] pb-16">
          {children}
        </div>
        <Footer />
      </>
    )
  }

  if (writer) {
    return (
      <>
        <Route render={(props) => <NavBar writer {...props} />} />
        <SystemStatus />
        <div className="bg-gray-50 min-h-[calc(100vh-210px)] pb-16">
          {children}
        </div>
        <Footer />
      </>
    )
  }

  return (
    <>
      <Route component={NavBar} />
      {branding.isTgt && (
        <SystemStatus
          checkForAutoPopUp={location.pathname.includes('/dashboard')}
        />
      )}
      <div className="bg-gray-50 min-h-[calc(100vh-210px)] pb-16">
        {children}
      </div>
      <Footer />
    </>
  )
}

export default MainTemplate
