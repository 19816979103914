import React from 'react'

import AdminNavBar from './Admin'
import WriterNavBar from './Writer'
import DealerNavBar from './Dealer'

const NavBar = ({ location, admin, writer }) => {
  if (admin) {
    return <AdminNavBar location={location} />
  }

  if (writer) {
    return <WriterNavBar location={location} />
  }

  return <DealerNavBar location={location} />
}

export default NavBar
