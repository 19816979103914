import React from 'react'

import FileDropzone from '../../FileDropzone'

const NewUploadFileUpload = (props) => {
  const { files, addFiles, removeFile } = props

  return (
    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-6 py-8">
      <div className="flex-[2]">
        <h3 className="text-xl font-semibold tracking-tight">File upload</h3>
        <div className="text-gray-700 text-sm mt-1 space-y-2">
          <p>Please upload your ECU file with any data logs you may have.</p>
          <p>
            Uploading multiple files is supported, however ZIP folders are not.
            Maximum upload size is 25MB.
          </p>
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm px-6 py-5 flex-[3] space-y-4">
        <FileDropzone
          files={files}
          onAccepted={(files) => addFiles(files)}
          onRejected={(fileRejections) =>
            addFiles(
              fileRejections.map(({ file, errors }) => {
                file.rejectReason = errors[0].message
                return file
              })
            )
          }
          onRemoveFile={(index) => removeFile(index)}
        />
      </div>
    </div>
  )
}

export default NewUploadFileUpload
