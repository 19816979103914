import React, { useState, useEffect } from 'react'
import currency from 'currency.js'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'
import InputGroup from '../../../components/InputGroup'

const ViewDealerCreditNewPackageModal = (props) => {
  const { isOpen, setIsOpen, onSuccess } = props

  const [packageName, setPackageName] = useState('')
  const [packageCredits, setPackageCredits] = useState('')
  const [packageCostStr, setPackageCostStr] = useState('')
  const [packageCost, setPackageCost] = useState('')

  const [{ res, success, error, isLoading }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    if (isOpen) {
      setPackageName('')
      setPackageCredits('')
      setPackageCostStr('')
      setPackageCost('')
    }
  }, [isOpen])

  useEffect(() => {
    if (success) {
      onSuccess(res.data.creditPackages)
      resetSuccess()
    }
  }, [onSuccess, res, success, resetSuccess])

  const handleCostChange = (e) => {
    const input = e.target.value.replace(/[^0-9.,]/g, '')
    const inputCurrency = currency(input)
    setPackageCost(
      inputCurrency.value ? Math.round(inputCurrency.value * 100) : null
    )
    setPackageCostStr(input)
  }

  const handleCreditsChange = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, '')
    setPackageCredits(input)
  }

  const getPricePerFile = () => {
    if (!packageCredits || packageCredits === '0' || !packageCost) {
      return ''
    }

    const cost = currency(packageCost / 100)
      .divide(packageCredits)
      .format()

    return `£${cost} per file`
  }

  const handleSubmit = () => {
    sendRequest({
      url: '/admin/credits/packages',
      method: 'post',
      data: {
        name: packageName,
        credits: packageCredits,
        cost: packageCost,
      },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm">
          There was an error saving the credit package.
        </p>
        <Button className="mt-4" color="white" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="New credit package"
      buttonProps={{
        children: isLoading ? 'Creating...' : 'Create package',
        onClick: handleSubmit,
        type: 'button',
        color: 'primary',
        disabled:
          isLoading ||
          !packageName ||
          !packageCredits ||
          packageCredits === '0' ||
          !packageCost,
      }}
    >
      <div className="mt-4 space-y-4">
        <InputGroup
          required
          label="Package name"
          id="packageName"
          disabled={isLoading}
          onChange={(e) => setPackageName(e.target.value)}
          value={packageName}
        />
        <div className="flex space-x-3">
          <InputGroup
            required
            className="flex-1"
            label="Number of credits"
            id="numberOfCredits"
            disabled={isLoading}
            help="Amount of credits to add to account"
            onChange={handleCreditsChange}
            value={packageCredits}
          />
          <InputGroup
            required
            className="flex-1"
            label="Total cost"
            leadingAddon="£"
            inputClassName="text-right"
            id="totalCost"
            onBlur={() =>
              setPackageCostStr(
                currency(packageCost / 100, { symbol: '' }).format()
              )
            }
            disabled={isLoading}
            onChange={handleCostChange}
            help={getPricePerFile()}
            value={packageCostStr}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ViewDealerCreditNewPackageModal
