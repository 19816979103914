import React from 'react'

export const initialAuth = {
  isLoggedIn: false,
  hasExpired: false,
  membership: {
    id: null,
    owner: null,
    user: {
      id: null,
      accountType: null,
      status: null,
      email: '',
      firstName: '',
      lastName: '',
      createdAt: null,
      updatedAt: null,
      imageUrl: null,
    },
    dealer: {
      id: null,
      name: '',
      address: {
        id: null,
        status: null,
        line1: '',
        line2: '',
        city: '',
        region: '',
        postcode: '',
        country: null,
        createdAt: null,
        updatedAt: null,
      },
      terms: {
        acceptedBy: null,
        acceptedAt: null,
        acceptedIP: null,
        acceptedVersion: null,
        requiresAcceptance: null,
      },
      tgtNumber: null,
      logoKey: null,
    },
  },
}

export default React.createContext(initialAuth)
