import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faCheckCircle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'

const Alert = (props) => {
  const {
    accent,
    title,
    actions,
    link,
    onDismiss,
    className,
    success,
    error,
    info,
    children,
  } = props

  const bgColorCn = cn({
    'bg-green-100 border-green-400': success,
    'bg-red-100 border-red-400': error,
    'bg-blue-50 border-blue-400': info,
  })

  const iconColorCn = cn({
    'text-green-400': success,
    'text-red-400': error,
    'text-blue-400': info,
  })

  const titleColorCn = cn({
    'text-green-800': success,
    'text-red-800': error,
    'text-blue-800': info,
  })

  const contentColorCn = {
    'text-green-800': success,
    'text-red-800': error,
    'text-blue-800': info,
  }

  const actionBaseCn = [
    'px-2',
    'py-1.5',
    'rounded-md',
    'text-sm',
    'leading-5',
    'font-medium',
    'focus:outline-none',
    'transition',
    'ease-in-out',
    'duration-150',
  ]

  const actionColorCn = cn({
    'text-green-800 hover:bg-green-100 focus:bg-green-100': success,
    'text-red-800 hover:bg-red-100 focus:bg-red-100': error,
    'text-blue-800 hover:bg-blue-100 focus:bg-blue-100': info,
  })

  const linkBaseCn = [
    'whitespace-nowrap',
    'font-medium',
    'transition',
    'ease-in-out',
    'duration-150',
  ]

  const linkColorCn = cn({
    'text-green-700 hover:text-green-600': success,
    'text-red-700 hover:text-red-600': error,
    'text-blue-700 hover:text-blue-600': info,
  })

  const dismissBaseCn = [
    'inline-flex',
    'rounded-md',
    'p-1.5',
    'focus:outline-none',
    'transition',
    'ease-in-out',
    'duration-150',
  ]

  const dismissColorCn = cn({
    'text-green-500 hover:bg-green-100 focus:bg-green-100': success,
    'text-red-500 hover:bg-red-100 focus:bg-red-100': error,
    'text-blue-500 hover:bg-blue-100 focus:bg-blue-100': info,
  })

  const renderIcon = () => {
    const iconCn = cn('h-5 w-5', iconColorCn)

    if (success) {
      return <FontAwesomeIcon icon={faCheckCircle} className={iconCn} />
    }

    if (error) {
      return <FontAwesomeIcon icon={faTimesCircle} className={iconCn} />
    }

    return <FontAwesomeIcon icon={faInfoCircle} className={iconCn} />
  }

  const renderActions = () => {
    return (
      <div className="mt-4">
        <div className="-mx-2 -my-1.5 flex">
          {actions.map((action, index) => {
            const { component: Component, componentProps } = action
            const { children, className, ...rest } = componentProps

            const actionCn = cn(
              actionBaseCn,
              actionColorCn,
              { 'ml-3': index !== 0 },
              className
            )

            return (
              <Component className={actionCn} {...rest}>
                {children}
              </Component>
            )
          })}
        </div>
      </div>
    )
  }

  const renderLink = () => {
    const { component: Component, componentProps } = link
    const { children, ...rest } = componentProps

    const linkCn = cn(linkBaseCn, linkColorCn)

    return (
      <p className="mt-3 text-sm leading-5 md:mt-0 md:ml-6">
        <Component className={linkCn} {...rest}>
          {children} &rarr;
        </Component>
      </p>
    )
  }

  const renderDismiss = () => {
    const dismissCn = cn(dismissBaseCn, dismissColorCn)

    return (
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button className={dismissCn} onClick={onDismiss} type="button">
            <FontAwesomeIcon icon={faTimes} className="h-auto w-5" />
          </button>
        </div>
      </div>
    )
  }

  const bgCn = cn('p-4 border', bgColorCn, className, {
    'rounded-md': !accent,
    'border-l-4': accent,
  })

  const contentContainerCn = cn('ml-3', {
    'flex-1 md:flex md:justify-between': link,
  })

  const contentCn = cn('text-sm leading-5', contentColorCn, {
    'mt-2': title,
  })

  return (
    <div className={bgCn}>
      <div className="flex items-center">
        <div className="flex-shrink-0">{renderIcon()}</div>
        <div className={contentContainerCn}>
          <div>
            {title && (
              <h3 className={cn('text-sm leading-5 font-medium', titleColorCn)}>
                {title}
              </h3>
            )}
            {children && <div className={contentCn}>{children}</div>}
          </div>
          {actions && renderActions()}
          {link && renderLink()}
        </div>
        {onDismiss && renderDismiss()}
      </div>
    </div>
  )
}

export default Alert
