import React, { useState, useEffect } from 'react'

import history from '../../../history'
import Dialog from '../../../components/Dialog'

const CheckoutErrorModal = (props) => {
  const { isOpen, stripeError, onRetry } = props

  // Used to prevent flash on content disappearing on modal fade-out
  const [_error, _setError] = useState(null)

  useEffect(() => {
    if (isOpen) {
      _setError(stripeError)
    }
  }, [isOpen, stripeError])

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => history.push('/credits')}
      title="Purchase not successful"
      buttonProps={{
        onClick: onRetry,
        children: 'Try again',
        color: 'primary',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        We have been unable to process your purchase for the following reason:
      </p>
      <strong className="mt-2 text-gray-600 text-sm">{_error}</strong>
      <p className="mt-2 text-gray-600 text-sm">
        Please try again using a different payment method.
      </p>
    </Dialog>
  )
}

export default CheckoutErrorModal
