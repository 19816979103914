import React from 'react'

import usePrivateApi from '../../hooks/usePrivateApi'
import Button from '../Button'
import Dialog from '../Dialog'

const PaymentMethodDeleteModal = (props) => {
  const { admin, dealerId, isOpen, setIsOpen, method } = props

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    if (admin) {
      sendRequest({
        url: `/admin/dealers/${dealerId}/payment-methods/${method.id}`,
        method: 'delete',
      })
    } else {
      sendRequest({
        url: `/billing/payment-methods/${method.id}`,
        method: 'delete',
      })
    }
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not delete payment method. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Deleted payment method"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          <strong>
            {method.card.brand.toUpperCase()} ending {method.card.last4}
          </strong>{' '}
          {admin
            ? "has been removed from the dealer's account."
            : 'has been removed from your account.'}
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Delete payment method"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Deleting...' : 'Delete method',
        color: 'red',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        Are you sure you wish to delete this payment method?
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        {admin ? (
          <>
            The dealer won't be able to make any payments using this card. The
            dealer may add this card to their account again if they wish.
          </>
        ) : (
          <>
            You will no longer be able to make any payments using this card. You
            may however add the card to your account again if required.
          </>
        )}
      </p>
    </Dialog>
  )
}

export default PaymentMethodDeleteModal
