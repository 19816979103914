import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'
import { faToolbox } from '@fortawesome/pro-regular-svg-icons'

import history from '../../../history'
import AuthContext from '../../../context/AuthContext'
import usePrivateApi from '../../../hooks/usePrivateApi'
import Tool from './Tool'
import NewToolModal from './ModalNewTool'
import Button from '../../../components/Button'
import BrandingContext from '../../../context/BrandingContext'

const ToolList = () => {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(null)
  const [tools, setTools] = useState(null)
  const [newToolModalOpen, setNewToolModalOpen] = useState(false)
  const [auth, setAuth] = useContext(AuthContext)
  const [branding] = useContext(BrandingContext)
  const { primaryColorPalette } = branding

  const { toolsConfirmationNeeded } = auth.membership.dealer

  const [{ res, success, error, isLoading }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({
      url: '/billing/payment-methods/primary',
    })
  }, [sendRequest])

  useEffect(() => {
    if (success && hasPaymentMethod === null) {
      setHasPaymentMethod(!!res.data.paymentMethod)
      sendRequest({ url: '/settings/tools' })
      resetSuccess()
    }
  }, [success, resetSuccess, res, hasPaymentMethod, sendRequest])

  useEffect(() => {
    if (success && hasPaymentMethod !== null) {
      setTools(res.data.tools)
      resetSuccess()
    }
  }, [hasPaymentMethod, res, success, resetSuccess])

  useEffect(() => {
    if (success && tools !== null) {
      setAuth({
        ...auth,
        membership: {
          ...auth.membership,
          dealer: {
            ...auth.membership.dealer,
            toolsConfirmationNeeded: false,
          },
        },
      })

      history.push('/dashboard')
    }
  }, [auth, setAuth, success, tools])

  const handleToolConfirmation = () => {
    sendRequest({
      url: '/settings/tools/confirm',
      method: 'post',
    })
  }

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 flex-col p-18">
        <FontAwesomeIcon
          icon={faWifiSlash}
          className="text-gray-600"
          size="2x"
        />
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Could not retrieve tools
        </h3>
        <p className="text-sm text-gray-600 mt-1 mb-6">
          Please refresh the page to try again.
        </p>
        <Button color="white" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </div>
    )
  }

  if (tools === null) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <div className="px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">Tools</h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            Each remapping tool you own should be listed here, along with the
            serial number and next expiry date.
          </p>
          <p>
            You can enable auto-renew to ensure your tool subscriptions are
            always active. We remind you 7 days before any payment is taken and
            you can cancel anytime.
          </p>
        </div>
      </div>
      <div className="flex-1">
        {tools.length === 0 && !toolsConfirmationNeeded && (
          <div className="bg-gray-50 rounded-md px-8 py-6">
            <FontAwesomeIcon
              icon={faToolbox}
              className={`text-${primaryColorPalette}-600`}
              size="2x"
            />
            <h5 className="font-medium mt-2 mb-1">Manage your tools</h5>
            <p className="text-gray-600 text-sm mb-4">
              Add your first tool to get started
            </p>
            <Button color="primary" onClick={() => setNewToolModalOpen(true)}>
              Add first tool
            </Button>
          </div>
        )}
        {tools.length === 0 && toolsConfirmationNeeded && (
          <div className="bg-gray-50 rounded-md px-8 py-6">
            <FontAwesomeIcon
              icon={faToolbox}
              className={`text-${primaryColorPalette}-600`}
              size="2x"
            />
            <h5 className="font-medium mt-2 mb-1">Add tool details</h5>
            <p className="text-gray-600 text-sm mb-4">
              Add your first tool to gain access to our portal
            </p>
            <Button color="primary" onClick={() => setNewToolModalOpen(true)}>
              Add first tool
            </Button>
          </div>
        )}
        {tools.length > 0 && (
          <>
            <div className="space-y-3 max-w-md">
              {tools.map((tool) => (
                <Tool
                  tool={tool}
                  key={tool.id}
                  hasPaymentMethod={hasPaymentMethod}
                />
              ))}
            </div>
            <Button
              color="primary"
              className="mt-4"
              onClick={() => setNewToolModalOpen(true)}
              disabled={isLoading}
            >
              Add tool
            </Button>
            {toolsConfirmationNeeded && tools.length > 0 && (
              <Button
                className="mt-4 ml-3"
                onClick={handleToolConfirmation}
                disabled={(tools && tools.length < 1) || isLoading}
              >
                {isLoading ? 'Confirming...' : 'Confirm tools'}
              </Button>
            )}
          </>
        )}
      </div>
      <NewToolModal
        isOpen={newToolModalOpen}
        setIsOpen={() => setNewToolModalOpen(false)}
      />
    </div>
  )
}

export default ToolList
