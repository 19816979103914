import React from 'react'
import { format } from 'date-fns'

import { getStatus, getDiscountLabelColor } from '../../../utils/discounts'
import Label from '../../../components/Label'

const DiscountListItem = ({ discount }) => {
  return (
    <div className="border-b border-gray-100 relative flex flex-1 space-x-3 py-4 pr-3 pl-7 hover:bg-gray-50">
      <div className="min-w-[260px] flex-3">
        <p className="text-gray-900 text-sm mb-1 font-medium">
          {discount.code}
        </p>
        <p className="text-gray-600 text-xs">{discount.description}</p>
      </div>
      <div className="min-w-[260px] flex-3">
        <p className="text-gray-900 mb-1.5 text-sm">
          Expires {format(new Date(discount.expiresAt), 'dd MMMM yyy')}
          <Label
            color={getDiscountLabelColor(discount.startsAt, discount.expiresAt)}
            className="ml-2"
          >
            {getStatus(discount.startsAt, discount.expiresAt)}
          </Label>
        </p>
        <p className="text-gray-600 text-xs">
          Starts {format(new Date(discount.startsAt), 'dd MMMM yyy')}
        </p>
      </div>
      <div className="min-w-[260px] flex-2">
        <p className="tex-gray-900 mb-1.5 text-sm mr-4">
          {discount.percent}% discount
        </p>
        <p className="text-gray-600 text-xs">Used {discount.useCount} times</p>
      </div>
    </div>
  )
}

export default DiscountListItem
