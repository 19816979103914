import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import matchSorter from 'match-sorter'
import Linkify from 'linkifyjs/react'

import usePrivateApi from '../../hooks/usePrivateApi'
import Title from '../../components/Title'
import Accordion from '../../components/Accordion'
import Button from '../../components/Button'
import TextButton from '../../components/TextButton'
import SearchInput from '../../components/SearchInput'

const Faqs = () => {
  const [faqs, setFaqs] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [searchResults, setSearchResults] = useState(null)

  const [{ res, success, error }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({ url: '/support/faqs' })
  }, [sendRequest])

  useEffect(() => {
    if (success) {
      setFaqs(res.data.faqs)
      return resetSuccess()
    }
  }, [res, resetSuccess, success])

  const handleSearchChange = useCallback(
    (search) => {
      if (!search) {
        return setSearchResults(null)
      }

      setSearchResults(
        matchSorter(faqs, search, { keys: ['question', 'answer'] })
      )
    },
    [faqs]
  )

  const renderFaqs = useCallback(
    (faqs) =>
      faqs.map((faq, index) => (
        <Accordion
          key={index}
          title={faq.question}
          content={
            <Linkify className="linkify" tagName="span">
              {faq.answer}
            </Linkify>
          }
        />
      )),
    []
  )

  return (
    <>
      <Title
        title="Frequently asked questions"
        description="Please review the below frequently asked questions before creating a support ticket."
      />
      <div className="max-w-5xl mx-auto px-4">
        {error && (
          <div className="flex items-center justify-center flex-1 flex-col p-18">
            <h3 className="text-xl font-semibold tracking-tighter mt-4">
              An error occurred
            </h3>
            <p className="text-sm text-gray-600 mt-1 mb-6">
              Could not retrieve frequently asked questions. Please raise a
              support ticket.
            </p>
            <Button as={Link} to="/support/new" color="white">
              New ticket
            </Button>
          </div>
        )}
        {!faqs && !error && (
          <div className="flex items-center justify-center p-12 flex-1">
            <span className="spinner" />
          </div>
        )}
        {faqs && !error && (
          <div className="flex flex-col-reverse sm:space-y-0 sm:flex-row sm:space-x-6">
            <div className="mt-6 flex-1 sm:mt-0">
              <h4 className="text-lg font-semibold tracking-tight mb-4">
                {!searchResults ? 'All FAQs' : 'Search results'}
              </h4>
              <div className="space-y-2">
                {renderFaqs(searchResults || faqs)}
              </div>
              <div className="text-gray-900 mt-4 text-sm px-4">
                <p className="mb-2">
                  If you cannot find the answer to your question above you can
                  create a support ticket.{' '}
                </p>
                <TextButton size="sm" onClick={() => setConfirm(true)}>
                  I have checked the FAQs but still need help.
                </TextButton>
                {confirm && (
                  <div className="mt-4">
                    <Button
                      as={Link}
                      color="primary"
                      to={{
                        pathname: '/support/new',
                        state: { confirmed: true },
                      }}
                    >
                      Create a ticket
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-1">
              <div className="bg-gray-100 border border-gray-200 rounded-md px-4 py-4">
                <h4 className="text-base font-semibold tracking-tight mb-1">
                  Search FAQs
                </h4>
                <p className="text-sm text-gray-800 mb-4">
                  Use the search input below to filter FAQs and find an answer
                  to your question faster.
                </p>
                <SearchInput
                  placeholder="Search questions and answers"
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Faqs
