import React, { useEffect, useContext } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import { ON_HOLD, MASTER } from '../utils/dealers'
import { DEALER_CREDIT_CHANGED } from '../utils/sockets'
import AuthContext from '../context/AuthContext'
import usePrivateSocket from '../hooks/usePrivateSocket'
import MainTemplate from '../templates/MainTemplate'
import Dashboard from './dashboard'
import Uploads from './uploads'
import Credits from './credits'
import VehicleData from '../components/VehicleData'
import Support from './support'
import Settings from './settings'
import ConfirmPhoneModal from './modals/ConfirmPhone'

const FrontEnd = ({ location }) => {
  const [auth, setAuth] = useContext(AuthContext)
  const { dealer } = auth.membership

  const [, { socket }] = usePrivateSocket('/dealer', dealer.id)

  const isMaster = dealer.type === MASTER

  useEffect(() => {
    if (socket) {
      socket.on(DEALER_CREDIT_CHANGED, (dealerCredit) => {
        setAuth({
          ...auth,
          membership: {
            ...auth.membership,
            dealer: {
              ...dealer,
              credit: {
                ...dealer.credit,
                ...dealerCredit,
              },
            },
          },
        })
      })

      return () => socket.off(DEALER_CREDIT_CHANGED)
    }
  }, [auth, setAuth, socket, dealer])

  if (dealer.toolsConfirmationNeeded) {
    return (
      <MainTemplate location={location}>
        <Switch>
          <Route path="/settings" component={Settings} />
          <Redirect to="/settings" />
        </Switch>
      </MainTemplate>
    )
  }

  return (
    <MainTemplate location={location}>
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/uploads" component={Uploads} />
        {dealer.status !== ON_HOLD && isMaster && (
          <Route path="/credits" component={Credits} />
        )}
        <Route path="/vehicle-data" component={VehicleData} />
        {isMaster && <Route path="/support" component={Support} />}
        <Route path="/settings" component={Settings} />
        <Redirect to="/dashboard" />
      </Switch>
      <ConfirmPhoneModal isOpen={!dealer.phone} />
    </MainTemplate>
  )
}

export default FrontEnd
