import React, { useState } from 'react'

import { PLACE_UPLOAD_ON_HOLD } from '../../../utils/sockets'
import { ON_HOLD } from '../../../utils/uploads'
import Button from '../../Button'
import Dialog from '../../Dialog'

const ViewUploadOnHoldModal = (props) => {
  const { isOpen, setIsOpen, socket, setUpload } = props

  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const handleSubmit = () => {
    setIsLoading(true)

    socket.emit(PLACE_UPLOAD_ON_HOLD, (err, success) => {
      setIsLoading(false)

      if (success) {
        setSuccess(true)
        setUpload({ status: ON_HOLD })
      }

      if (err) {
        setError(err)
      }
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not place upload on hold. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => setIsOpen(false)}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        title="Successfully placed on hold"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          This upload has been placed on hold.
        </p>
        <Button className="mt-4" onClick={() => setIsOpen(false)}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Place on hold"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Sending...' : 'Place on hold',
        color: 'red',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        <strong>Are you sure you wish to place this upload on hold?</strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        This upload will enter an "On hold" status. This will remove the upload
        from the file turnaround time calculation.
      </p>
    </Dialog>
  )
}

export default ViewUploadOnHoldModal
