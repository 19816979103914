import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import './tailwind.css'
import App from './App'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://338b0062f65345b388114d73d2d1a192@o684990.ingest.sentry.io/5772005',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
