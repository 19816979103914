import React, { useState } from 'react'

import ViewDealerCreditChangeBalanceModal from './ModalChangeBalance.jsx'
import Button from '../../../../components/Button'

const ViewDealerCreditBalance = ({ dealer, setDealer, socket }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleSuccess = (dealerCredit) => {
    setDealer({ credit: { ...dealer.credit, ...dealerCredit } })
  }

  return (
    <div className="px-6 pt-6 pb-6 space-y-3 sm:space-y-0 sm:flex sm:space-x-6 sm:items-center sm:justify-between">
      <h2 className="text-lg font-semibold tracking-tight mb-0.5">
        Current balance is {dealer.credit.balance}{' '}
        {dealer.credit.balance === 1 ? 'credit' : 'credits'}
      </h2>
      <Button onClick={() => setModalOpen(true)}>Adjust balance</Button>
      <ViewDealerCreditChangeBalanceModal
        isOpen={modalOpen}
        setIsOpen={() => setModalOpen(false)}
        balance={dealer.credit.balance}
        socket={socket}
        onSuccess={handleSuccess}
      />
    </div>
  )
}

export default ViewDealerCreditBalance
