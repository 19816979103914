import React, { useState } from 'react'

import Dialog from '../../Dialog'
import { CLOSE_TICKET } from '../../../utils/sockets'
import { CLOSED } from '../../../utils/support'

const ViewTicketCloseModal = (props) => {
  const { isOpen, setIsOpen, socket, setTicket } = props
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)
    socket.emit(CLOSE_TICKET, (err, success) => {
      if (success) {
        setIsLoading(false)
        setTicket({ status: CLOSED })
        setIsOpen(false)
      }
    })
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Close ticket"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Closing...' : 'Close ticket',
        color: 'red',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        Are you sure you want to close this ticket?
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        You will still be able to view the messages and files on this ticket,
        however closing the ticket will prevent any further messages being sent
        (including from Topgear support).
      </p>
    </Dialog>
  )
}

export default ViewTicketCloseModal
