import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import { format, isFuture } from 'date-fns'

import ToolEditModal from './ModalEditTool'
import ToolDeleteModal from './ModalDeleteTool'
import EnableAutoRenewModal from './ModalEnableAutoRenew'
import DisableAutoRenewModal from './ModalDisableAutoRenew'
import ConfirmPaymentModal from './ModalConfirmPayment'
import Switch from '../../../components/Switch'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import BrandingContext from '../../../context/BrandingContext'

const ToolItem = ({ tool, hasPaymentMethod }) => {
  const [autoRenewOn, setAutoRenewOn] = useState(tool.autoRenewOn)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [paymentConfirmationModalOpen, setPaymentConfirmationModalOpen] =
    useState(false)
  const [enableAutoRenewModalOpen, setEnableAutoRenewModalOpen] =
    useState(false)
  const [disableAutoRenewModalOpen, setDisableAutoRenewModalOpen] =
    useState(false)

  const [branding] = useContext(BrandingContext)
  const { primaryColorPalette, isTgt } = branding

  const nextExpiry = tool.nextExpiry ? new Date(tool.nextExpiry) : null

  const renderStatus = () => {
    if (tool.status === 'ACTIVE') {
      return <span className="text-teal-600">ACTIVE</span>
    }

    if (tool.status === 'PAYMENT_ATTEMPTED') {
      return <span className="text-yellow-600">PAYMENT ATTEMPTED</span>
    }

    if (tool.status === 'PAYMENT_ACTION_NEEDED') {
      return <span className="text-red-600">ACTION NEEDED</span>
    }

    if (tool.status === 'PAYMENT_FAILED') {
      return <span className="text-red-600">PAYMENT FAILED</span>
    }

    if (tool.status === 'EXPIRED') {
      return <span className="text-red-600">EXPIRED</span>
    }

    return ''
  }

  return (
    <div className="flex-1 rounded-md shadow-sm border border-gray-200 px-4 py-3 flex items-center justify-between">
      <div>
        <p className="font-medium tracking-tight text-base">
          {tool.name} ({tool.serialNumber})
        </p>
        <p className="text-gray-600 text-xs">
          {nextExpiry ? (
            <>
              {isFuture(nextExpiry) ? 'Expires' : 'Expired on'}{' '}
              {format(new Date(tool.nextExpiry), 'dd MMM yyyy')}
            </>
          ) : (
            'Never expires'
          )}
        </p>
        {tool.key !== 'AUTOTUNER' && isTgt && (
          <>
            <div className="mt-2">
              <label
                className="flex items-center space-x-2"
                htmlFor={`autoRenew-${tool.id}`}
              >
                <Switch
                  checked={autoRenewOn}
                  small
                  onChange={(checked) => {
                    setAutoRenewOn(checked)
                    checked
                      ? setEnableAutoRenewModalOpen(true)
                      : setDisableAutoRenewModalOpen(true)
                  }}
                  id={`autoRenew-${tool.id}`}
                />
                <span className="text-sm text-gray-600">
                  Auto-renew {autoRenewOn ? 'on' : 'off'}
                </span>
              </label>
            </div>
            {tool.paymentToConfirm && (
              <Button
                className="mt-4"
                size="xs"
                onClick={() => setPaymentConfirmationModalOpen(true)}
              >
                Confirm payment
              </Button>
            )}
          </>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-xs uppercase font-medium">{renderStatus()}</span>
        <div>
          <Dropdown
            items={[
              [
                {
                  onClick: () => setEditModalOpen(true),
                  label: 'Edit tool details',
                  icon: faPencilAlt,
                },
                {
                  onClick: () => setDeleteModalOpen(true),
                  label: 'Remove tool',
                  icon: faTrashAlt,
                },
              ],
            ]}
            renderButton={({ MenuButton }) => (
              <MenuButton
                className={`rounded-full block p-0 ml-4 h-8 w-8 focus:outline-none focus-visible:ring-2 focus-visible:ring-${primaryColorPalette}-600 hover:bg-gray-100`}
              >
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  size="1x"
                  className="text-gray-600"
                />
              </MenuButton>
            )}
          />
        </div>
      </div>
      {isTgt && (
        <>
          {tool.paymentToConfirm && (
            <ConfirmPaymentModal
              isOpen={paymentConfirmationModalOpen}
              setIsOpen={() => setPaymentConfirmationModalOpen(false)}
              tool={tool}
            />
          )}
          <EnableAutoRenewModal
            hasPaymentMethod={hasPaymentMethod}
            isOpen={enableAutoRenewModalOpen}
            setIsOpen={() => setEnableAutoRenewModalOpen(false)}
            tool={tool}
          />
          <DisableAutoRenewModal
            isOpen={disableAutoRenewModalOpen}
            setIsOpen={() => setDisableAutoRenewModalOpen(false)}
            tool={tool}
          />
        </>
      )}
      <ToolEditModal
        isOpen={editModalOpen}
        setIsOpen={() => setEditModalOpen(false)}
        tool={tool}
      />
      <ToolDeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={() => setDeleteModalOpen(false)}
        tool={tool}
      />
    </div>
  )
}

export default ToolItem
