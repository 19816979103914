import React, { useState } from 'react'

import Title from '../../components/Title'
import InputGroup from '../../components/InputGroup'
import UploadListReport from './UploadList'
import SupportListReport from './SupportList'
import DealerListReport from './DealerList'
import UserListReport from './UserList'
import VRMListReport from './VRMList'
import VehicleListReport from './VehicleList'
import {
  UPLOAD,
  SUPPORT,
  DEALER,
  USER,
  VRM,
  VEHICLE,
  getReportOptions,
} from '../../utils/reports'

const Reports = () => {
  const [report, setReport] = useState(null)

  return (
    <>
      <Title title="Reports" />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg shadow border border-gray-200 px-6 py-6">
          <InputGroup
            className="max-w-sm"
            label="Choose report"
            type="select"
            id="report"
            onChange={(e) => setReport(e.target.value || null)}
            renderOptions={() => (
              <>
                <option value="">Select report</option>
                {getReportOptions()}
              </>
            )}
            value={report || ''}
          />
          <div className="border-t border-gray-100 mt-3 pt-4">
            {report === null && (
              <p className="text-gray-600 text-sm italic">
                Additional fields will be shown once you have selected a report.
              </p>
            )}
          </div>
          {report === UPLOAD && <UploadListReport />}
          {report === SUPPORT && <SupportListReport />}
          {report === DEALER && <DealerListReport />}
          {report === USER && <UserListReport />}
          {report === VRM && <VRMListReport />}
          {report === VEHICLE && <VehicleListReport />}
        </div>
      </div>
    </>
  )
}

export default Reports
