import React from 'react'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts'

const getChartData = (vehicle) => {
  const { power, torque } = vehicle

  const power0 = 0
  const power1000 = 0.155
  const power2000 = 0.546
  const power3000 = 0.876
  const power4000 = 1.0
  const power5000 = 0.938

  const torque0 = 0.33
  const torque1000 = 0.558
  const torque2000 = 0.974
  const torque3000 = 1.0
  const torque4000 = 0.832
  const torque5000 = 0.65

  return [
    {
      rpm: 0,
      originalPower: power0 * power.original,
      tunedPower: power0 * power.stageOne,
      originalTorque: torque0 * torque.original,
      tunedTorque: torque0 * torque.stageOne,
    },
    {
      rpm: 1000,
      originalPower: power1000 * power.original,
      tunedPower: power1000 * power.stageOne,
      originalTorque: torque1000 * torque.original,
      tunedTorque: torque1000 * torque.stageOne,
    },
    {
      rpm: 2000,
      originalPower: power2000 * power.original,
      tunedPower: power2000 * power.stageOne,
      originalTorque: torque2000 * torque.original,
      tunedTorque: torque2000 * torque.stageOne,
    },
    {
      rpm: 3000,
      originalPower: power3000 * power.original,
      tunedPower: power3000 * power.stageOne,
      originalTorque: torque3000 * torque.original,
      tunedTorque: torque3000 * torque.stageOne,
    },
    {
      rpm: 4000,
      originalPower: power4000 * power.original,
      tunedPower: power4000 * power.stageOne,
      originalTorque: torque4000 * torque.original,
      tunedTorque: torque4000 * torque.stageOne,
    },
    {
      rpm: 5000,
      originalPower: power5000 * power.original,
      tunedPower: power5000 * power.stageOne,
      originalTorque: torque5000 * torque.original,
      tunedTorque: torque5000 * torque.stageOne,
    },
  ]
}

const VehicleDataChart = ({ vehicle, vpWidth }) => {
  if (vpWidth < 450) {
    return null
  }

  return (
    <ResponsiveContainer
      aspect={vpWidth < 768 ? 2 : undefined}
      height={vpWidth < 768 ? undefined : 450}
      width={vpWidth < 768 ? undefined : '99%'}
    >
      <LineChart
        data={getChartData(vehicle)}
        margin={{ left: 0, right: 0, top: 2, bottom: 0 }}
      >
        <CartesianGrid stroke="#f3f4f6" />
        <XAxis
          dataKey="rpm"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12 }}
          label={{
            value: 'RPM',
            offset: 0,
            position: 'insideBottom',
            style: {
              textAnchor: 'middle',
              fill: '#6b7280',
              fontSize: 12,
              opacity: 0.7,
            },
          }}
        />
        <YAxis
          yAxisId="left"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12 }}
          tickCount={8}
          label={{
            value: 'Power (HP)',
            angle: -90,
            position: 'insideLeft',
            offset: 15,
            style: {
              textAnchor: 'middle',
              fill: '#6b7280',
              fontSize: 12,
              opacity: 0.7,
            },
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12 }}
          tickCount={8}
          label={{
            value: 'Torque (Nm)',
            angle: 90,
            position: 'insideRight',
            offset: 15,
            style: {
              textAnchor: 'middle',
              fill: '#6b7280',
              fontSize: 12,
              opacity: 0.7,
            },
          }}
        ></YAxis>
        <Line
          yAxisId="left"
          type="basis"
          dot={false}
          strokeWidth={2}
          dataKey="originalPower"
          stroke="rgba(213, 63, 140, .5)"
        />
        <Line
          yAxisId="left"
          type="basis"
          dot={false}
          strokeWidth={2}
          dataKey="tunedPower"
          stroke="rgba(213, 63, 140, 1)"
        />
        <Line
          yAxisId="right"
          type="basis"
          dot={false}
          strokeWidth={2}
          dataKey="originalTorque"
          stroke="rgba(49, 151, 149, .5)"
        />
        <Line
          yAxisId="right"
          type="basis"
          dot={false}
          strokeWidth={2}
          dataKey="tunedTorque"
          stroke="rgba(49, 151, 149, 1)"
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default VehicleDataChart
