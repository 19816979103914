import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import Dialog from '../../Dialog'
import Button from '../../Button'

const NewUploadWarrantyModal = ({ isOpen, setIsOpen, onContinue }) => {
  return (
    <Dialog isOpen={isOpen} title="No mechanical warranty" buttonProps={null}>
      <div className="mt-4 sm:flex sm:items-start sm:space-x-3">
        <div className="bg-orange-100 rounded-md w-10 h-10 flex flex-shrink-0 items-center justify-center">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-orange-600"
            size="lg"
          />
        </div>
        <div className="mt-2 sm:mt-0">
          <h3 className="font-semibold tracking-tight mb-1">
            You haven't selected any mechanical warranty
          </h3>
          <p className="text-sm text-gray-600">
            Mechanical warranty is available on this vehicle, but you haven't
            selected a warranty add-on. We recommend you offer mechanical
            warranty to your customers when available.
          </p>
          <div className="mt-4 space-y-3 sm:space-y-0 sm:flex sm:space-x-4 sm:items-center">
            <Button
              className="w-full justify-center sm:w-auto sm:justify-start"
              color="primary"
              onClick={onContinue}
            >
              Just continue
            </Button>
            <Button
              className="text-center w-full sm:w-auto sm:text-left"
              color="white"
              onClick={setIsOpen}
            >
              Choose warranty
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default NewUploadWarrantyModal
