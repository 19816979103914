import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import CreditPackages from './packages'
import Checkout from './checkout'

const Credits = () => {
  return (
    <Switch>
      <Route path="/credits/packages" component={CreditPackages} />
      <Route path="/credits/checkout" component={Checkout} />
      <Redirect to="/credits/packages" />
    </Switch>
  )
}

export default Credits
