import React from 'react'
import cn from 'classnames'

const Title = (props) => {
  const {
    title,
    children,
    description,
    renderBreadcrumbs,
    renderBtns,
    className,
  } = props

  const titleCn = cn('bg-white border-b border-gray-100 shadow mb-8 py-4', {
    [className]: className,
  })

  return (
    <div className={titleCn}>
      <div className="max-w-5xl mx-auto px-4 space-y-4 justify-start md:space-y-0 md:space-x-6 md:flex md:items-center md:justify-between">
        <div>
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">
            {title}
          </h1>
          {description && (
            <p className="my-1 text-gray-600 text-sm">{description}</p>
          )}
          {renderBreadcrumbs && (
            <div className="mt-1">{renderBreadcrumbs()}</div>
          )}
          {children}
        </div>
        {renderBtns && (
          <div className="mt-3 sm:mt-0 flex justify-start">{renderBtns()}</div>
        )}
      </div>
    </div>
  )
}

export default Title
