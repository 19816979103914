import React from 'react'
import cn from 'classnames'

const Badge = (props) => {
  const {
    size = 'sm',
    color = 'gray',
    border = 'normal',
    dot,
    onRemove,
    className,
    children,
    ...rest
  } = props

  const baseCn = 'inline-flex items-center font-medium whitespace-nowrap'

  const sizeCn = {
    sm: 'px-2.5 py-0.5 text-xs leading-4',
    lg: 'px-3 py-0.5 text-sm leading-5',
  }

  const colorCn = {
    gray: 'bg-gray-100 text-gray-800',
    red: 'bg-red-100 text-red-800',
    orange: 'bg-orange-100 text-orange-800',
    yellow: 'bg-yellow-100 text-yellow-800',
    green: 'bg-green-200 text-green-800',
    teal: 'bg-teal-100 text-teal-800',
    blue: 'bg-blue-100 text-blue-800',
    indigo: 'bg-indigo-100 text-indigo-800',
    purple: 'bg-purple-100 text-purple-800',
    pink: 'bg-pink-200 text-pink-800',
  }

  const borderCn = {
    normal: 'rounded-full',
    rounded: 'rounded',
  }

  const dotSizeCn = {
    sm: '-ml-0.5 mr-1.5 h-2 w-2',
    lg: '-ml-1 mr-1.5 h-2 w-2',
  }

  const dotColorCn = {
    gray: 'text-gray-400',
    red: 'text-red-400',
    orange: 'text-orange-400',
    yellow: 'text-yellow-400',
    green: 'text-green-400',
    teal: 'text-teal-400',
    blue: 'text-blue-400',
    indigo: 'text-indigo-400',
    purple: 'text-purple-400',
    pink: 'text-pink-400',
  }

  const buttonBaseCn = 'flex-shrink-0 inline-flex focus:outline-none'

  const buttonSizeCn = {
    sm: 'ml-1.5',
    lg: '-mr-0.5 ml-1.5',
  }

  const buttonColorCn = {
    gray: 'text-gray-500 focus:text-gray-700',
    red: 'text-red-500 focus:text-red-700',
    orange: 'text-orange-500 focus:text-orange-700',
    yellow: 'text-yellow-500 focus:text-yellow-700',
    green: 'text-green-500 focus:text-green-700',
    teal: 'text-teal-500 focus:text-teal-700',
    blue: 'text-blue-500 focus:text-blue-700',
    indigo: 'text-indigo-500 focus:text-indigo-700',
    purple: 'text-purple-500 focus:text-purple-700',
    pink: 'text-pink-500 focus:text-pink-700',
  }

  const badgeCn = cn(
    baseCn,
    sizeCn[size],
    colorCn[color],
    borderCn[border],
    className
  )

  const dotCn = cn(dotSizeCn[size], dotColorCn[color])
  const buttonCn = cn(buttonBaseCn, buttonSizeCn[size], buttonColorCn[color])

  return (
    <span className={badgeCn} {...rest}>
      {dot && (
        <svg className={dotCn} fill="currentColor" viewBox="0 0 8 8">
          <circle cx="4" cy="4" r="3" />
        </svg>
      )}
      {children}
      {onRemove && (
        <button className={buttonCn} onClick={onRemove} type="button">
          <svg
            className="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      )}
    </span>
  )
}

export default Badge
