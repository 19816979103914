export const downloadBuffer = ({ buffer, filename, type }) => {
  const blob = new Blob([buffer], { type })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  a.parentNode.removeChild(a)
}

export const downloadFromUrl = ({ url, fileName }) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.parentNode.removeChild(a)
}
