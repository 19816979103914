import React, { useState, useEffect } from 'react'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { useDebouncedCallback } from 'use-debounce'

import InputGroup from './InputGroup'

const SearchInput = (props) => {
  const { placeholder, onChange, value: parentValue } = props
  const [value, setValue] = useState('')

  const [updateFilters] = useDebouncedCallback(
    (search) => onChange(search),
    400
  )

  useEffect(() => {
    updateFilters(value)
  }, [updateFilters, value])

  useEffect(() => {
    if (parentValue) {
      setValue(parentValue)
    }
  }, [parentValue])

  return (
    <InputGroup
      className="flex-1"
      type="search"
      label="Search"
      labelHidden
      placeholder={placeholder ? placeholder : 'Search...'}
      onChange={(e) => setValue(e.target.value)}
      icon={faSearch}
      value={value}
    />
  )
}

export default SearchInput
