import React, { useState } from 'react'
import isEmail from 'validator/lib/isEmail'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'
import InputGroup from '../../../components/InputGroup'

const UsersInviteModal = ({ isOpen, setIsOpen }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: '/settings/users/invite',
      method: 'post',
      data: { email, firstName, lastName },
    })
  }

  const renderError = () => {
    if (error) {
      if (error.response) {
        if (error.response.status === 409) {
          return (
            <p className="text-gray-600 text-sm mt-2">
              An account already exists with that email address.
            </p>
          )
        }

        return (
          <p className="text-gray-600 text-sm mt-2">
            An unknown error occurred [{error.response.status}]
          </p>
        )
      } else {
        return (
          <p className="text-gray-600 text-sm mt-2">
            Could not invite user. Please try again later.
          </p>
        )
      }
    }
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        {renderError()}
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="User invite sent"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          We have sent an email invite to{' '}
          <strong>
            {firstName} {lastName}'s
          </strong>{' '}
          email address ({email}).
        </p>
        <p className="text-gray-600 text-sm mt-2">
          Please ask {firstName} to check their email inbox, click the link we
          sent them and set a password for their account. This invitation will
          expire in 24 hours, in which case you will need to resend the
          invitation.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Invite user"
      buttonProps={{
        disabled: isLoading || !firstName || !lastName || !isEmail(email),
        onClick: handleSubmit,
        children: isLoading ? 'Sending...' : 'Send invite',
        color: 'primary',
      }}
    >
      <div className="space-y-4 mt-2">
        <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
          <InputGroup
            required
            className="flex-1"
            label="First name"
            id="firstName"
            disabled={isLoading}
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <InputGroup
            required
            className="flex-1"
            label="Last name"
            id="lastName"
            disabled={isLoading}
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </div>
        <InputGroup
          required
          label="Email"
          id="email"
          disabled={isLoading}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          help="We will send an invite to this email address"
        />
      </div>
    </Dialog>
  )
}

export default UsersInviteModal
