import React from 'react'

import InputGroup from '../../InputGroup'
import Textarea from '../../Textarea'
import { getCategoryOptions } from '../../../utils/support'

const NewTicketBasicForm = (props) => {
  const {
    category,
    setCategory,
    subject,
    setSubject,
    message,
    setMessage,
    isLoading,
  } = props

  return (
    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-6 pb-8 mt-8">
      <div className="flex-[2]">
        <h3 className="text-xl font-semibold tracking-tight">Support needed</h3>
        <p className="text-gray-700 text-sm mt-1">
          Please provide us with as much information as you can about your
          issue.
        </p>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm px-6 py-5 flex-[3] space-y-4">
        <div className="space-y-4 md:space-y-0 md:flex md:space-x-4">
          <InputGroup
            className="flex-1"
            type="select"
            label="I need support for"
            id="category"
            onChange={(e) => setCategory(e.target.value || null)}
            disabled={isLoading}
            required
            value={category || ''}
            renderOptions={() => (
              <>
                <option value="">Select category</option>
                {getCategoryOptions()}
              </>
            )}
          />
          <InputGroup
            className="flex-1"
            label="Subject"
            id="subject"
            required
            placeholder="Enter subject..."
            disabled={isLoading}
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
          />
        </div>
        <Textarea
          label="Message"
          id="message"
          placeholder="Enter message..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          disabled={isLoading}
          minRows={6}
          required
        />
      </div>
    </div>
  )
}

export default NewTicketBasicForm
