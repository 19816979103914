import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import _ from 'lodash'

import history from '../history'
import usePublicApi from '../hooks/usePublicApi'
import useLocalStorage from '../hooks/useLocalStorage'
import AuthContext from '../context/AuthContext'
import InputGroup from '../components/InputGroup'
import Button from '../components/Button'
import TextButton from '../components/TextButton'
import AuthTemplate from '../templates/AuthTemplate'
import BrandingContext from '../context/BrandingContext'

const Login = ({ location }) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [, setAuthToken] = useLocalStorage('authToken')
  const [generalError, setGeneralError] = useState(null)
  const [, setAuth] = useContext(AuthContext)
  const [branding] = useContext(BrandingContext)

  const [hasPasswordResetExpired, setHasPasswordResetExpired] = useState(
    _.get(location, 'state.passwordResetExpired', false)
  )

  const [hasInvitedUserExpired, setHasInvitedUserExpired] = useState(
    _.get(location, 'state.invitedUserExpired', false)
  )

  const [isPasswordReset, setIsPasswordReset] = useState(
    _.get(location, 'state.passwordReset', false)
  )

  const [isInvitedUser, setIsInvitedUser] = useState(
    _.get(location, 'state.invitedUser', false)
  )

  const [isIncorrectSetupLink, setIsIncorrectSetupLink] = useState(
    _.get(location, 'state.incorrectSetupLink', false)
  )

  const [
    { res, isLoading, success, error },
    { sendRequest, resetError, resetSuccess },
  ] = usePublicApi()

  useEffect(() => {
    if (error) {
      if (error.response) {
        if (error.response.data.email) {
          setEmailError('No account exists with that email.')
        } else if (error.response.data.password) {
          setPasswordError("We're sorry, that password is incorrect.")
        } else if (error.response.data.redirect) {
          window.location.replace(error.response.data.redirect)
        } else {
          setIsPasswordReset(false)
          setHasPasswordResetExpired(false)
          setIsInvitedUser(false)
          setHasInvitedUserExpired(false)
          setIsIncorrectSetupLink(false)
          setGeneralError(`Error code [${error.response.status}].`)
        }
      } else if (error.request) {
        setIsPasswordReset(false)
        setHasPasswordResetExpired(false)
        setIsInvitedUser(false)
        setHasInvitedUserExpired(false)
        setIsIncorrectSetupLink(false)
        setGeneralError('Please check your internet connection.')
      } else {
        setIsPasswordReset(false)
        setHasPasswordResetExpired(false)
        setIsInvitedUser(false)
        setHasInvitedUserExpired(false)
        setIsIncorrectSetupLink(false)
        setGeneralError('Error code [900].')
      }

      resetError()
    }
  }, [error, resetError])

  useEffect(() => {
    if (success) {
      resetSuccess()

      if (res.data.token) {
        setAuthToken(res.data.token)

        setAuth({
          isLoggedIn: true,
          membership: {
            ...res.data.membership,
          },
        })
      } else {
        history.push({
          pathname: '/signup-pending',
          state: { membership: res.data.membership },
        })
      }
    }
  }, [res, resetSuccess, success, setAuth, setAuthToken])

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    setEmailError(null)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    setPasswordError(null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!isEmail(email)) {
      setEmailError('Please enter a valid email address.')
    } else if (password.length < 8) {
      setPasswordError("We're sorry, that password is incorrect.")
    } else {
      sendRequest({
        method: 'post',
        data: { email, password },
        url: '/auth/login',
      })
    }
  }

  const getSuccessAlert = () => {
    if (isPasswordReset) {
      return 'Your new password has been saved.'
    }

    if (isInvitedUser) {
      return 'You have accepted your invitation.'
    }
  }

  const getErrorAlert = () => {
    if (hasPasswordResetExpired) {
      return 'Please request a new password reset link.'
    }

    if (hasInvitedUserExpired) {
      return 'Please request a new account invite.'
    }

    if (isIncorrectSetupLink) {
      return 'Please request a new setup link'
    }

    if (generalError) {
      return generalError
    }
  }

  return (
    <AuthTemplate
      title="Login to your account"
      alertSuccess={getSuccessAlert()}
      alertError={getErrorAlert()}
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-3">
          <InputGroup
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect="none"
            autoFocus
            label="Email address"
            error={emailError}
            disabled={isLoading}
            id="email"
            onChange={handleEmailChange}
            className="flex-1"
            value={email}
          />
          <InputGroup
            autoCapitalize="none"
            autoComplete="current-password"
            autoCorrect="none"
            error={passwordError}
            disabled={isLoading}
            id="password"
            onChange={handlePasswordChange}
            type="password"
            value={password}
            className="flex-1"
            label="Password"
            renderLabelAction={() => (
              <TextButton
                as={Link}
                tabIndex="-1"
                to="/reset-password"
                size="xs"
              >
                Forgot password?
              </TextButton>
            )}
          />
        </div>
        <Button
          block
          color="primary"
          className="mt-6 mb-3"
          disabled={isLoading || emailError || passwordError}
          type="submit"
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </Button>
        {branding.isTgt && (
          <Button as={Link} to="/signup" block>
            Create an account
          </Button>
        )}
      </form>
    </AuthTemplate>
  )
}

export default Login
