import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faQuestionCircle,
  faWifiSlash,
} from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'react-router-dom'

import AuthContext from '../../../context/AuthContext'
import Button from '../../Button'
import Title from '../../Title'
import Alert from '../../Alert'
import BoxNav from '../../BoxNav'
import SearchInput from '../../SearchInput'
import TicketListItem from './ListItem'
import TablePagination from '../../TablePagination'
import usePrivateSocket from '../../../hooks/usePrivateSocket'
import usePagination from '../../../hooks/usePagination'
import useObjState from '../../../hooks/useObjState'
import { CLOSED } from '../../../utils/support'
import { ON_HOLD } from '../../../utils/dealers'
import {
  FETCH_TICKET_LIST,
  NEW_TICKET,
  TICKET_OPENED,
  TICKET_CLOSED,
  NEW_MESSAGE,
  NEW_FILES,
} from '../../../utils/sockets'

const TicketList = ({ location, isAdmin }) => {
  const [auth] = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState(null)
  const [tickets, setTickets] = useState(null)
  const [showErr, setShowErr] = useState(false)
  const [hasSkippedFirst, setHasSkippedFirst] = useState(false)
  const [hasRestoredState, setHasRestoredState] = useState(false)

  const [filters, setFilters] = useObjState({
    search: null,
    status: null,
  })

  const [
    { page, pageSize, ...paginationValues },
    { goToFirst, goToPage, ...paginationActions },
  ] = usePagination(count, {
    page: location?.state?.page || 1,
    pageSizeKey: 'support.list.pageSize',
  })

  const [{ inRoom, err }, { socket, resetErr }] = usePrivateSocket(
    '/support-list',
    true
  )

  const prepareTicket = useCallback(
    (ticket) => {
      if (ticket.status === CLOSED) {
        return { ...ticket, isUnreplied: false }
      }

      const hasMessages = ticket.messages.length > 0
      const fromUser = (message) => message.createdBy.accountType === 'USER'

      const isUnreplied = isAdmin
        ? hasMessages && fromUser(ticket.messages[ticket.messages.length - 1])
        : hasMessages && !fromUser(ticket.messages[ticket.messages.length - 1])

      return { ...ticket, isUnreplied }
    },
    [isAdmin]
  )

  const handleTicketChange = useCallback(
    ({ ticket }) => {
      const ticketIndex = tickets.findIndex((x) => x.id === ticket.id)

      if (ticketIndex > -1) {
        // The ticket is currently in view
        const newTickets = [...tickets]

        if (page === 1) {
          // Move to the top of the list
          newTickets.splice(ticketIndex, 1)
          newTickets.unshift(prepareTicket(ticket))
          return setTickets(newTickets)
        }

        // Keep in place so we don't distract the user
        newTickets[ticketIndex] = prepareTicket(ticket)
        return setTickets(newTickets)
      }
      // The ticket is not in view

      if (page === 1) {
        const newTickets = [prepareTicket(ticket), ...tickets]

        if (newTickets.length > 10) {
          newTickets.pop()
        }

        return setTickets(newTickets)
      }

      // The ticket is not in view and we are not on page 1
      // Don't change anything so we don't distract the user
      return
    },
    [prepareTicket, tickets, page]
  )

  const handleStatusChange = useCallback(
    (status) => {
      if (status !== filters.status) {
        setFilters({ status })

        if (page !== 1) {
          goToPage(1)
        }
      }
    },
    [setFilters, filters.status, goToPage, page]
  )

  const handleSearchChange = useCallback(
    (search) => {
      if (search !== filters.search) {
        setFilters({ search })

        if (page !== 1) {
          goToPage(1)
        }
      }
    },
    [setFilters, filters.search, goToPage, page]
  )

  useEffect(() => {
    if (hasSkippedFirst) {
      if (!hasRestoredState && location.state?.page) {
        goToPage(location.state.page)
        handleStatusChange(location.state.filters.status)
        handleSearchChange(location.state.filters.search)
      }
      setHasRestoredState(true)
    } else {
      setHasSkippedFirst(true)
    }
  }, [
    location.state,
    goToPage,
    hasRestoredState,
    handleSearchChange,
    handleStatusChange,
    hasSkippedFirst,
  ])

  useEffect(() => {
    if (
      inRoom &&
      hasRestoredState &&
      filters.status !== null &&
      filters.search !== null
    ) {
      setTickets(null)
      setIsLoading(true)

      socket.emit(
        FETCH_TICKET_LIST,
        { page, pageSize, filters },
        (err, { tickets, count }) => {
          setIsLoading(false)

          if (tickets) {
            setTickets(tickets.map((ticket) => prepareTicket(ticket)))
            setCount(count)
          } else {
            setShowErr(true)
          }
        }
      )
    }
  }, [
    inRoom,
    hasRestoredState,
    page,
    pageSize,
    socket,
    filters,
    setTickets,
    prepareTicket,
  ])

  useEffect(() => {
    if (socket) {
      socket.on(TICKET_OPENED, handleTicketChange)
      return () => socket.off(TICKET_OPENED)
    }
  }, [socket, handleTicketChange])

  useEffect(() => {
    if (socket) {
      socket.on(TICKET_CLOSED, handleTicketChange)
      return () => socket.off(TICKET_CLOSED)
    }
  }, [socket, handleTicketChange])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_TICKET, handleTicketChange)
      return () => socket.off(NEW_TICKET)
    }
  }, [socket, handleTicketChange])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_MESSAGE, handleTicketChange)
      return () => socket.off(NEW_MESSAGE)
    }
  }, [socket, handleTicketChange])

  useEffect(() => {
    if (socket) {
      socket.on(NEW_FILES, handleTicketChange)
      return () => socket.off(NEW_FILES)
    }
  }, [socket, handleTicketChange])

  useEffect(() => {
    if (err) {
      setShowErr(true)
      resetErr()
    }
  }, [err, resetErr])

  const boxNavLinks = useMemo(
    () => [
      { to: '/support/list/all', label: 'All', key: 'ALL' },
      { to: '/support/list/open', label: 'Open', key: 'OPEN' },
      { to: '/support/list/closed', label: 'Closed', key: 'CLOSED' },
    ],
    []
  )

  const renderTicketList = () => {
    if (showErr) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faWifiSlash}
            className="text-gray-600"
            size="2x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not retrieve support tickets
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Please refresh the page to try again.
          </p>
          <Button color="white" onClick={() => window.location.reload()}>
            Refresh page
          </Button>
        </div>
      )
    }

    if (isLoading || tickets === null) {
      return (
        <div className="flex items-center justify-center p-12 flex-1">
          <span className="spinner" />
        </div>
      )
    }

    if (tickets.length < 1 && !filters.search && !isAdmin) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="text-gray-600"
            size="3x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Create your first support ticket
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Click the new ticket button to begin.
          </p>
          <Button as={Link} color="primary" to="/support/faqs">
            New ticket
          </Button>
        </div>
      )
    }

    if (tickets.length < 1) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-600"
            size="3x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not find any support tickets
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Try changing your filters or search term.
          </p>
        </div>
      )
    }

    return (
      <>
        <div className="flex flex-col flex-1 flex-wrap justify-start overflow-x-auto border-b">
          {tickets.map((ticket) => (
            <TicketListItem
              page={page}
              pathname={location.pathname}
              filters={filters}
              ticket={ticket}
              isAdmin={isAdmin}
              key={ticket.id}
            />
          ))}
        </div>
        <div className="py-1">
          <TablePagination
            {...paginationValues}
            {...paginationActions}
            count={count}
            page={page}
            pageSize={pageSize}
            goToPage={goToPage}
            goToFirst={goToFirst}
            scrollOnChange
          />
        </div>
      </>
    )
  }

  const searchPlaceholder = isAdmin
    ? 'Search by subject, ticket number, dealer name or TGT number'
    : 'Search by subject or ticket number'

  // prettier-ignore
  const titleBtns = isAdmin || auth.membership.dealer.status === ON_HOLD
    ? undefined
    : () => (
      <Button as={Link} color="primary" to="/support/faqs">
        New ticket
      </Button>
    )

  return (
    <>
      <Title title="Support tickets" renderBtns={titleBtns} />
      {!isAdmin && (
        <div className="max-w-5xl mx-auto px-4 mb-5">
          <Alert title="Creating a support ticket?" info>
            Please only submit a support ticket for a new enquiry. If you have
            an issue with an upload please use the messaging system on the
            upload it refers to.
          </Alert>
        </div>
      )}
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
            <BoxNav
              current={location.pathname}
              onChange={handleStatusChange}
              links={boxNavLinks}
            />
          </div>
          <div className="border-b border-gray-200 flex py-3 px-4">
            <SearchInput
              placeholder={searchPlaceholder}
              onChange={handleSearchChange}
              value={filters.search}
            />
          </div>
          {renderTicketList()}
        </div>
      </div>
    </>
  )
}

export default TicketList
