import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../../Button'

const NewTicketFormActions = (props) => {
  const { handleOnCreate, isUploading, category, subject, message } = props

  return (
    <div className="flex justify-end pt-6 space-x-4">
      {!isUploading && (
        <Button as={Link} color="white" to="/support/list">
          Cancel
        </Button>
      )}
      <Button
        color="primary"
        onClick={handleOnCreate}
        disabled={isUploading || !category || !subject || !message}
      >
        {isUploading ? 'Creating...' : 'Create ticket'}
      </Button>
    </div>
  )
}

export default NewTicketFormActions
