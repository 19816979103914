import React, { useContext } from 'react'
import cn from 'classnames'
import { RadioGroup } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BrandingContext from '../context/BrandingContext'

const RadioBox = (props) => {
  const {
    checked,
    onChange,
    disabled,
    label,
    description,
    checkedLabel,
    uncheckedLabel,
    checkedIcon,
    uncheckedIcon,
  } = props

  const [branding] = useContext(BrandingContext)
  const { primaryColorPalette } = branding

  return (
    <RadioGroup
      value={checked}
      onChange={onChange}
      disabled={disabled}
      className={cn(
        'bg-white py-4 px-6 shadow-sm border border-gray-300 rounded-md focus:outline-none',
        {}
      )}
    >
      <div>
        <RadioGroup.Label className="font-medium text-sm tracking-tight text-gray-900">
          {label}
        </RadioGroup.Label>
        <RadioGroup.Description className="mt-1 text-gray-600 text-sm">
          {description}
        </RadioGroup.Description>
      </div>
      <div className="flex items-center space-x-4 mt-4">
        <RadioGroup.Option
          key="true"
          value={true}
          className={({ checked, active }) =>
            cn(
              'border py-2 px-4 flex items-center cursor-pointer rounded-md focus:outline-none',
              {
                [`bg-${primaryColorPalette}-50 border-transparent z-10 ring-2 ring-${primaryColorPalette}-500`]:
                  checked,
                'bg-white border-gray-300 hover:bg-gray-50': !checked,
                [`ring-2 ring-offset-2 ring-${primaryColorPalette}-500`]:
                  active,
              }
            )
          }
        >
          {({ checked }) => (
            <>
              {checkedIcon && (
                <FontAwesomeIcon
                  icon={checkedIcon}
                  className={cn('w-6 h-6 text-lg mr-1.5', {
                    [`text-${primaryColorPalette}-600`]: checked,
                    'text-gray-500': !checked,
                  })}
                />
              )}
              <RadioGroup.Label className="font-medium text-sm tracking-tight text-gray-900 cursor-pointer">
                {checkedLabel}
              </RadioGroup.Label>
            </>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option
          key="false"
          value={false}
          className={({ checked, active }) =>
            cn(
              'border py-2 px-4 flex items-center cursor-pointer rounded-md focus:outline-none',
              {
                [`bg-${primaryColorPalette}-50 border-transparent z-10 ring-2 ring-${primaryColorPalette}-500`]:
                  checked,
                'bg-white border-gray-300 hover:bg-gray-50': !checked,
                [`ring-2 ring-offset-2 ring-${primaryColorPalette}-500`]:
                  active,
              }
            )
          }
        >
          {({ checked }) => (
            <>
              {uncheckedIcon && (
                <FontAwesomeIcon
                  icon={uncheckedIcon}
                  className={cn('w-6 h-6 text-lg mr-1.5', {
                    [`text-${primaryColorPalette}-600`]: checked,
                    'text-gray-500': !checked,
                  })}
                />
              )}
              <RadioGroup.Label className="font-medium text-sm tracking-tight text-gray-900 cursor-pointer">
                {uncheckedLabel}
              </RadioGroup.Label>
            </>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  )
}

export default RadioBox
