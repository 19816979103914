import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const AdminDisableModal = (props) => {
  const { isOpen, setIsOpen, membership } = props

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/admin/admins/${membership.id}`,
      method: 'patch',
      data: { status: 'DISABLED' },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm">
          Could not disable admin account. Please try again later.
        </p>
        <Button
          className="mt-4"
          color="white"
          onClick={() => window.location.reload()}
        >
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Account disabled"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Successfully disabled{' '}
          <strong>
            {membership.user.firstName} {membership.user.lastName}'s
          </strong>{' '}
          account ({membership.user.email}).
        </p>
        <Button
          className="mt-4"
          color="white"
          onClick={() => window.location.reload()}
        >
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Disable admin account"
      buttonProps={{
        onClick: handleSubmit,
        children: isLoading ? 'Disabling..' : 'Disable account',
        type: 'button',
        color: 'red',
        disabled: isLoading,
      }}
    >
      <p className="text-gray-600 text-sm mt-2">
        Are you sure you wish to disable{' '}
        <strong>
          {membership.user.firstName} {membership.user.lastName}'s
        </strong>{' '}
        account?
      </p>
      <p className="text-gray-600 text-sm mt-2">
        This admin won't be able to login or carry out any actions in the system
        once their account is disabled. You can re-activate their account in the
        future if required.
      </p>
    </Dialog>
  )
}

export default AdminDisableModal
