import { useEffect, useState, useCallback } from 'react'

import useObjState from './useObjState'
import usePrivateApi from './usePrivateApi'
import { objectCompare } from '../utils/objects'

// config:

// initialValues - Object - required
// The initial values of the form (required)

// mapResData - Fn created using useCallback - required
// Function that maps response data from server to our initialValues. Needed
// to track local changes against what we have saved to the server.

// axiosConfig - Object
// axios config for fetching initial form data from server
// useful for when we don't already have the initialValues in memory or
// we want to get an up-to-date snapshot before editing.
// Omit this if we don't want to fetch data from server before editing.

const useEditableForm = config => {
  const { initialValues, mapResData, axiosConfig } = config

  const [formValues, setFormValues] = useObjState(initialValues)
  const [fetchConfig] = useState(axiosConfig)
  const [cache, setCache] = useState(initialValues)
  const [hasEdits, setHasEdits] = useState(false)
  const [hasFetched, setHasFetched] = useState(axiosConfig ? false : true)
  const [hasSaved, setHasSaved] = useState(false)
  const [saveError, setSaveError] = useState(null)
  const [connectionError, setConnectionError] = useState(null)
  const [isFetchLoading, setIsFetchLoading] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)

  const [
    { success, res, error },
    { sendRequest, resetSuccess, resetError }
  ] = usePrivateApi()

  const saveFormValues = useCallback(
    axiosConfig => {
      setSaveError(null)

      if (hasEdits) {
        setIsSaveLoading(true)
        sendRequest(axiosConfig)
      }
    },
    [hasEdits, sendRequest]
  )

  const resetFormValues = useCallback(() => setFormValues({ ...cache }), [
    cache,
    setFormValues
  ])

  const resetSaveError = useCallback(() => setSaveError(null), [])
  const resetConnectionError = useCallback(() => setConnectionError(null), [])

  // OPTIONAL: Sends request to fetch initial data from server
  // Omit axiosConfig when calling hook to skip
  useEffect(() => {
    if (fetchConfig) {
      if (!fetchConfig.url) {
        throw new Error('We require a GET url.')
      }

      if (!hasFetched) {
        setIsFetchLoading(true)
        sendRequest(fetchConfig)
      }
    }
  }, [fetchConfig, hasFetched, sendRequest])

  // Checks for differences between current form values and either:
  // a) initialValues OR b) data retrieved from server fetch.
  useEffect(() => {
    if (typeof formValues !== 'object') {
      throw new Error('Form values must be an object')
    }

    const hasDiff = !objectCompare(formValues, cache)
    setHasEdits(hasDiff)

    if (hasDiff && hasSaved) {
      setHasSaved(false)
    }
  }, [formValues, cache, hasSaved])

  // Handles fetching initialData from server
  useEffect(() => {
    if (success && fetchConfig && !hasFetched) {
      resetSuccess()
      setHasFetched(true)
      setIsFetchLoading(false)
      setCache(mapResData(res.data))
      setFormValues(mapResData(res.data))
    }
  }, [
    hasFetched,
    res,
    resetSuccess,
    success,
    setFormValues,
    mapResData,
    fetchConfig
  ])

  // Handles result of saving edited form values
  useEffect(() => {
    if (success && hasFetched) {
      resetSuccess()
      setHasSaved(true)
      setIsSaveLoading(false)
      setHasEdits(false)
      setCache(mapResData(res.data))
      setFormValues(mapResData(res.data))
    }
  }, [hasFetched, res, resetSuccess, success, setFormValues, mapResData])

  // handles errors
  useEffect(() => {
    if (error) {
      if (!hasFetched) {
        setIsFetchLoading(false)
        setHasFetched(false)
        setConnectionError(error)
      } else {
        setIsSaveLoading(false)
        setHasSaved(false)
        setSaveError(error)
      }
      resetError()
    }
  }, [error, resetError, hasFetched])

  return [
    {
      formValues,
      hasEdits,
      hasSaved,
      hasFetched,
      showNoChanges: !hasSaved && !hasEdits,
      showChangesMade: !hasSaved && hasEdits && !isSaveLoading,
      showChangesSaving: !hasSaved && hasEdits && isSaveLoading,
      showChangesSaved: hasSaved && !hasEdits,
      saveError,
      connectionError,
      isFetchLoading,
      isSaveLoading
    },
    {
      setFormValues,
      resetFormValues,
      saveFormValues,
      resetSaveError,
      resetConnectionError,
      setHasEdits // ONLY USE IF ABSOLUTELY NECESSARY
    }
  ]
}

export default useEditableForm
