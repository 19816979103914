import React, { useEffect } from 'react'
import _ from 'lodash'

import history from '../../../history'
import Title from '../../Title'

const NewUploadError = ({ location }) => {
  const errCode = _.get(location, 'state.errCode', null)

  useEffect(() => {
    if (!errCode) {
      history.push('/dashboard')
    }
  }, [errCode])

  return (
    <>
      <Title title="Upload error" />
      <div className="max-w-5xl mx-auto px-4 py-1">
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Your upload could not be created
        </h3>
        <p className="text-sm text-gray-600 mt-2 mb-6">
          Please contact our support team quoting error code {errCode}. We're
          sorry for any inconvenience.
        </p>
      </div>
    </>
  )
}

export default NewUploadError
