import React, { useMemo } from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import cn from 'classnames'

import ViewDealerBusinessInfo from './business'
import ViewDealerBilling from './billing'
import ViewDealerTools from './tools'
import ViewDealerUsers from './users'
import ViewDealerAccountStatus from './status'

const ViewDealerAccount = ({ match, location, dealer, setDealer, socket }) => {
  const links = useMemo(
    () => [
      { to: `${match.url}/business`, label: 'Dealer info' },
      { to: `${match.url}/billing`, label: 'Billing' },
      { to: `${match.url}/tools`, label: 'Tools' },
      { to: `${match.url}/users`, label: 'Users' },
      { to: `${match.url}/status`, label: 'Account status' },
    ],
    [match.url]
  )

  return (
    <div>
      <div className="border-b border-gray-200 pt-3 pb-3 overflow-x-auto">
        <nav className="flex items-center min-h-[40px] pl-4">
          {links.map((link) => (
            <Link
              className={cn(
                'rounded-md text-sm font-medium py-2 px-3 mr-3 no-underline whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500',
                {
                  'bg-blue-100 text-blue-900': location.pathname.startsWith(
                    link.to
                  ),
                  'text-gray-600 hover:bg-gray-50 hover:text-gray-800':
                    !location.pathname.startsWith(link.to),
                }
              )}
              key={link.to}
              to={link.to}
            >
              {link.label}
            </Link>
          ))}
        </nav>
      </div>
      <div>
        <Switch>
          <Route
            path={`${match.url}/business`}
            render={() => <ViewDealerBusinessInfo dealerId={dealer.id} />}
          />
          <Route
            path={`${match.url}/billing`}
            render={() => <ViewDealerBilling dealerId={dealer.id} />}
          />
          <Route
            path={`${match.url}/tools`}
            render={() => (
              <ViewDealerTools dealerId={dealer.id} dealer={dealer} />
            )}
          />
          <Route
            path={`${match.url}/users`}
            render={() => <ViewDealerUsers dealerId={dealer.id} />}
          />
          <Route
            path={`${match.url}/status`}
            render={() => (
              <ViewDealerAccountStatus
                socket={socket}
                dealer={dealer}
                setDealer={setDealer}
              />
            )}
          />
          <Redirect
            to={{ pathname: `${match.url}/business`, state: location.state }}
          />
        </Switch>
      </div>
    </div>
  )
}

export default ViewDealerAccount
