import { useCallback } from 'react'

const useSessionStorage = (key) => {
  const getValue = useCallback(() => {
    try {
      const item = window.sessionStorage.getItem(key)
      return item ? item : null
    } catch (err) {
      throw err
    }
  }, [key])

  const setValue = useCallback(
    (value) => {
      try {
        if (value) {
          window.sessionStorage.setItem(key, value)
        } else {
          window.sessionStorage.removeItem(key)
        }
      } catch (err) {
        throw err
      }
    },
    [key]
  )

  return [getValue, setValue]
}

export default useSessionStorage
