import React, { useState } from 'react'
import { getDay, getHours, getMinutes } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import Alert from '../../Alert'

const isNearEndOfWorkingHours = () => {
  // Warn users if we are within 15 mins of uploads closing

  // Time zone we need to check
  const timeZone = 'Europe/London'
  // Time per user's system
  const now = new Date()
  // Convert user's time to UK time
  const ukTime = utcToZonedTime(now, timeZone)

  const dayOfWeek = getDay(ukTime)
  const hours = getHours(ukTime)
  const minutes = getMinutes(ukTime)

  // All the following relates to the current upload working times
  const isMondayToThursday = dayOfWeek >= 1 && dayOfWeek <= 4
  const isFriday = dayOfWeek === 5
  const isSaturday = dayOfWeek === 6

  // Mon-Thurs 8am to 8:30pm
  if (isMondayToThursday && hours === 20 && minutes >= 15 && minutes < 30) {
    return true
  }

  // Friday 8am to 6pm
  if (isFriday && hours === 17 && minutes >= 45) {
    return true
  }

  // Saturday 8:30am to 4pm
  if (isSaturday && hours === 15 && minutes >= 45) {
    return true
  }

  // Sunday closed
  // It is not within 15 mins of closing or it's Sunday
  return false
}

const NewUploadFormTimeWarning = () => {
  const [showWarning] = useState(isNearEndOfWorkingHours())

  if (!showWarning) return null

  return (
    <Alert error title="You may have to wait longer for this upload">
      We are near the end of our file times for today. It is the file team's
      discretion to decide if they can work on the file or if the file gets left
      for the next shift. It is also important to note that if there are any
      issues we may not be able to offer support until the next working day.
    </Alert>
  )
}

export default NewUploadFormTimeWarning
