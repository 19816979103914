import { useState, useEffect, useCallback } from 'react'
import useLocalStorage from './useLocalStorage'

const usePagination = (count, config = {}) => {
  const [getStoredPageSize, setStoredPageSize] = useLocalStorage(
    config.pageSizeKey
  )

  const storedPageSize = getStoredPageSize()
  const [page, setPage] = useState(config.page || 1)
  const [pageOptions, setPageOptions] = useState(null)
  const [maxPage, setMaxPage] = useState(null)
  const [pageSize, setPageSize] = useState(
    (storedPageSize && parseInt(storedPageSize, 10)) || 25
  )

  useEffect(() => {
    if (count) {
      setMaxPage(Math.ceil(count / pageSize))

      // Calculate the number of pages numbers we should show.
      // We want to show a maximum number of 5 page numbers, with the
      // current page being in the middle if possible.
      let options = []

      if (maxPage <= 5) {
        // We can choose any page up to the maxPage as it's 5 or less.
        for (let i = 1; i <= maxPage; i++) {
          options.push(i)
        }
      } else if (page <= 3) {
        // We have more than 5 pages, but we are currently on the first 3
        // and we still need to see pages 1 and 2
        options = [1, 2, 3, 4, 5]
      } else {
        let start = page - 2
        let end = page + 2

        if (maxPage - page === 0) {
          start = page - 4
          end = maxPage
        } else if (maxPage - page === 1) {
          start = page - 3
          end = maxPage
        }

        for (let i = start; i <= end; i++) {
          options.push(i)
        }
      }

      setPageOptions(options)
    }
  }, [page, pageSize, count, maxPage])

  useEffect(() => {
    setPage(config.page)
  }, [config.page])

  const goToPage = useCallback((int) => setPage(int), [])
  const goToFirst = useCallback(() => setPage(1), [])
  const goToNext = useCallback(() => setPage((page) => page + 1), [])
  const goToPrev = useCallback(() => setPage((page) => page - 1), [])
  const goToLast = useCallback(() => setPage(maxPage), [maxPage])
  const setAndStorePageSize = useCallback(
    (newPageSize) => {
      if (pageSize !== newPageSize) {
        setPageSize(newPageSize)
        setStoredPageSize(newPageSize)

        if (page !== 1) {
          const newMaxPage = Math.ceil(count / newPageSize)

          if (page > newMaxPage) {
            setPage(newMaxPage)
          }

          const currentItemsShown = page * pageSize
          setPage(Math.ceil(currentItemsShown / newPageSize))
        }
      }
    },
    [count, page, pageSize, setStoredPageSize]
  )

  return [
    { page, pageSize, maxPage, pageOptions },
    {
      goToPage,
      goToFirst,
      goToNext,
      goToPrev,
      goToLast,
      setPageSize: setAndStorePageSize,
    },
  ]
}

export default usePagination
