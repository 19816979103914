import React, { useState, useEffect, useContext } from 'react'

import usePrivateApi from '../hooks/usePrivateApi'
import AuthContext from '../context/AuthContext'
import Dialog from './Dialog'

const TermsModal = ({ isOpen, onAccept, setIsOpen }) => {
  const [html, setHtml] = useState(null)
  const [auth, setAuth] = useContext(AuthContext)

  const [{ res, success, isLoading }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({ url: '/settings/terms' })
  }, [sendRequest])

  useEffect(() => {
    if (success && !html) {
      setHtml(res.data.current.body)
      resetSuccess()
    }
  }, [res.data, success, html, resetSuccess])

  useEffect(() => {
    if (success && html) {
      onAccept()
      setAuth({
        membership: {
          ...auth.membership,
          dealer: {
            ...auth.membership.dealer,
            terms: {
              requiresAcceptance: false,
            },
          },
        },
      })
      resetSuccess()
    }
  }, [html, success, onAccept, auth, setAuth, resetSuccess])

  const handleAccept = () => {
    sendRequest({
      method: 'patch',
      data: { accept: true },
      url: '/settings/terms',
    })
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Review Terms and Conditions"
      buttonProps={{
        color: 'primary',
        children: isLoading && html ? 'Accepting...' : 'I accept',
        disabled: isLoading,
        onClick: handleAccept,
      }}
    >
      <div>
        {!html ? (
          <div className="flex items-center justify-center p-12 flex-1">
            <span className="spinner" />
          </div>
        ) : (
          <div
            className="mt-4 max-h-[500px] overflow-y-auto space-y-3 bg-gray-100 rounded-md border border-gray-200 p-3 text-xs text-gray-800"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        )}
      </div>
    </Dialog>
  )
}

export default TermsModal
