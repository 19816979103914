import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import enGB from 'date-fns/locale/en-GB'
import { format, getYear } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from '@fortawesome/pro-solid-svg-icons'

registerLocale('en-GB', enGB)

const acceptedFormats = [
  'dd MMM yyyy', // 01 Jan 2019 (1st format is used for display)
  'dd MMMM yyyy', // 01 January 2019
  'dd/MM/yyyy', // 01/01/2019
  'dd/M/yyyy', // 01/1/2019
  'dd MMMMM yy', // 01 January 19
  'dd MMM yy', // 01 Jan 19
  'dd/MM/yy', // 01/01/19
  'dd/M/yy', // 01/1/19
  'd MMMM yyyy', // 1 January 2019
  'd MMM yyyy', // 1 Jan 2019
  'd/MM/yyyy', // 1/01/2019
  'd/M/yyyy', // 1/1/2019
  'd MMMM yy', // 1 January 19
  'd MMM yy', // 1 Jan 19
  'd/MM/yy', // 1/01/19
  'd/M/yy', // 1/1/19
]

const DatePicker = (props) => {
  const {
    error,
    className,
    onDateChange,
    value,
    inModal,
    absoluteDate = true,
    ...rest
  } = props
  const [date, setDate] = useState(value || null)
  const [isOpen, setIsOpen] = useState(false)
  const [showMonthPicker, setShowMonthPicker] = useState(false)
  const [showYearPicker, setShowYearPicker] = useState(false)

  let ref = useRef()

  // Syncs with parent state if value changes outside of the input.
  useEffect(() => {
    if (value !== date && !isOpen) {
      setDate(value)
    }
  }, [date, value, isOpen])

  const handleShowDayPicker = () => {
    setShowMonthPicker(false)
    setShowYearPicker(false)
  }

  const handleShowMonthPicker = () => {
    setShowMonthPicker(true)
    setShowYearPicker(false)
  }

  const handleShowYearPicker = () => {
    setShowMonthPicker(false)
    setShowYearPicker(true)
  }

  // Removes timezone offset to send actual date to the server.
  // NOTE: This breaks accurate time reporting across different timezones.
  // Only use when we need an "absolute" date such as date of birth
  const convertLocalToUTC = (date) => {
    date = new Date(date)
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    )
    return date
  }

  const handleChange = (newDate, e) => {
    if (showYearPicker) {
      handleShowMonthPicker()
      setDate(newDate)
    } else if (showMonthPicker) {
      handleShowDayPicker()
      setDate(newDate)
    } else {
      setDate(newDate)

      if (absoluteDate && newDate) {
        newDate = convertLocalToUTC(newDate)
      }

      onDateChange(newDate)

      // Ignore manual typing into the input as the user may not be finished
      if (e.type !== 'change') {
        setIsOpen(false)
      }
    }
  }

  const renderHeader = (state) => {
    const { date, decreaseMonth, increaseMonth, decreaseYear, increaseYear } =
      state

    const buttonBaseCn = cn([
      'bg-white',
      'rounded',
      'h-8',
      'hover:bg-gray-100',
      'focus:outline-none',
      'flex',
      'items-center',
      'justify-center',
    ])

    const buttonIconCn = cn(
      'px-1 text-gray-600 hover:text-gray-800 flex-shrink-0'
    )

    const buttonTextCn = cn([
      'px-3',
      'font-medium',
      'text-sm',
      'leading-1',
      'text-gray-900',
      'hover:text-black',
    ])

    const buttonPrevCn = cn(buttonBaseCn, buttonIconCn)
    const buttonNextCn = cn(buttonBaseCn, buttonIconCn)

    const buttonMonthCn = cn(buttonBaseCn, buttonTextCn, 'flex-1')

    const buttonYearCn = cn(buttonBaseCn, buttonTextCn, {
      'flex-1': showMonthPicker,
    })

    const decadeCn = cn([
      'bg-white',
      'h-8',
      'px-3',
      'font-medium',
      'text-sm',
      'leading-1',
      'text-gray-900',
      'flex',
      'items-center',
      'justify-center',
    ])

    const handlePrevious = () => {
      showMonthPicker || showYearPicker ? decreaseYear() : decreaseMonth()
    }

    const handleNext = () => {
      showMonthPicker || showYearPicker ? increaseYear() : increaseMonth()
    }

    return (
      <div className="flex items-center justify-between px-2 pt-2 py-1">
        <button className={buttonPrevCn} onClick={handlePrevious} type="button">
          <FontAwesomeIcon icon={faChevronLeft} className="h-5 w-5" />
        </button>
        {!showMonthPicker && !showYearPicker && (
          <button
            className={buttonMonthCn}
            onClick={handleShowMonthPicker}
            type="button"
          >
            {format(date, 'MMMM')}
          </button>
        )}
        {!showYearPicker && (
          <button
            className={buttonYearCn}
            onClick={handleShowYearPicker}
            type="button"
          >
            {format(date, 'yyyy')}
          </button>
        )}
        {showYearPicker && (
          <span className={decadeCn}>
            {getYear(date) - 11} - {getYear(date)}
          </span>
        )}
        <button className={buttonNextCn} onClick={handleNext} type="button">
          <FontAwesomeIcon icon={faChevronRight} className="h-5 w-5" />
        </button>
      </div>
    )
  }

  const baseInputCn = [
    'block',
    'bg-white',
    'w-full',
    'sm:text-sm',
    'sm:leading-5',
    'form-input',
  ]

  const errorInputCn = [
    'pr-10',
    'border-red-300',
    'text-red-900',
    'placeholder-red-300',
    'focus:border-red-300',
    'focus:shadow-outline-red',
  ]

  const inputCn = cn(baseInputCn, className, {
    [errorInputCn.join(' ')]: error,
  })

  const calendarButtonCn = cn(
    [
      'absolute',
      'right-0',
      'inset-y-0',
      'pr-3',
      'flex',
      'items-center',
      'appearance-none',
      'focus:outline-none',
    ],
    {
      'text-gray-400 hover:text-gray-500 focus:text-gray-500': !error,
      'text-red-400 hover:text-red-500 focus:text-red-500': error,
    }
  )

  return (
    <>
      <ReactDatePicker
        locale="en-GB"
        showPopperArrow={false}
        allowSameDay
        dateFormat={acceptedFormats}
        className={inputCn}
        showMonthYearPicker={showMonthPicker}
        showYearPicker={showYearPicker}
        renderCustomHeader={renderHeader}
        formatWeekDay={(day) => day[0]}
        shouldCloseOnSelect={!showMonthPicker && !showYearPicker}
        onChange={handleChange}
        onCalendarClose={handleShowDayPicker}
        onClickOutside={() => {
          // Formats input text on blur when typed manually
          ref.current.setOpen(false)
          setIsOpen(false)
        }}
        onFocus={() => setIsOpen(true)}
        onKeyDown={(e) => {
          // react-datepicker onBlur does not trigger when tabbing.
          // This ensures the calendar is closed when the input is
          // no longer focused.
          if (e.key === 'Tab') {
            // Formats input text on blur when typed manually
            ref.current.setOpen(false)
            setIsOpen(false)
          }
        }}
        selected={date}
        open={isOpen}
        ref={ref}
        value={date}
        popperContainer={({ children }) =>
          inModal
            ? ReactDOM.createPortal(children, document.querySelector('body'))
            : children || null
        }
        {...rest}
      />
      <button
        className={calendarButtonCn}
        onClick={() => setIsOpen(!isOpen)}
        tabIndex="-1"
        type="button"
      >
        <FontAwesomeIcon icon={faCalendarAlt} className="h-5 w-5" />
      </button>
    </>
  )
}

export default DatePicker
