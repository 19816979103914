import React from 'react'
import ReactAsyncSelect from 'react-select/async'

const AsyncSelect = (props) => {
  const { onLoadOptions, ...rest } = props

  return (
    <ReactAsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={onLoadOptions}
      {...rest}
    />
  )
}

export default AsyncSelect
