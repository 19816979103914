import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const UsersReactivateModal = ({ isOpen, setIsOpen, membership }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/users/${membership.id}`,
      method: 'patch',
      data: { status: 'ACTIVE' },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not re-activate user account. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Account re-activated"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Successfully re-activated{' '}
          <strong>
            {membership.user.firstName} {membership.user.lastName}'s
          </strong>{' '}
          account ({membership.user.email}).
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Re-activate user account"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Activating...' : 'Activate account',
        color: 'primary',
      }}
    >
      <p className="text-gray-600 text-sm mt-2">
        <strong>
          Are you sure you wish to re-activate {membership.user.firstName}{' '}
          {membership.user.lastName}'s account?
        </strong>
      </p>
      <p className="text-gray-600 text-sm mt-2">
        This user will regain full access to your dealer account.
      </p>
    </Dialog>
  )
}

export default UsersReactivateModal
