import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import MainTemplate from '../templates/MainTemplate'
import Uploads from './uploads'
import Support from './support'
import Settings from './settings'
import VehicleData from '../components/VehicleData'

const Writers = ({ location }) => {
  return (
    <MainTemplate writer location={location}>
      <Switch>
        <Route path="/uploads" component={Uploads} />
        <Route path="/support" component={Support} />
        <Route path="/vehicle-data" component={VehicleData} />
        <Route path="/settings" component={Settings} />
        <Redirect to="/uploads" />
      </Switch>
    </MainTemplate>
  )
}

export default Writers
