import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import ViewDealerBillingVat from './Vat'
import ViewDealerBillingPaymentMethods from './PaymentMethodList'
import ViewDealerBillingDocs from './DocList'
import Button from '../../../../../components/Button'

const ViewDealerBilling = ({ dealerId }) => {
  const [fetchedVat, setFetchedVat] = useState(false)
  const [fetchedMethods, setFetchedMethods] = useState(false)
  const [fetchError, setFetchError] = useState(false)

  const isLoading = !fetchedVat || !fetchedMethods
  const showContent = !isLoading && !fetchError

  return (
    <>
      {isLoading && !fetchError && (
        <div className="flex items-center justify-center p-12 flex-1">
          <span className="spinner" />
        </div>
      )}
      {fetchError && (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faWifiSlash}
            className="text-gray-600"
            size="2x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not retrieve account settings
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Please refresh the page to try again.
          </p>
          <Button color="white" onClick={() => window.location.reload()}>
            Refresh page
          </Button>
        </div>
      )}
      <ViewDealerBillingVat
        dealerId={dealerId}
        setFetchedVat={setFetchedVat}
        setFetchError={setFetchError}
        showContent={showContent}
      />
      <ViewDealerBillingPaymentMethods
        dealerId={dealerId}
        setFetchedMethods={setFetchedMethods}
        setFetchError={setFetchError}
        showContent={showContent}
      />
      <ViewDealerBillingDocs
        dealerId={dealerId}
        setFetchError={setFetchError}
        showContent={showContent}
      />
    </>
  )
}

export default ViewDealerBilling
