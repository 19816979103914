import React, { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import Button from '../../../components/Button'
import SearchInput from '../../../components/SearchInput'
import TablePagination from '../../../components/TablePagination'
import usePagination from '../../../hooks/usePagination'
import usePrivateApi from '../../../hooks/usePrivateApi'
import useObjState from '../../../hooks/useObjState'
import DiscountListItem from './ListItem'
import DiscountListStatusSelect from './ListStatusSelect'
import NewDiscountModal from './ModalNewDiscount'

const DiscountCodeList = ({ location }) => {
  const [newDiscountModalOpen, setNewDiscountModalOpen] = useState(false)
  const [count, setCount] = useState(null)
  const [discountCodes, setDiscountCodes] = useState(null)

  const [filters, setFilters] = useObjState({
    search: null,
    status: null,
  })

  const [{ success, res, err, isLoading }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  const [
    { page, pageSize, ...paginationValues },
    { goToFirst, goToPage, ...paginationActions },
  ] = usePagination(count, {
    page: location?.state?.page || 1,
    pageSizeKey: 'discount.list.pageSize',
  })

  useEffect(() => {
    if (filters.search !== null && filters.status !== null) {
      sendRequest({
        url: '/admin/credits/discounts',
        params: { page, filters },
      })
    }
  }, [sendRequest, page, filters])

  useEffect(() => {
    if (success) {
      setDiscountCodes(res.data.discounts)
      setCount(res.data.count)
      resetSuccess()
    }
  }, [res, resetSuccess, success])

  const handleStatusChange = useCallback(
    (status) => setFilters({ status }),
    [setFilters]
  )

  const handleSearchChange = useCallback(
    (search) => setFilters({ search }),
    [setFilters]
  )

  const handleNewDiscountSuccess = (newDiscountCode) => {
    setDiscountCodes([newDiscountCode, ...discountCodes])
  }

  const renderDiscountList = () => {
    if (err) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faWifiSlash}
            className="text-gray-600"
            size="2x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not retrieve discount codes
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Please refresh the page to try again.
          </p>
          <Button color="white" onClick={() => window.location.reload()}>
            Refresh page
          </Button>
        </div>
      )
    }

    if (isLoading || discountCodes === null) {
      return (
        <div className="flex items-center justify-center p-12 flex-1">
          <span className="spinner" />
        </div>
      )
    }

    if (discountCodes.length < 1) {
      return (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-gray-600"
            size="3x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not find any discount codes
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Try changing your filters or search term.
          </p>
        </div>
      )
    }

    return (
      <>
        <div className="flex flex-wrap relative overflow-x-auto">
          {discountCodes.map((discount) => (
            <DiscountListItem
              page={page}
              path={location.pathname}
              discount={discount}
              key={discount.id}
            />
          ))}
        </div>
        <div className="py-1">
          <TablePagination
            {...paginationValues}
            {...paginationActions}
            count={count}
            page={page}
            pageSize={pageSize}
            goToPage={goToPage}
            goToFirst={goToFirst}
            scrollOnChange
          />
        </div>
      </>
    )
  }

  return (
    <>
      <div className="border-b border-gray-200 flex py-3 px-4">
        <SearchInput
          placeholder="Search by code name or description"
          onChange={handleSearchChange}
        />
        <div className="flex space-x-2 ml-3">
          <DiscountListStatusSelect setFilters={handleStatusChange} />
          <Button color="primary" onClick={() => setNewDiscountModalOpen(true)}>
            New code
          </Button>
        </div>
      </div>
      {renderDiscountList()}
      <NewDiscountModal
        isOpen={newDiscountModalOpen}
        setIsOpen={setNewDiscountModalOpen}
        onSuccess={handleNewDiscountSuccess}
      />
    </>
  )
}

export default DiscountCodeList
