import React, { useState, useEffect } from 'react'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'
import InputGroup from '../../../components/InputGroup'

const NewDiscountModal = (props) => {
  const { isOpen, setIsOpen, onSuccess } = props

  const [discountCode, setDiscountCode] = useState('')
  const [discountDesc, setDiscountDesc] = useState('')
  const [discountPercent, setDiscountPercent] = useState('')
  const [discountStart, setDiscountStart] = useState(new Date())
  const [discountExpires, setDiscountExpires] = useState(new Date())
  const [codeError, setCodeError] = useState(null)
  const [generalError, setGeneralError] = useState(null)

  const [
    { res, success, error, isLoading },
    { sendRequest, resetSuccess, resetError },
  ] = usePrivateApi()

  useEffect(() => {
    if (isOpen) {
      setDiscountCode('')
      setDiscountDesc('')
      setDiscountPercent('')
      setDiscountStart(new Date())
      setDiscountExpires(new Date())
    }
  }, [isOpen])

  useEffect(() => {
    if (success) {
      onSuccess(res.data.discount)
      setIsOpen(false)
      resetSuccess()
    }
  }, [onSuccess, res, success, resetSuccess, setIsOpen])

  useEffect(() => {
    if (error) {
      if (error.response && error.response.status === 409) {
        setCodeError('Code already exists')
      } else {
        setGeneralError(true)
      }

      resetError()
    }
  }, [error, resetError])

  const handlePercentChange = (e) => {
    const match = e.target.value.match(/^[1-9][0-9]?/g)
    setDiscountPercent(match ? match.join('') : '')
  }

  const handleSubmit = () => {
    sendRequest({
      url: '/admin/credits/discounts',
      method: 'post',
      data: {
        code: discountCode,
        description: discountDesc,
        percent: parseInt(discountPercent),
        startsAt: startOfDay(discountStart),
        expiresAt: endOfDay(discountExpires),
      },
    })
  }

  if (generalError) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm">
          There was an error saving the discount code.
        </p>
        <Button className="mt-4" color="white" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="New discount code"
      buttonProps={{
        color: 'primary',
        size: 'md',
        children: isLoading ? 'Creating...' : 'Create code',
        type: 'button',
        onClick: handleSubmit,
        disabled:
          isLoading ||
          !discountCode ||
          !discountDesc ||
          !discountPercent ||
          !discountStart ||
          !discountExpires,
      }}
    >
      <div className="mt-4 space-y-4">
        <div className="flex space-x-3">
          <InputGroup
            required
            className="flex-1"
            label="Discount code"
            id="discountCode"
            help="Must be unique"
            disabled={isLoading}
            error={codeError}
            onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
            value={discountCode}
          />
          <InputGroup
            required
            className="flex-1"
            label="Percent"
            id="discountPercent"
            disabled={isLoading}
            help="Number between 1 and 99"
            trailingAddon="%"
            onChange={handlePercentChange}
            value={discountPercent}
          />
        </div>
        <InputGroup
          required
          label="Discount description"
          help="Example: Black Friday event"
          id="discountDesc"
          disabled={isLoading}
          onChange={(e) => setDiscountDesc(e.target.value)}
          value={discountDesc}
        />
        <div className="flex space-x-3">
          <InputGroup
            required
            inModal
            className="flex-1"
            type="date"
            label="Starts from"
            id="discountStart"
            disabled={isLoading}
            onChange={(date) => setDiscountStart(date)}
            value={discountStart}
          />
          <InputGroup
            required
            inModal
            className="flex-1"
            type="date"
            label="Ends on"
            id="discountExpires"
            disabled={isLoading}
            onChange={(date) => setDiscountExpires(date)}
            value={discountExpires}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default NewDiscountModal
