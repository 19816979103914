import React from 'react'

import usePrivateApi from '../../../../../hooks/usePrivateApi'
import Button from '../../../../../components/Button'
import Dialog from '../../../../../components/Dialog'

const DisableAutoRenewModal = ({ isOpen, tool, dealer }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/admin/dealers/${dealer.id}/tools/${tool.id}/subscription`,
      method: 'delete',
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not disable auto-renew for this tool. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Auto-renew disabled"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Auto-renew has been disabled for this tool.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => window.location.reload()}
      title="Disable auto-renew"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Disabling...' : 'Disable',
        color: 'red',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        <strong>
          Are you sure you wish to disable auto-renew for this tool?
        </strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        This may cause the dealer's tool subscription to expire.
      </p>
    </Dialog>
  )
}

export default DisableAutoRenewModal
