import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import RecentUploadItem from './RecentUploadItem'
import usePrivateSocket from '../../hooks/usePrivateSocket'
import { FETCH_UPLOAD_LIST } from '../../utils/sockets'
import BrandingContext from '../../context/BrandingContext'

const RecentUploadList = ({ isMaster }) => {
  const [uploads, setUploads] = useState(null)
  const [hasFetchedUploads, setHasFetchedUploads] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [branding] = useContext(BrandingContext)
  const { primaryColorPalette } = branding

  const [{ inRoom }, { socket }] = usePrivateSocket('/upload-list', true)

  useEffect(() => {
    if (inRoom && !hasFetchedUploads) {
      setHasFetchedUploads(true)

      socket.emit(FETCH_UPLOAD_LIST, { recent: true }, (err, { uploads }) => {
        setIsLoading(false)

        if (uploads) {
          setUploads(uploads)
        } else if (err) {
          setError(err)
        }
      })
    }
  }, [inRoom, socket, hasFetchedUploads])

  if (isLoading || uploads === null) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 flex-col p-18">
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          An error occurred
        </h3>
        <p className="text-sm text-gray-600 mt-1 mb-6">
          We couldn't fetch your recent uploads. Please raise a support ticket
          so our team can investigate this issue.
        </p>
      </div>
    )
  }

  if (uploads.length === 0) {
    return (
      <div>
        <div className="max-w-5xl mx-auto px-4">
          <h3 className="text-xl font-semibold tracking-tight mt-8">
            Welcome to your new account!
          </h3>
          <div className="text-gray-700 text-sm space-y-2 mt-2">
            <p>
              Use your account to upload ECU files to be remapped and lookup
              vehicle data.
            </p>
            {isMaster ? (
              <p>
                You'll need to purchase a credit package before you can upload
                an ECU file. We offer a few different credit package options,
                but the general idea is the more files you buy the cheaper the
                price per file.
              </p>
            ) : (
              <p>
                You'll need to be transferred upload credits by your master
                dealer in order to create your first upload.
              </p>
            )}
            <p>
              Once you have uploaded at least one ECU file to be remapped we'll
              show your recent uploads here.
            </p>
            <p>Happy remapping!</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="max-w-5xl mx-auto px-4">
        <div className="flex items-center mt-8">
          <h3 className="text-xl font-semibold tracking-tight flex-1">
            Recent uploads
          </h3>
          <Link
            className={`text-sm text-${primaryColorPalette}-600 font-medium ml-2`}
            to="/uploads"
          >
            View all uploads
          </Link>
        </div>
        <div className="mt-4 overflow-x-auto -mx-4 mb-8 sm:mx-0 bg-white rounded-md border border-gray-200 flex flex-col flex-wrap flex-1">
          {uploads.map((upload, index) => (
            <RecentUploadItem
              key={upload.id}
              upload={upload}
              first={index === 0}
              last={index === uploads.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default RecentUploadList
