import { useState, useEffect, useContext } from 'react'

import AuthContext from '../context/AuthContext'
import usePrivateApi from './usePrivateApi'
import useLocalStorage from './useLocalStorage'

const useAuthRefresh = () => {
  const [hasTried, setHasTried] = useState(false)
  const [, setAuth] = useContext(AuthContext)
  const [{ res, isLoading, error }, { sendRequest }] = usePrivateApi()
  const [getAuthToken] = useLocalStorage('authToken')

  useEffect(() => {
    const authToken = getAuthToken()

    if (authToken && !hasTried) {
      sendRequest({ url: '/auth/refresh' })
    } else if (!authToken) {
      setHasTried(true)
    }
  }, [getAuthToken, hasTried, sendRequest])

  useEffect(() => {
    if (res.data.membership) {
      setAuth({
        isLoggedIn: true,
        membership: { ...res.data.membership }
      })
      setHasTried(true)
    }

    if (error) {
      setHasTried(true)
    }
  }, [error, res, setAuth])

  return [hasTried, isLoading]
}

export default useAuthRefresh
