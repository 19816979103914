import React, { useState, useEffect, useMemo } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import _ from 'lodash'

import history from '../../../history'
import useObjState from '../../../hooks/useObjState'
import usePrivateSocket from '../../../hooks/usePrivateSocket'
import BoxNav from '../../../components/BoxNav'
import ViewDealerTitle from './Title'
import ViewDealerError from './Error'
import ViewDealerAccount from './account'
import ViewDealerCredits from './credits'
import ViewDealerWhiteLabel from './white-label'
import {
  FETCH_DEALER,
  DEALER_STATUS_CHANGED,
  DEALER_CREDIT_CHANGED,
} from '../../../utils/sockets'

const ViewDealer = (props) => {
  const { match, location } = props

  const [dealer, setDealer] = useObjState({
    id: match.params.dealerId,
    name: _.get(location, 'state.dealer.name', ''),
    tgtNumber: _.get(location, 'state.dealer.tgtNumber', ''),
    status: _.get(location, 'state.dealer.status', null),
    type: _.get(location, 'state.dealer.type', null),
    credit: {
      balance: _.get(location, 'state.dealer.credit.balance', null),
      packages: _.get(location, 'state.dealer.credit.packages', null),
      baseCost: _.get(location, 'state.dealer.credit.baseCost', null),
    },
  })

  const [hasFetchedDealer, setHasFetchedDealer] = useState(false)
  const [connectionErr, setConnectionErr] = useState(false)

  const [{ inRoom, err }, { socket, resetErr }] = usePrivateSocket(
    '/dealer',
    dealer.id
  )

  useEffect(() => {
    if (inRoom && !hasFetchedDealer) {
      socket.emit(FETCH_DEALER, (err, _dealer) => {
        if (_dealer) {
          setDealer({ ...dealer, ..._dealer })
          setHasFetchedDealer(true)
        } else {
          // We had a problem finding the ticket
          setConnectionErr(true)
        }
      })
    }
  }, [inRoom, socket, dealer, setDealer, hasFetchedDealer])

  useEffect(() => {
    if (socket) {
      socket.on(DEALER_STATUS_CHANGED, (status) => {
        setDealer({ status })
      })

      return () => socket.off(DEALER_STATUS_CHANGED)
    }
  }, [socket, setDealer])

  useEffect(() => {
    if (socket) {
      socket.on(DEALER_CREDIT_CHANGED, (dealerCredit) => {
        setDealer({ credit: { ...dealer.credit, ...dealerCredit } })
      })

      return () => socket.off(DEALER_CREDIT_CHANGED)
    }
  }, [socket, dealer, setDealer])

  useEffect(() => {
    if (err) {
      // We couldn't connect to server
      setConnectionErr(true)
      resetErr()
    }
  }, [err, resetErr])

  useEffect(() => {
    const interceptBackButton = () => {
      if (document.location.pathname.includes('/dealers/list')) {
        if (location?.state?.listState) {
          const { page, pathname, filters } = location.state.listState
          history.push(pathname, { page, filters })
        }
      }
      window.removeEventListener('popstate', interceptBackButton)
    }

    window.addEventListener('popstate', interceptBackButton)
  }, [location])

  const boxNavLinks = useMemo(() => {
    const links = [
      { to: `${match.url}/account`, label: 'Account' },
      { to: `${match.url}/credits`, label: 'Credits' },
    ]

    if (dealer.type === 'MASTER') {
      links.push({ to: `${match.url}/white-label`, label: 'White-label' })
    }

    return links
  }, [match.url, dealer.type])

  if (connectionErr) {
    return <ViewDealerError dealer={dealer} />
  }

  return (
    <>
      <ViewDealerTitle dealer={dealer} location={location} />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          {!hasFetchedDealer ? (
            <div className="flex items-center justify-center p-12 flex-1">
              <span className="spinner" />
            </div>
          ) : (
            <>
              <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
                <BoxNav current={location.pathname} links={boxNavLinks} />
              </div>
              <Switch>
                <Route
                  path={`${match.url}/account`}
                  render={(props) => (
                    <ViewDealerAccount
                      dealer={dealer}
                      setDealer={setDealer}
                      socket={socket}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${match.url}/credits`}
                  render={() => (
                    <ViewDealerCredits
                      dealer={dealer}
                      setDealer={setDealer}
                      socket={socket}
                    />
                  )}
                />
                <Route
                  path={`${match.url}/white-label`}
                  render={(props) => (
                    <ViewDealerWhiteLabel
                      dealer={dealer}
                      setDealer={setDealer}
                      socket={socket}
                      {...props}
                    />
                  )}
                />
                <Redirect
                  to={{
                    pathname: `${match.url}/account`,
                    state: location.state,
                  }}
                />
              </Switch>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewDealer
