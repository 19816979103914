import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import AuthContext from '../../../context/AuthContext'
import usePrivateApi from '../../../hooks/usePrivateApi'
import User from './User'
import UserInviteModal from './ModalInviteUser'
import Button from '../../../components/Button'
import BrandingContext from '../../../context/BrandingContext'

const UsersList = () => {
  const [memberships, setMemberships] = useState(null)
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [auth] = useContext(AuthContext)
  const [branding] = useContext(BrandingContext)
  const { isTgt, masterDealerName } = branding

  const [{ res, success, error }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    sendRequest({ url: '/settings/users' })
  }, [sendRequest])

  useEffect(() => {
    if (success) {
      const { memberships } = res.data

      // Make sure the current user is always on top of list
      if (memberships.length > 1) {
        const index = memberships.findIndex(
          (membership) => membership.id === auth.membership.id
        )

        memberships.splice(0, 0, memberships.splice(index, 1)[0])
      }
      setMemberships(memberships)
      resetSuccess()
    }
  }, [success, resetSuccess, res, auth])

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 flex-col p-18">
        <FontAwesomeIcon
          icon={faWifiSlash}
          className="text-gray-600"
          size="2x"
        />
        <h3 className="text-xl font-semibold tracking-tighter mt-4">
          Could not retrieve users
        </h3>
        <p className="text-sm text-gray-600 mt-1 mb-6">
          Please refresh the page to try again.
        </p>
        <Button color="white" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </div>
    )
  }

  if (memberships === null) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <div className="px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">Users</h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            You can invite other people into your dealer account. They will have
            full access to the system so you must ensure you have controls in
            place to prevent malicious use.
          </p>
          <p>
            {isTgt ? 'Top Gear Tuning Ltd' : masterDealerName} will not be
            responsibile for any actions taken by users you have invited.
          </p>
        </div>
      </div>
      <div className="flex-1">
        <div className="space-y-3">
          {memberships.map((membership) => (
            <User membership={membership} auth={auth} key={membership.id} />
          ))}
        </div>
        <Button
          color="primary"
          className="mt-5"
          onClick={() => setInviteModalOpen(true)}
        >
          Invite user
        </Button>
      </div>
      <UserInviteModal
        isOpen={inviteModalOpen}
        setIsOpen={() => setInviteModalOpen(false)}
      />
    </div>
  )
}

export default UsersList
