import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import {
  faBriefcase,
  faUserAlt,
  faSignOut,
} from '@fortawesome/pro-regular-svg-icons'

import { ON_HOLD } from '../../utils/dealers'
import Dropdown from '../Dropdown'
import AuthContext, { initialAuth } from '../../context/AuthContext'
import useLocalStorage from '../../hooks/useLocalStorage'
import { Notifications } from '../Notifications'
import BrandingContext from '../../context/BrandingContext'

const DealerNavBar = ({ location }) => {
  const [auth, setAuth] = useContext(AuthContext)
  const [branding] = useContext(BrandingContext)
  const [, setAuthToken] = useLocalStorage('authToken')

  const { primaryColorPalette } = branding

  const { user, dealer } = auth.membership

  const accountNumber = useMemo(() => {
    if (branding.isTgt) return dealer.tgtNumber

    const number = dealer.tgtNumber.slice(4)
    const letters = branding.masterDealerName.split(' ')
    const prefix = letters.map((x) => x[0].toUpperCase()).join('')
    return `${prefix}-${number}`
  }, [branding, dealer.tgtNumber])

  let links = [
    { to: '/dashboard', label: 'Dashboard' },
    { to: '/uploads', label: 'Uploads' },
    { to: '/vehicle-data', label: 'Vehicle data' },
    { to: '/settings', label: 'Settings' },
  ]

  if (dealer.type === 'MASTER') {
    links.splice(3, 0, { to: '/support', label: 'Support' })

    if (dealer.status !== ON_HOLD) {
      links.splice(2, 0, { to: '/credits', label: 'Credits' })
    }
  }

  if (dealer.toolsConfirmationNeeded) {
    links = [{ to: '/settings', label: 'Settings' }]
  }

  const getUserInitials = () => {
    const { firstName, lastName } = user
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  }

  const getBusinessInitials = () => {
    const { name } = dealer
    const words = name.split(' ')
    const firstInitial = words[0].charAt(0).toUpperCase()
    return words.length > 1
      ? firstInitial + words[1].charAt(0).toUpperCase()
      : firstInitial + words[0].charAt(1).toUpperCase()
  }

  const handleLogout = () => {
    setAuthToken()
    setAuth(initialAuth)
  }

  if (!auth.isLoggedIn) {
    return null
  }

  return (
    <>
      <header className={`bg-${primaryColorPalette}-600`}>
        <div className="max-w-5xl mx-auto">
          <div
            className={`flex items-center justify-between border-b border-${primaryColorPalette}-500 pt-5 pb-4 px-4`}
          >
            <div className="flex items-center">
              <div className="hidden sm:block h-10 mr-3 w-10">
                {dealer.logoUrl ? (
                  <img
                    src={dealer.logoUrl}
                    alt="business logo"
                    className="h-10 w-10 rounded-md block"
                  />
                ) : (
                  <span
                    className={`bg-${primaryColorPalette}-300 rounded-md text-gray-900 block font-bold leading-10 text-center text-sm`}
                  >
                    {getBusinessInitials()}
                  </span>
                )}
              </div>
              <div>
                <span className="text-white text-sm block font-semibold leading-none mb-1">
                  {dealer.name}
                </span>
                <span className="text-white block text-xs leading-none">
                  {accountNumber} | Credits: {dealer.credit.balance}
                </span>
              </div>
            </div>
            <div className="flex">
              <Notifications />
              <Dropdown
                renderButton={({ MenuButton }) =>
                  user.imageUrl ? (
                    <MenuButton className="rounded-full block p-0 ml-6 h-10 w-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white">
                      <img
                        src={user.imageUrl}
                        alt="Avatar"
                        className="bg-transparent rounded-full inline-block h-10 w-10"
                      />
                    </MenuButton>
                  ) : (
                    <MenuButton
                      className={`rounded-full text-sm bg-${primaryColorPalette}-300 text-gray-900 block font-bold leading-10 text-center p-0 ml-6 h-10 w-10 hover:bg-${primaryColorPalette}-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white"`}
                    >
                      {getUserInitials()}
                    </MenuButton>
                  )
                }
                items={[
                  [
                    {
                      as: Link,
                      to: '/settings/dealer',
                      label: 'Business profile',
                      icon: faBriefcase,
                    },
                    {
                      as: Link,
                      to: '/settings/account',
                      label: 'Your profile',
                      icon: faUserAlt,
                    },
                  ],
                  {
                    onClick: handleLogout,
                    label: 'Sign out',
                    icon: faSignOut,
                  },
                ]}
              />
            </div>
          </div>
          <div className="pt-3 pb-3 overflow-x-auto">
            <nav className="flex items-center min-h-[40px] pl-4">
              {links.map((link) => (
                <Link
                  className={cn(
                    `rounded-md text-white text-sm font-medium py-2 px-3 mr-3 no-underline whitespace-nowrap hover:text-${primaryColorPalette}-200 focus:outline-none focus:ring-2 focus:ring-white`,
                    {
                      [`bg-${primaryColorPalette}-800 text-white`]:
                        location.pathname.startsWith(link.to),
                    }
                  )}
                  key={link.to}
                  to={link.to}
                >
                  {link.label}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </header>
      {dealer.toolPaymentNeeded && (
        <div className="bg-red-800 py-2">
          <div className="max-w-5xl mx-auto px-4">
            <p className="text-white text-sm font-medium">
              ACTION NEEDED: We need you to confirm your tool subscription
              payment.{' '}
              <Link className="underline" to="/settings/tools">
                Click here to view.
              </Link>
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default DealerNavBar
