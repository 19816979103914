import React, { useState, useCallback } from 'react'
import {
  faCar,
  faTruck,
  faTractor,
  faAnchor,
  faBiking,
} from '@fortawesome/pro-regular-svg-icons'

import VrmLookup from '../../VehicleData/VrmLookup'
import RadioGroup from '../../RadioGroup'
import RadioCard from '../../RadioCard'
import InputGroup from '../../InputGroup'
import ManualLookup from '../../VehicleData/ManualLookup'

const NewUploadVehicleForm = (props) => {
  const {
    isLoading,
    mileageStr,
    setMileageStr,
    setMileage,
    reference,
    setReference,
    vehicleType,
    setVehicleType,
    vehicle,
    setVehicle,
    setVrm,
    vrm,
    uploadType,
  } = props

  const [lookupType, setLookupType] = useState('VRM')
  const [hideDetails, setHideDetails] = useState(false)

  const handleVehicleTypeChange = useCallback(
    (type) => {
      setVehicleType(type)

      if (type === 'CAR') {
        // Reset any vehicle details from other types
        return setVehicle(null)
      }

      return setVehicle({
        make: '',
        model: '',
        engineSize: '',
        hpModel: '',
        year: '',
      })
    },
    [setVehicle, setVehicleType]
  )

  const handleVehicleChange = useCallback(
    ({ vehicle, vrm }) => {
      setVehicle(vehicle)
      setHideDetails(true)
      setVrm(vrm)
    },
    [setVehicle, setVrm]
  )

  const handleManualVehicleDataChange = (key) => {
    return (e) => {
      e.persist()
      return setVehicle((vehicle) => ({ ...vehicle, [key]: e.target.value }))
    }
  }

  const handleMileageChange = (e) => {
    const cleanValue = e.target.value.replace(/\D/g, '')
    const number = parseInt(cleanValue, 10)
    setMileage(number || null)
    setMileageStr(cleanValue ? number.toLocaleString() : '')
  }

  const VehicleTypeContainer = useCallback(
    ({ children }) => (
      <div className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-2 lg:grid-cols-3">
        {children}
      </div>
    ),
    []
  )

  const OptionContainer = useCallback(
    ({ children }) => <div className="space-y-3">{children}</div>,
    []
  )

  if (uploadType === 'ADJUST') {
    return (
      <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-6 py-8">
        <div className="flex-[2]">
          <h3 className="text-xl font-semibold tracking-tight">Vehicle</h3>
          <div className="space-y-2 mt-1 text-gray-700 text-sm">
            <p>Use our VRM lookup tool for vehicles from the UK.</p>
            <p>
              Use our manual lookup tool for vehicles that can’t be found by VRM
              or that are from outside the UK.
            </p>
          </div>
        </div>
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm px-6 py-5 flex-[3] space-y-4">
          <div className="py-5 text-center bg-gray-100 rounded-md">
            <h5 className="text-base font-semibold tracking-tight">
              Adjustment remap selected
            </h5>
            <p className="text-sm text-gray-600 mt-3">
              We will fetch the vehicle details from the original upload
              selected above.
            </p>
          </div>
          <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
            <InputGroup
              label="Vehicle mileage"
              id="mileage"
              required
              className="flex-1"
              placeholder="Enter mileage"
              disabled={isLoading}
              onChange={handleMileageChange}
              value={mileageStr}
            />
            <InputGroup
              label="Reference (optional)"
              id="reference"
              className="flex-1"
              placeholder="VRM, VIN etc."
              disabled={isLoading}
              onChange={(e) => setReference(e.target.value)}
              help="Searchable reference for your upload"
              value={reference}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-6 py-8">
      <div className="flex-[2]">
        <h3 className="text-xl font-semibold tracking-tight">Vehicle</h3>
        <div className="space-y-2 mt-1 text-gray-700 text-sm">
          <p>Use our VRM lookup tool for vehicles from the UK.</p>
          <p>
            Use our manual lookup tool for vehicles that can’t be found by VRM
            or that are from outside the UK.
          </p>
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm px-6 py-5 flex-[3] space-y-4">
        <div>
          <p className="font-medium text-sm mb-2">Vehicle type</p>
          <RadioCard
            label="Vehicle type"
            selected={vehicleType}
            setSelected={handleVehicleTypeChange}
            Container={VehicleTypeContainer}
            items={[
              {
                label: 'Car',
                icon: faCar,
                value: 'CAR',
              },
              {
                label: 'Truck',
                icon: faTruck,
                value: 'TRUCK',
              },
              {
                label: 'Motorbike',
                icon: faBiking,
                value: 'MOTORBIKE',
              },
              {
                label: 'Tractor',
                icon: faTractor,
                value: 'TRACTOR',
              },
              {
                label: 'Boat',
                icon: faAnchor,
                value: 'BOAT',
              },
            ]}
          />
        </div>
        {vehicleType === 'CAR' && (
          <>
            <div>
              <p className="font-medium text-sm mb-2">Search for vehicle</p>
              <RadioGroup
                selected={lookupType}
                setSelected={setLookupType}
                Container={OptionContainer}
                items={[
                  {
                    label: 'VRM lookup',
                    description:
                      'Search for a vehicle by registration number (UK only)',
                    value: 'VRM',
                    onClick: () => setHideDetails(false),
                    renderContent: ({ checked }) => (
                      <VrmLookup
                        checked={checked}
                        onVehicleChange={handleVehicleChange}
                        hideDetails={hideDetails}
                      />
                    ),
                  },
                  {
                    label: 'Manual lookup',
                    description:
                      'Search for a vehicle by manually selecting the correct data',
                    value: 'MANUAL',
                    onClick: () => setHideDetails(false),
                    renderContent: ({ checked }) => (
                      <ManualLookup
                        checked={checked}
                        onVehicleChange={handleVehicleChange}
                        hideDetails={hideDetails}
                      />
                    ),
                  },
                ]}
              />
            </div>
            {vehicle && (
              <>
                <div>
                  <p className="font-medium text-sm mb-1">Vehicle found</p>
                  <div className="px-4 py-4 bg-gray-100 rounded-md">
                    <p className="text-sm font-medium tracking-tight">
                      {vehicle.make} {vehicle.model} {vehicle.startYear}{' '}
                      {vehicle.fuel} {vehicle.engineSize}cc{' '}
                      {vehicle.power.original}
                      HP {vrm && `(${vrm})`}
                    </p>
                  </div>
                </div>
                <InputGroup
                  label="Vehicle mileage"
                  id="mileage"
                  required
                  placeholder="Enter mileage"
                  disabled={isLoading}
                  onChange={handleMileageChange}
                  value={mileageStr}
                />
                {lookupType === 'MANUAL' && (
                  <div className="mt-12">
                    <InputGroup
                      className="mt-4 mb-0"
                      label="Reference (optional)"
                      id="reference"
                      placeholder="VRM, VIN etc."
                      disabled={isLoading}
                      onChange={(e) => setReference(e.target.value)}
                      help="Searchable reference for your upload"
                      value={reference}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        {vehicleType !== 'CAR' && uploadType === 'FULL' && (
          <div className="space-y-3">
            <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
              <InputGroup
                label="Make"
                id="vehicleMake"
                required
                className="flex-1"
                placeholder="Enter vehicle make"
                disabled={isLoading}
                onChange={handleManualVehicleDataChange('make')}
                value={vehicle.make}
              />
              <InputGroup
                label="Model"
                id="vehicleModel"
                required
                className="flex-1"
                placeholder="Enter vehicle model"
                disabled={isLoading}
                onChange={handleManualVehicleDataChange('model')}
                value={vehicle.model}
              />
            </div>
            <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
              <InputGroup
                label="Engine size"
                id="vehicleEngineSize"
                required
                className="flex-1"
                placeholder="Enter engine size"
                disabled={isLoading}
                onChange={handleManualVehicleDataChange('engineSize')}
                value={vehicle.engineSize}
              />
              <InputGroup
                label="HP model"
                id="vehicleHpModel"
                required
                className="flex-1"
                placeholder="Enter vehicle HP model"
                disabled={isLoading}
                onChange={handleManualVehicleDataChange('hpModel')}
                value={vehicle.hpModel}
              />
            </div>
            <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
              <InputGroup
                label="Year"
                id="vehicleYear"
                required
                className="flex-1"
                placeholder="Enter vehicle year"
                disabled={isLoading}
                onChange={handleManualVehicleDataChange('year')}
                value={vehicle.year}
              />
              <InputGroup
                label="Vehicle mileage"
                id="mileage"
                required
                className="flex-1"
                placeholder="Enter mileage"
                disabled={isLoading}
                onChange={handleMileageChange}
                value={mileageStr}
              />
            </div>
            <InputGroup
              label="Reference (optional)"
              id="reference"
              placeholder="VRM, VIN etc."
              disabled={isLoading}
              onChange={(e) => setReference(e.target.value)}
              help="Searchable reference for your upload"
              value={reference}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default NewUploadVehicleForm
