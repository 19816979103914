import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const UsersDisableModal = ({ isOpen, setIsOpen, membership }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/users/${membership.id}`,
      method: 'patch',
      data: { status: 'DISABLED' },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not disable user account. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Account disabled"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Successfully disabled{' '}
          <strong>
            {membership.user.firstName} {membership.user.lastName}'s
          </strong>{' '}
          account ({membership.user.email}).
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Disable user account"
      buttonProps={{
        onClick: handleSubmit,
        children: isLoading ? 'Disabling...' : 'Disable account',
        color: 'red',
        disabled: isLoading,
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        <strong>
          Are you sure you wish to disable {membership.user.firstName}{' '}
          {membership.user.lastName}'s account?
        </strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        This user won't be able to login or carry out any actions in the system
        once their account is disabled. You can re-activate their account in the
        future if required.
      </p>
    </Dialog>
  )
}

export default UsersDisableModal
