import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import TicketList from '../../components/support/list'
import ViewTicket from '../../components/support/view'

const Support = ({ location }) => {
  return (
    <Switch>
      <Route
        path={[
          '/support/list/open',
          '/support/list/closed',
          '/support/list/archived',
          '/support/list/all',
        ]}
        render={(props) => <TicketList isAdmin {...props} />}
      />
      <Route
        path="/support/view/:ticketId"
        render={(props) => <ViewTicket isAdmin {...props} />}
      />
      <Redirect to={{ pathname: '/support/list/all', state: location.state }} />
    </Switch>
  )
}

export default Support
