import React from 'react'
import { faShield, faShieldCheck } from '@fortawesome/pro-regular-svg-icons'

import RadioBox from '../../../RadioBox'

const NewUploadAddonEcuWarranty = (props) => {
  const { isLoading, ecuWarranty, setEcuWarranty } = props

  return (
    <RadioBox
      checked={ecuWarranty}
      checkedLabel="Yes - protect me"
      uncheckedLabel="No - don't protect me"
      disabled={isLoading}
      name="ecuWarranty"
      description="Protect yourself with an ECU warranty. If anything goes wrong during the writing process TGT will fix or replace the unit under this warranty for FREE."
      onChange={setEcuWarranty}
      checkedIcon={faShieldCheck}
      uncheckedIcon={faShield}
      label={
        <>
          Ecu warranty (0.25 credit){' '}
          <span className="bg-pink-100 py-0.5 px-1.5 align-baseline rounded-md text-pink-700 ml-1 text-xs font-semibold tracking-tight">
            New
          </span>
        </>
      }
    />
  )
}

export default NewUploadAddonEcuWarranty
