import { useState, useReducer, useEffect, useCallback } from 'react'
import axios from 'axios'

import useLocalStorage from './useLocalStorage'

const API_URL = import.meta.env.VITE_API_URL
const MAX_FILE_SIZE = 1024 * 1024 * 25 // 25MB

const INITIAL_STATE = {
  files: [],
  allFilesUploaded: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_files': {
      return { files: action.payload }
    }

    case 'add_files': {
      const files = [...state.files, ...action.payload]
      return { ...state, files }
    }

    case 'remove_file': {
      const files = state.files.filter((file, i) => i !== action.payload)
      return { ...state, files }
    }

    case 'new_upload_url_init': {
      const { index } = action.payload
      const files = [...state.files]
      files[index] = { ...files[index], isPreparing: true }
      return { ...state, files }
    }

    case 'new_upload_url_success': {
      const { index, uploadUrl, id } = action.payload
      const files = [...state.files]
      files[index] = { ...files[index], id, uploadUrl, isPreparing: false }
      return { ...state, files }
    }

    case 'upload_start': {
      const { index } = action.payload
      const files = [...state.files]
      files[index] = { ...files[index], isUploading: true, uploadProgress: 0 }
      return { ...state, files }
    }

    case 'upload_progress': {
      const { index, progress } = action.payload
      const files = [...state.files]
      files[index] = { ...files[index], uploadProgress: progress }
      return { ...state, files }
    }

    case 'upload_success': {
      const { index } = action.payload
      const files = [...state.files]
      files[index] = { ...files[index], uploadProgress: 100, isUploaded: true }
      return { ...state, files }
    }

    case 'upload_error': {
      const { index, err } = action.payload
      const files = [...state.files]
      files[index] = { ...files[index], uploadErr: err }
      return { ...state, files }
    }

    case 'change_all_file_status': {
      const allFilesUploaded = action.payload
      return { ...state, allFilesUploaded }
    }

    case 'reset_hook': {
      return { ...INITIAL_STATE }
    }

    default: {
      throw new Error(`Invalid action type: ${action.type}`)
    }
  }
}

const mapFileState = (files) => {
  return files.map((file) => {
    const isTooLarge = file.size > MAX_FILE_SIZE

    return {
      id: null,
      name: file.name,
      size: file.size,
      fileBlob: file,
      isUploading: false,
      uploadProgress: null,
      isUploaded: false,
      isAccepted: !isTooLarge && !file.rejectReason,
      isRejected: isTooLarge || file.rejectReason,
      // prettier-ignore
      rejectReason: file.rejectReason
        ? file.rejectReason
        : isTooLarge
          ? 'File size is too large'
          : null,
      uploadErr: null,
      uploadUrl: null,
      isPreparing: null,
    }
  })
}

const useFileUpload = (bucketName) => {
  const [bucket] = useState(bucketName)
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const [_upload, _setUpload] = useState(false)
  const [_hasUploadUrls, _setHasUploadUrls] = useState(false)
  const [getAuthToken] = useLocalStorage('authToken')
  const _authToken = getAuthToken()

  const setFiles = useCallback(
    (files) =>
      dispatch({
        type: 'set_files',
        payload: mapFileState(files),
      }),
    []
  )

  const addFiles = useCallback(
    (files) =>
      dispatch({
        type: 'add_files',
        payload: mapFileState(files),
      }),
    []
  )

  const removeFile = useCallback(
    (index) => dispatch({ type: 'remove_file', payload: index }),
    []
  )

  const resetFiles = useCallback(
    () => dispatch({ type: 'set_files', payload: [] }),
    []
  )

  const startUpload = useCallback(() => _setUpload(true), [])

  const resetHook = useCallback(() => dispatch({ type: 'reset_hook' }), [])

  // Fetches signed url for each file which can used to upload via PUT.
  useEffect(() => {
    if (_upload && !_hasUploadUrls) {
      if (!bucket) {
        throw new Error('Bucket name is required.')
      }

      if (state.files.length < 1) {
        throw new Error('No files have been selected for upload.')
      }

      const fetchUploadUrl = async (file, index) => {
        dispatch({ type: 'new_upload_url_init', payload: { index } })

        const axiosConfig = {
          baseURL: API_URL,
          url: '/files',
          method: 'post',
          headers: { Authorization: `Bearer ${_authToken}` },
          data: { name: file.name, size: file.size, bucket },
        }

        const _res = await axios(axiosConfig)

        dispatch({
          type: 'new_upload_url_success',
          payload: { index, uploadUrl: _res.data.url, id: _res.data.file.id },
        })
      }

      const fetchAllUploadUrls = async () => {
        await Promise.all(
          state.files.map(async (file, i) => {
            if (!file.isUploaded && !file.isRejected) {
              await fetchUploadUrl(file, i)
            }
          })
        )
        _setHasUploadUrls(true)
      }

      fetchAllUploadUrls()
      _setUpload(false)
    }
  }, [_upload, _hasUploadUrls, bucket, state.files, _authToken])

  // Once we have signed urls for each file we upload them.
  // We update the upload progress for each file.
  // We don't clear the files uploaded as we may rely on them to show UI.
  useEffect(() => {
    if (_hasUploadUrls) {
      const uploadFile = async (file, index) => {
        dispatch({ type: 'upload_start', payload: { index } })

        const axiosConfig = {
          data: file.fileBlob,
          url: file.uploadUrl,
          method: 'put',
          onUploadProgress: (e) => {
            const progress = Math.round((e.loaded * 100) / e.total)
            dispatch({ type: 'upload_progress', payload: { index, progress } })
          },
        }

        try {
          await axios(axiosConfig)
          dispatch({ type: 'upload_success', payload: { index } })
        } catch (err) {
          dispatch({ type: 'upload_error', payload: { index, err } })
        }
      }

      const uploadAllFiles = async () => {
        await Promise.all(
          state.files.map(async (file, i) => {
            if (!file.isUploaded && !file.isRejected) {
              await uploadFile(file, i)
            }
          })
        )
      }

      uploadAllFiles()
      _setHasUploadUrls(false)
    }
  }, [_hasUploadUrls, state.files])

  useEffect(() => {
    if (state.files.length > 0) {
      const acceptedFiles = state.files.filter((file) => file.isAccepted)

      if (acceptedFiles.length > 0) {
        const allFilesUploaded = acceptedFiles.every((file) => file.isUploaded)

        if (allFilesUploaded !== state.allFilesUploaded) {
          dispatch({
            type: 'change_all_file_status',
            payload: allFilesUploaded,
          })
        }
      }
    }
  }, [state.allFilesUploaded, state.files])

  return [
    state,
    { setFiles, startUpload, addFiles, removeFile, resetFiles, resetHook },
  ]
}

export default useFileUpload
