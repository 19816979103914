import React, { useState, useContext } from 'react'

import AuthContext from '../../context/AuthContext'
import usePrivateApi from '../../hooks/usePrivateApi'
import Dialog from '../Dialog'
import Button from '../Button'
import InputGroup from '../InputGroup'

const PaymentMethodNicknameModal = (props) => {
  const { admin, dealerId, isOpen, setIsOpen, method } = props

  const [nickname, setNickname] = useState('')
  const [auth] = useContext(AuthContext)
  const { user } = auth.membership

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    if (admin) {
      sendRequest({
        url: `/admin/dealers/${dealerId}/payment-methods/${method.id}`,
        method: 'patch',
        data: { nickname },
      })
    } else {
      sendRequest({
        url: `/billing/payment-methods/${method.id}`,
        method: 'patch',
        data: { nickname },
      })
    }
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not save card nickname. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Saved card machine"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Nickname <strong>{nickname}</strong> has been saved.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Change card nickname"
      buttonProps={{
        disabled: isLoading || method.nickname === nickname,
        color: 'primary',
        onClick: handleSubmit,
        children: isLoading ? 'Saving...' : 'Save nickname',
      }}
    >
      <div className="mt-2">
        <InputGroup
          placeholder={`${user.firstName}'s credit card`}
          label="Card nickname"
          id="cardNickname"
          disabled={isLoading}
          onChange={(e) => setNickname(e.target.value)}
          value={nickname}
        />
      </div>
    </Dialog>
  )
}

export default PaymentMethodNicknameModal
