import React, { useState } from 'react'
import currency from 'currency.js'

import ViewDealerCreditChangeBaseCostModal from './ModalChangeBaseCost'
import Button from '../../../../components/Button'

const ViewDealerCreditBaseCost = ({ dealer, setDealer, socket }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleOnChangeSuccess = (dealerCredit) => {
    setDealer({ credit: { ...dealer.credit, ...dealerCredit } })
  }

  return (
    <div className="px-6 pt-6 pb-6 space-y-3 sm:space-y-0 sm:flex sm:space-x-6 sm:items-center sm:justify-between">
      <h2 className="text-lg font-semibold tracking-tight mb-0.5">
        Dealer's base cost is £{currency(dealer.credit.baseCost / 100).format()}{' '}
        per file
      </h2>
      <Button onClick={() => setModalOpen(true)}>Change base cost</Button>
      <ViewDealerCreditChangeBaseCostModal
        isOpen={modalOpen}
        setIsOpen={() => setModalOpen(false)}
        onSuccess={handleOnChangeSuccess}
        socket={socket}
        baseCost={dealer.credit.baseCost}
      />
    </div>
  )
}

export default ViewDealerCreditBaseCost
