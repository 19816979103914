import {
  faCar,
  faChartArea,
  faTruck,
  faTractor,
  faAnchor,
  faBiking,
  faHistory,
  faSeedling,
  faBalanceScale,
  faTachometerAlt,
  faTachometerAltFastest,
  faFlagCheckered,
} from '@fortawesome/pro-regular-svg-icons'

import { objectToOptions } from './objects'

const READY = 'READY'
const IN_PROGRESS = 'IN_PROGRESS'
const DOWNLOADED = 'DOWNLOADED'
const PENDING = 'PENDING'
const RE_CREDITED = 'RE_CREDITED'
const ON_HOLD = 'ON_HOLD'
const PAYMENT_NEEDED = 'PAYMENT_NEEDED'

const OTHER = 'OTHER'
const CUSTOM = 'CUSTOM'

const WARRANTY_12 = 'WARRANTY_12'
const WARRANTY_24 = 'WARRANTY_24'
const FILTERS_1 = 'FILTERS_1'
const FILTERS_2 = 'FILTERS_2'

const statusLookup = {
  READY: 'Ready',
  IN_PROGRESS: 'In progress',
  DOWNLOADED: 'Downloaded',
  PENDING: 'Pending',
  RE_CREDITED: 'Re-credited',
  ON_HOLD: 'On hold',
  PAYMENT_NEEDED: 'Payment needed',
}

const vehicleTypeIconLookup = {
  CAR: faCar,
  TRUCK: faTruck,
  TRACTOR: faTractor,
  BOAT: faAnchor,
  MOTORBIKE: faBiking,
}

const toolLookup = {
  KESS: 'KESS V2',
  KESS3: 'KESS3',
  CMD: 'CMD',
  KTAG: 'KTAG',
  AUTOTUNER: 'AUTOTUNER',
  GENIUS: 'GENIUS',
  TRANSDATA: 'TRANSDATA',
  FLEX: 'FLEX',
  MAGPRO: 'MAGPRO',
  PCMFLASH: 'PCMFLASH',
  OTHER: 'OTHER',
}

const tuneLookup = {
  // LEGACY
  POWER: 'Power',
  CUSTOM: 'Custom',
  //CURRENT
  STOCK: 'Stock',
  ECO: 'Eco',
  BLEND: 'Blend',
  STAGE1: 'Stage one',
  STAGE2: 'Stage two',
  STAGE3: 'Stage three',
}

const tuneIconLookup = {
  // LEGACY
  POWER: faFlagCheckered,
  CUSTOM: faChartArea,
  //CURRENT
  STOCK: faHistory,
  ECO: faSeedling,
  BLEND: faBalanceScale,
  STAGE1: faTachometerAlt,
  STAGE2: faTachometerAltFastest,
  STAGE3: faFlagCheckered,
}

const uploadLabelColorLookup = {
  READY: 'green',
  RE_CREDITED: 'red',
  IN_PROGRESS: 'orange',
  PENDING: 'yellow',
  DOWNLOADED: 'gray',
  ON_HOLD: 'pink',
  PAYMENT_NEEDED: 'purple',
}

const warrantyLookup = {
  WARRANTY_12: '12 month warranty for £10 + VAT (RRP £50)',
  WARRANTY_24: '24 month warranty for £20 + VAT (RRP £125)',
}

export {
  READY,
  IN_PROGRESS,
  DOWNLOADED,
  PENDING,
  RE_CREDITED,
  ON_HOLD,
  OTHER,
  PAYMENT_NEEDED,
  CUSTOM,
  WARRANTY_12,
  WARRANTY_24,
  FILTERS_1,
  FILTERS_2,
  statusLookup,
  toolLookup,
  warrantyLookup,
}

export const getStatus = (key) => statusLookup[key]
export const getTool = (key) => toolLookup[key]
export const getTune = (key) => tuneLookup[key]
export const getTuneIcon = (key) => tuneIconLookup[key]
export const getVehicleTypeIcon = (key) => vehicleTypeIconLookup[key]
export const getUploadLabelColor = (key) => uploadLabelColorLookup[key]
export const getWarranty = (key) => warrantyLookup[key]
export const getToolOptions = () => objectToOptions(toolLookup)
export const getWarrantyOptions = () => objectToOptions(warrantyLookup)
