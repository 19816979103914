import React, { useState, useEffect } from 'react'
import currency from 'currency.js'

import { EDIT_DEALER_CREDIT_PACKAGE } from '../../../../utils/sockets'
import Button from '../../../../components/Button'
import Dialog from '../../../../components/Dialog'
import InputGroup from '../../../../components/InputGroup'

const ViewDealerCreditEditPackageModal = (props) => {
  const { isOpen, setIsOpen, socket, creditPackage, onSuccess } = props

  const [packageName, setPackageName] = useState('')
  const [packageCredits, setPackageCredits] = useState(null)
  const [packageCostStr, setPackageCostStr] = useState('')
  const [packageCost, setPackageCost] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setError(null)
      setSuccess(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && !success && creditPackage) {
      setPackageName(creditPackage.name)
      setPackageCredits(creditPackage.credits)
      setPackageCost(creditPackage.cost)
      setPackageCostStr(currency(creditPackage.cost / 100).format())
    }
  }, [isOpen, creditPackage, success])

  const handleCostChange = (e) => {
    const input = e.target.value.replace(/[^0-9.,]/g, '')
    const inputCurrency = currency(input)
    setPackageCost(
      inputCurrency.value ? Math.round(inputCurrency.value * 100) : null
    )
    setPackageCostStr(input)
  }

  const handleCreditsChange = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, '')
    setPackageCredits(input)
  }

  const getPricePerFile = () => {
    if (!packageCredits || packageCredits === '0' || !packageCost) {
      return 'N/A'
    }

    const cost = currency(packageCost / 100)
      .divide(packageCredits)
      .format()

    return `£${cost} per file`
  }

  const handleSubmit = () => {
    setIsLoading(true)

    socket.emit(
      EDIT_DEALER_CREDIT_PACKAGE,
      {
        creditPackageId: creditPackage.id,
        name: packageName,
        cost: packageCost,
        credits: packageCredits,
      },
      (err, dealerCredit) => {
        setIsLoading(false)

        if (dealerCredit) {
          setSuccess(true)
          onSuccess(dealerCredit)
          return
        }

        if (err) {
          setError(err)
          return
        }
      }
    )
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          There was an error saving your edits.
        </p>
        <Button className="mt-4" onClick={setIsOpen}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Edit credit package"
      buttonProps={{
        disabled:
          isLoading ||
          !packageName ||
          !packageCredits ||
          packageCredits === '0' ||
          !packageCost,
        onClick: handleSubmit,
        children: isLoading ? 'Saving...' : 'Save edits',
        color: 'primary',
      }}
    >
      <div className="space-y-4 mt-2">
        <InputGroup
          required
          label="Package name"
          id="packageName"
          disabled={isLoading}
          onChange={(e) => setPackageName(e.target.value)}
          value={packageName}
        />
        <div className="space-y-4 sm:flex sm:space-x-3 sm:space-y-0">
          <InputGroup
            required
            className="flex-1"
            label="Number of credits"
            id="numberOfCredits"
            disabled={isLoading}
            help="Amount of credits to add to account"
            onChange={handleCreditsChange}
            value={packageCredits}
          />
          <InputGroup
            required
            className="flex-1"
            label="Total cost"
            id="totalCost"
            disabled={isLoading}
            onChange={handleCostChange}
            help={getPricePerFile()}
            value={packageCostStr}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ViewDealerCreditEditPackageModal
