import React, { useState } from 'react'

import Button from '../../../../components/Button'
import ViewDealerCreditPackageItem from './PackageItem'
import ViewDealerCreditNewPackageModal from './ModalNewCreditPackage'
import ViewDealerCreditEditPackageModal from './ModalEditCreditPackage'
import ViewDealerCreditDeletePackageModal from './ModalDeleteCreditPackage'

const ViewDealerCreditPackages = ({ dealer, setDealer, socket }) => {
  const [newPackageOpen, setNewPackageOpen] = useState(false)
  const [editPackageId, setEditPackageId] = useState(null)
  const [deletePackageId, setDeletePackageId] = useState(null)

  const handleSuccess = (dealerCredit) => {
    setEditPackageId(null)
    setDeletePackageId(null)
    setDealer({ credit: { ...dealer.credit, ...dealerCredit } })
  }

  return (
    <div className="px-6 pt-6 pb-6">
      <div className="space-y-3 sm:space-y-0 sm:flex sm:space-x-6 sm:items-center sm:justify-between">
        <h2 className="text-lg font-semibold tracking-tight mb-0.5">
          Credit Packages
        </h2>
        <Button onClick={() => setNewPackageOpen(true)}>Add new package</Button>
      </div>
      <div className="mt-6 mb-2 space-y-3">
        {dealer.credit.packages
          .sort((a, b) => (a.cost > b.cost ? 1 : -1))
          .map((creditPackage) => (
            <ViewDealerCreditPackageItem
              creditPackage={creditPackage}
              baseCost={dealer.credit.baseCost}
              dealer={dealer}
              setDealer={setDealer}
              socket={socket}
              key={creditPackage.id}
              setEditPackageId={setEditPackageId}
              setDeletePackageId={setDeletePackageId}
            />
          ))}
      </div>
      <ViewDealerCreditNewPackageModal
        isOpen={newPackageOpen}
        setIsOpen={() => setNewPackageOpen(false)}
        onSuccess={handleSuccess}
        socket={socket}
      />
      <ViewDealerCreditEditPackageModal
        isOpen={editPackageId !== null}
        setIsOpen={() => setEditPackageId(null)}
        onSuccess={handleSuccess}
        socket={socket}
        creditPackage={
          editPackageId !== null &&
          dealer.credit.packages.find((x) => x.id === editPackageId)
        }
      />
      <ViewDealerCreditDeletePackageModal
        isOpen={deletePackageId !== null}
        setIsOpen={() => setDeletePackageId(null)}
        onSuccess={handleSuccess}
        socket={socket}
        creditPackage={
          deletePackageId !== null &&
          dealer.credit.packages.find((x) => x.id === deletePackageId)
        }
      />
    </div>
  )
}

export default ViewDealerCreditPackages
