import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const SubdealerCancelInviteModal = ({ isOpen, setIsOpen, subdealer }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/subdealers/invite/${subdealer.id}`,
      method: 'delete',
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not cancel subdealer invite. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Invitation cancelled"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Successfully cancelled the invite for{' '}
          <strong>{subdealer.name}</strong> .
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Cancel subdealer invitation"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Cancelling...' : 'Cancel invite',
        color: 'red',
      }}
    >
      <p className="text-gray-600 text-sm mt-2">
        <strong>
          Are you sure you wish to cancel the invite for {subdealer.name}?
        </strong>
      </p>
      <p className="text-gray-600 text-sm mt-2">
        This subdealer won't be able to login or carry out any actions in the
        system until they accept an invite. You can send another invite in the
        future if required.
      </p>
    </Dialog>
  )
}

export default SubdealerCancelInviteModal
