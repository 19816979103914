import React, { useContext } from 'react'
import BrandingContext from '../context/BrandingContext'

const Footer = () => {
  const [branding] = useContext(BrandingContext)
  const { isTgt, primaryColorPalette } = branding

  return (
    <footer className="bg-gray-100 border-t border-gray-200 py-4">
      <div className="max-w-5xl mx-auto px-4 space-y-6">
        <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-6">
          <div className="text-xs flex-1">
            <h6 className="font-medium text-gray-700 mb-1">File times (UK)</h6>
            <div className="text-gray-600 space-y-1">
              <p>
                Monday-Thursday: 8am-8:30pm (up to 1 hour turnaround after 6pm)
              </p>
              <p>Friday: 8am-6pm</p>
              <p>Saturday: 8:30am-4pm</p>
              <p>Sunday: Closed</p>
            </div>
          </div>
          <div className="text-xs flex-1">
            <h6 className="font-medium text-gray-700 mb-1">
              Support times (UK)
            </h6>
            <div className="text-gray-600 space-y-1">
              <p>Monday-Friday: 9am-6pm</p>
              <p>Saturday: 9am-4pm</p>
              <p>Sunday: Closed</p>
            </div>
          </div>
        </div>
        <p className="text-gray-600 text-xs">
          If you require a file out of these hours, please raise a support
          ticket and we will confirm if we can facilitate.
        </p>
        <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-6">
          {isTgt && (
            <div className="space-y-6">
              <a
                className="block max-w-[150px]"
                href="https://www.evc.de/en/check_evc_license.asp?k=ZmI8EAojveMdows2ftq48w%3d%3d"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="EVC badge"
                  src="https://www.evc.de/common/check_evc_license_image.asp?k=ZmI8EAojveMdows2ftq48w%3d%3d"
                />
              </a>
              <a
                className={`inline-block text-xs text-${primaryColorPalette}-600 my-2 leading-none sm:my-1 hover:underline`}
                href={import.meta.env.VITE_TERMS_PDF_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and conditions (PDF)
              </a>
            </div>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
