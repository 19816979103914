import React, { useState, useEffect } from 'react'

import useObjState from '../../../hooks/useObjState'
import usePrivateSocket from '../../../hooks/usePrivateSocket'
import Button from '../../Button'
import SystemStatus from './Status'
import AutoMessage from './AutoMessage'
import AutoPopUp from './AutoPopUp'
import {
  FETCH_SYSTEM,
  SYSTEM_UPDATED,
  UPDATE_SYSTEM,
} from '../../../utils/sockets'

const SystemSettings = () => {
  const [hasFetched, setHasFetched] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [hasEdits, setHasEdits] = useState(false)
  const [generalError, setGeneralError] = useState(false)
  const [systemCache, setSystemCache] = useState(false)
  const [system, setSystem] = useObjState({
    status: {
      active: false,
      color: 'GREEN',
      message: '',
    },
    autoMessage: {
      active: false,
      message: '',
    },
    autoPopUp: {
      active: false,
      title: '',
      content: '',
      buttonText: '',
      buttonHref: '',
    },
  })

  const [{ inRoom }, { socket }] = usePrivateSocket('/system', true)

  useEffect(() => {
    if (!hasFetched && inRoom) {
      socket.emit(FETCH_SYSTEM, (err, { system }) => {
        setHasFetched(true)
        setSystem(system)
        setSystemCache(system)
      })
    }
  }, [socket, hasFetched, inRoom, setSystem])

  useEffect(() => {
    if (socket) {
      socket.on(SYSTEM_UPDATED, (system) => {
        setSystem(system)
      })

      return () => socket.off(SYSTEM_UPDATED)
    }
  }, [socket, setSystem])

  const handleEdits = (change) => {
    if (!hasEdits) {
      setHasEdits(true)
    }

    if (saved) {
      setSaved(false)
    }

    setSystem(change)
  }

  const handleCancel = () => {
    setSystem(systemCache)
  }

  const handleSave = () => {
    setIsSaving(true)

    socket.emit(UPDATE_SYSTEM, system, (err, success) => {
      setIsSaving(false)

      if (success) {
        setSystemCache(system)
        setHasEdits(false)
        return setSaved(true)
      }

      if (err) {
        return setGeneralError(true)
      }
    })
  }

  if (!hasFetched) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  return (
    <div>
      <SystemStatus
        onChange={(change) =>
          handleEdits({ status: { ...system.status, ...change } })
        }
        isSaving={isSaving}
        status={system.status}
      />
      <AutoMessage
        onChange={(change) => {
          handleEdits({ autoMessage: { ...system.autoMessage, ...change } })
        }}
        isSaving={isSaving}
        autoMessage={system.autoMessage}
      />
      <AutoPopUp
        onChange={(change) => {
          handleEdits({ autoPopUp: { ...system.autoPopUp, ...change } })
        }}
        isSaving={isSaving}
        autoPopUp={system.autoPopUp}
      />
      <div className="flex items-center justify-between py-4 px-6">
        <div className="text-sm">
          {generalError ? (
            <p className="text-red-600">
              Error saving changes - please try again
            </p>
          ) : (
            <p className="text-gray-600">
              {saved && 'Your changes have been saved'}
              {isSaving && 'Saving your changes...'}
              {hasEdits && !saved && !isSaving && 'Please save your changes'}
              {!hasEdits && !saved && 'No changes have made'}
            </p>
          )}
        </div>
        <div className="space-x-3">
          <Button disabled={isSaving} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={
              (!system.status.message && system.status.active) ||
              (!system.autoMessage.message && system.autoMessage.active) ||
              ((!system.autoPopUp.title ||
                !system.autoPopUp.content ||
                !system.autoPopUp.buttonText ||
                !system.autoPopUp.buttonHref) &&
                system.autoPopUp.active) ||
              !hasEdits ||
              isSaving
            }
            onClick={handleSave}
          >
            {saved && 'Changes saved'}
            {isSaving && 'Saving...'}
            {!saved && !isSaving && 'Save changes'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SystemSettings
