import React from 'react'
import isEmail from 'validator/lib/isEmail'

const FormRequiredFields = (props) => {
  const {
    uploadType,
    originalUpload,
    vehicle,
    tuneData,
    mileage,
    warrantyData,
    filterData,
    files,
    modsData,
    egtDisclaimerAgreed,
    creditBalance,
    creditsToPurchase,
    creditsTotal,
  } = props

  return (
    <div className="bg-red-100 border border-red-300 p-4 my-4 rounded-md">
      <div className="text-sm">
        <p className="font-semibold tracking-tight text-red-800">
          Please review the following error(s):
        </p>
        <ul className="list-inside list-disc mt-2 space-y-1 text-red-800">
          {creditBalance + (creditsToPurchase || 0) < creditsTotal && (
            <li>You do not have enough credits</li>
          )}
          {uploadType === 'ADJUST' && !originalUpload && (
            <li>Must select an original upload for an adjustment remap</li>
          )}
          {!vehicle && <li>Must select a vehicle</li>}
          {!tuneData.ecuType && <li>Must enter an ECU type</li>}
          {!tuneData.tuningTool && <li>Must select the tuning tool used</li>}
          {tuneData.tuningTool === 'OTHER' && !tuneData.tuningToolOther && (
            <li>Must enter other tuning tool used</li>
          )}
          {!mileage && <li>Must enter vehicle mileage</li>}
          {warrantyData.required && !warrantyData.customerName && (
            <li>Must enter warranty customer name</li>
          )}
          {warrantyData.required && !isEmail(warrantyData.customerEmail) && (
            <li>Must enter warranty customer email</li>
          )}
          {filterData.required && !filterData.customerName && (
            <li>Must enter filter customer name</li>
          )}
          {filterData.required && !isEmail(filterData.customerEmail) && (
            <li>Must enter filter customer email</li>
          )}
          {filterData.required && !filterData.shippingAddress.line1 && (
            <li>Must enter filter customer address line 1</li>
          )}
          {filterData.required && !filterData.shippingAddress.postcode && (
            <li>Must enter filter customer address postcode</li>
          )}
          {filterData.required && !filterData.shippingAddress.country && (
            <li>Must enter filter customer address country</li>
          )}
          {files.length < 1 && <li>Must upload an ECU file</li>}
          {modsData.egt && !modsData.egtPCodes && (
            <li>Must provide EGT off P codes</li>
          )}
          {modsData.egt && !egtDisclaimerAgreed && (
            <li>Must agree to terms of EGT off disclaimer</li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default FormRequiredFields
