import React, { useState, useEffect } from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import NewPaymentMethodModal from '../../../components/PaymentMethod/NewPaymentMethodModal'
import CheckoutChangeMethodModal from './ModalChangePaymentMethod'
import CheckoutNoMethodsModal from './ModalNoPaymentMethods'
import Button from '../../../components/Button'
import PaymentMethod from '../../../components/PaymentMethod'
import PayPalButtons from './PayPalButtons'
import history from '../../../history'

const CheckoutPaymentMethods = (props) => {
  const {
    setPaymentMethod,
    setFetchMethodsError,
    setFetchedMethods,
    setPayPalSuccess,
    creditPackageId,
    discountId,
    addCreditsToDealer,
  } = props

  const [chosenIndex, setChosenIndex] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [changeMethodIndex, setChangeMethodIndex] = useState(null)
  const [noPaymentMethodModalOpen, setNoPaymentMethodModalOpen] =
    useState(false)
  const [newPaymentMethodModalOpen, setNewPaymentMethodModalOpen] =
    useState(false)

  const [{ res, success, error }, { sendRequest }] = usePrivateApi()

  useEffect(() => {
    sendRequest({ url: '/billing/payment-methods' })
  }, [sendRequest])

  useEffect(() => {
    if (success) {
      const { paymentMethods } = res.data

      const chosenIndex = paymentMethods.findIndex((method) => method.primary)
      setPaymentMethods(paymentMethods)
      setChosenIndex(chosenIndex)
      setFetchedMethods(true)

      if (paymentMethods.length < 1) {
        setNoPaymentMethodModalOpen(true)
      }
    }
  }, [res, success, setFetchedMethods])

  useEffect(() => {
    if (error) {
      setFetchMethodsError(error)
    }
  }, [error, setFetchMethodsError])

  useEffect(() => {
    if (chosenIndex !== null) {
      setPaymentMethod(paymentMethods[chosenIndex])
    }
  }, [chosenIndex, setPaymentMethod, paymentMethods])

  const renderOtherMethods = () => {
    if (paymentMethods.length < 2) {
      return (
        <div className="mt-4 bg-gray-100 border border-gray-300 rounded-md px-4 py-3">
          <p className="text-gray-900 text-sm">
            No more debit/credit cards in your account
          </p>
        </div>
      )
    }

    return paymentMethods.map((method, index) => {
      if (index !== chosenIndex) {
        return (
          <PaymentMethod
            key={method.id}
            method={method}
            onSelect={() => setChangeMethodIndex(index)}
          />
        )
      }
      return null
    })
  }

  if (chosenIndex === null) {
    return (
      <div className="flex items-center justify-center p-12 flex-1">
        <span className="spinner" />
      </div>
    )
  }

  if (paymentMethods.length < 1) {
    const handleOnClick = () => {
      setNoPaymentMethodModalOpen(false)
      setNewPaymentMethodModalOpen(true)
    }

    return (
      <>
        <CheckoutNoMethodsModal
          isOpen={noPaymentMethodModalOpen}
          onClick={handleOnClick}
        />
        <NewPaymentMethodModal
          isOpen={newPaymentMethodModalOpen}
          setIsOpen={() => history.push('/dashboard')}
          isPrimary
        />
      </>
    )
  }

  return (
    <>
      <h3 className="text-lg font-semibold tracking-tight mb-2">
        Choose your payment method
      </h3>
      <div className="space-y-6 sm:space-y-0 sm:flex sm:space-x-8">
        <div className="flex-1">
          <h5 className="text-base font-medium mb-3">Chosen payment method</h5>
          <PaymentMethod method={paymentMethods[chosenIndex]} isExpanded />
        </div>
        <div className="flex-1">
          <h5 className="text-base font-medium mb-3">Other payment methods</h5>
          {renderOtherMethods()}
          <div className="mt-4 flex flex-col items-end">
            <PayPalButtons
              setPayPalSuccess={setPayPalSuccess}
              creditPackageId={creditPackageId}
              discountId={discountId}
              addCreditsToDealer={addCreditsToDealer}
            />
            <Button
              className="mt-4"
              size="sm"
              onClick={() => setNewPaymentMethodModalOpen(true)}
            >
              Add new method
            </Button>
          </div>
        </div>
      </div>
      <NewPaymentMethodModal
        isOpen={newPaymentMethodModalOpen}
        setIsOpen={() => setNewPaymentMethodModalOpen(false)}
      />
      <CheckoutChangeMethodModal
        isOpen={changeMethodIndex !== null}
        setIsOpen={() => setChangeMethodIndex(null)}
        onConfirm={() => setChosenIndex(changeMethodIndex)}
        method={paymentMethods[changeMethodIndex]}
      />
    </>
  )
}

export default CheckoutPaymentMethods
