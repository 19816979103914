import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons'
import BrandingContext from '../../context/BrandingContext'

const DashboardAction = (props) => {
  const { icon, title, desc, to, linkText } = props

  const [branding] = useContext(BrandingContext)
  const { primaryColorPalette } = branding

  return (
    <Link
      className="border border-gray-200 rounded-md shadow-sm flex items-start py-4 px-4 space-x-3 group"
      to={to}
    >
      <FontAwesomeIcon
        className={`text-${primaryColorPalette}-600 mt-1`}
        icon={icon}
      />
      <div>
        <h5 className="text-base font-semibold tracking-tight">{title}</h5>
        <p className="text-sm mt-1 text-gray-600">{desc}</p>
        <p
          className={`font-medium text-sm text-${primaryColorPalette}-600 mt-3`}
        >
          {linkText}
          <FontAwesomeIcon
            className="ml-1 transition-all duration-100 group-hover:ml-2"
            icon={faLongArrowRight}
          />
        </p>
      </div>
    </Link>
  )
}

export default DashboardAction
