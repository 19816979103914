import React, { useState, useEffect } from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const ViewDealerCreditDeletePackageModal = (props) => {
  const { isOpen, setIsOpen, onSuccess, creditPackage } = props

  const [packageName, setPackageName] = useState('')

  const [{ res, success, error, isLoading }, { sendRequest, resetSuccess }] =
    usePrivateApi()

  useEffect(() => {
    if (isOpen) {
      if (creditPackage) {
        setPackageName(creditPackage.name)
      } else {
        setPackageName('')
      }
    }
  }, [isOpen, creditPackage])

  useEffect(() => {
    if (success) {
      onSuccess(res.data.creditPackages)
      resetSuccess()
    }
  }, [onSuccess, res, success, resetSuccess])

  const handleSubmit = () => {
    sendRequest({
      url: `/admin/credits/packages/${creditPackage.id}`,
      method: 'delete',
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm">
          There was an error deleting the credit package.
        </p>
        <Button className="mt-4" color="white" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Delete credit package"
      buttonProps={{
        onClick: handleSubmit,
        children: isLoading ? 'Deleting...' : 'Delete package',
        type: 'button',
        color: 'red',
        disabled: isLoading,
      }}
    >
      <p className="text-gray-600 text-sm">
        Are you sure you wish to delete the credit package: {packageName}?
      </p>
      <p className="mt-2 text-red-600 text-sm font-medium">
        This action is irreversible.
      </p>
    </Dialog>
  )
}

export default ViewDealerCreditDeletePackageModal
