import React, { useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import currency from 'currency.js'

import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const ConfirmPaymentModal = ({ isOpen, setIsOpen, tool }) => {
  const stripe = useStripe()
  const [isLoading, setIsLoading] = useState(false)
  const [paymentConfirmed, setPaymentConfirmed] = useState(null)
  const [paymentError, setPaymentError] = useState(null)

  const handleConfirmPayment = async () => {
    setIsLoading(true)

    const { error } = await stripe.confirmCardPayment(
      tool.paymentToConfirm.stripePaymentIntentClientSecret
    )

    setIsLoading(false)

    if (error) {
      return setPaymentError(error.message)
    }

    return setPaymentConfirmed(true)
  }

  if (paymentError) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Tool payment unsuccessful"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          We could not process your tool subscription payment for the following
          reason:{' '}
        </p>
        <p className="mt-2 text-gray-600 text-sm">{paymentError}</p>
        <p className="mt-2 text-gray-600 text-sm">
          Please resolve this issue and try again.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (paymentConfirmed) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Tool payment successful"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Your tool subscription payment was successful.
        </p>
        <p className="mt-2 text-gray-600 text-sm">
          We will renew your tool subscription shortly. It may take a few
          minutes for your tool subscription status to change.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Confirm tool payment"
      buttonProps={{
        disabled: isLoading,
        onClick: handleConfirmPayment,
        children: isLoading ? 'Confirming...' : 'Confirm payment',
        color: 'primary',
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        We tried to collect payment for your {tool.name} subscription renewal,
        however your card issuer has requested that you confirm the payment. The
        payment details are below:
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        <strong>Tool: {tool.name}</strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        <strong>Serial number: {tool.serialNumber}</strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        <strong>
          Renewal price: £{currency(tool.paymentToConfirm.amount).format()}
        </strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        <strong>
          Payment method: {tool.paymentToConfirm.paymentMethodBrand} ending{' '}
          {tool.paymentToConfirm.paymentMethodLast4}
        </strong>
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        If you wish to keep your tool subscription active please confirm this
        payment. Alternatively you can disable auto-renew to allow your tool
        subscription to expire.
      </p>
    </Dialog>
  )
}

export default ConfirmPaymentModal
