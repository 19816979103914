import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NewTicketFormIndex from './FormIndex'
import NewTicketError from './Error'
import NewTicketSuccess from './Success'

const NewTicket = () => {
  return (
    <Switch>
      <Route exact path="/support/new" component={NewTicketFormIndex} />
      <Route exact path="/support/new/error" component={NewTicketError} />
      <Route exact path="/support/new/success" component={NewTicketSuccess} />
      <Redirect to="/support/list/all" />
    </Switch>
  )
}

export default NewTicket
