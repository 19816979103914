import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'

import usePrivateApi from './usePrivateApi'

const useManualLookup = () => {
  const [make, _setMake] = useState(null)
  const [makeList, _setMakeList] = useState([])
  const [model, _setModel] = useState(null)
  const [modelList, _setModelList] = useState([])
  const [year, _setYear] = useState(null)
  const [yearList, _setYearList] = useState([])
  const [fuel, _setFuel] = useState(null)
  const [fuelList, _setFuelList] = useState([])
  const [engine, _setEngine] = useState()
  const [engineList, _setEngineList] = useState([])
  const [power, setPower] = useState(null)
  const [powerList, _setPowerList] = useState([])
  const [vehicle, _setVehicle] = useState(null)
  const [otherVehicles, _setOtherVehicles] = useState(null)

  const resetValues = keys => {
    if (keys.includes('MODEL')) {
      _setModel(null)
      _setModelList([])
    }

    if (keys.includes('YEAR')) {
      _setYear(null)
      _setYearList([])
    }

    if (keys.includes('FUEL')) {
      _setFuel(null)
      _setFuelList([])
    }

    if (keys.includes('ENGINE')) {
      _setEngine(null)
      _setEngineList([])
    }

    if (keys.includes('POWER')) {
      setPower(null)
      _setPowerList([])
    }
  }

  const [
    { res, isLoading, success, error, config },
    { sendRequest, resetSuccess, resetError }
  ] = usePrivateApi({ url: '/vehicles/makes' })

  const requestUrl = _.get(config, 'url', '')

  useEffect(() => {
    if (success) {
      if (res.data.makeList) {
        _setMakeList(res.data.makeList)
      }

      if (res.data.modelList) {
        _setModelList(res.data.modelList)
      }

      if (res.data.yearList) {
        _setYearList(res.data.yearList)
      }

      if (res.data.fuelList) {
        _setFuelList(res.data.fuelList)
      }

      if (res.data.engineList) {
        _setEngineList(res.data.engineList)
      }

      if (res.data.powerList) {
        _setPowerList(res.data.powerList)
      }

      if (res.data.vehicle) {
        _setVehicle(res.data.vehicle)
      }

      if (res.data.otherVehicles) {
        _setOtherVehicles(res.data.otherVehicles)
      }

      resetSuccess()
    }
  }, [res, success, resetSuccess])

  const setMake = useCallback(
    make => {
      const current = `make=${make}`
      _setMake(make)
      resetValues(['MODEL', 'YEAR', 'FUEL', 'ENGINE', 'POWER'])
      sendRequest({
        url: `/vehicles/models?${current}`
      })
    },
    [sendRequest]
  )

  const setModel = useCallback(
    model => {
      const previous = `make=${make}&`
      const current = `model=${model}`
      _setModel(model)
      resetValues(['YEAR', 'FUEL', 'ENGINE', 'POWER'])
      sendRequest({
        url: `/vehicles/years?${previous}${current}`
      })
    },
    [sendRequest, make]
  )

  const setYear = useCallback(
    year => {
      const previous = `make=${make}&model=${model}&`
      const current = `year=${year}`
      _setYear(year)
      resetValues(['FUEL', 'ENGINE', 'POWER'])
      sendRequest({
        url: `/vehicles/fuels?${previous}${current}`
      })
    },
    [sendRequest, make, model]
  )

  const setFuel = useCallback(
    fuel => {
      const previous = `make=${make}&model=${model}&year=${year}&`
      const current = `fuel=${fuel}`
      _setFuel(fuel)
      resetValues(['ENGINE', 'POWER'])
      sendRequest({
        url: `/vehicles/engines?${previous}${current}`
      })
    },
    [sendRequest, make, model, year]
  )

  const setEngine = useCallback(
    engine => {
      const previous = `make=${make}&model=${model}&year=${year}&fuel=${fuel}&`
      const current = `engine=${engine}`
      _setEngine(engine)
      resetValues(['POWER'])
      sendRequest({
        url: `/vehicles/power?${previous}${current}`
      })
    },
    [sendRequest, make, model, year, fuel]
  )

  const lookupVehicle = useCallback(() => {
    const makeQ = `make=${make}&`
    const modelQ = `model=${model}&`
    const yearQ = `year=${year}&`
    const fuelQ = `fuel=${fuel}&`
    const engineQ = `engine=${engine}&`
    const powerQ = `power=${power}`

    sendRequest({
      url: `/vehicles?${makeQ}${modelQ}${yearQ}${fuelQ}${engineQ}${powerQ}`
    })
  }, [sendRequest, make, model, year, fuel, engine, power])

  return [
    {
      make,
      makeList,
      model,
      modelList,
      year,
      yearList,
      fuel,
      fuelList,
      engine,
      engineList,
      power,
      powerList,
      vehicle,
      otherVehicles,
      isLoading,
      isFetchingVehicle: isLoading && requestUrl.includes('vehicles?'),
      error,
      success,
      requestUrl
    },
    {
      setMake,
      setModel,
      setYear,
      setFuel,
      setEngine,
      setPower,
      lookupVehicle,
      resetError,
      resetSuccess
    }
  ]
}

export default useManualLookup
