import React, { useContext } from 'react'
import cn from 'classnames'

import Chart from './Chart'
import TechnicalInfo from './TechnicalInfo'
import Disclaimer from './Disclaimer'
import useWindowWidth from '../../hooks/useWindowWidth'
import BrandingContext from '../../context/BrandingContext'

const VehicleSpecItem = ({ label, value, gain }) => (
  <div className="flex items-center justify-between mb-1">
    <span className="block text-sm font-medium text-gray-600">{label}</span>
    <span className="block text-sm font-semibold text-right">
      {gain && <span className="text-green-600 text-xs mr-2">{gain}</span>}
      {value}
    </span>
  </div>
)

const formatPerfValue = (value, unit) =>
  value === 0 ? 'Not available' : `${value} ${unit}`

const calculateGain = (original, tuned) => {
  if (tuned === 0 || original === 0) {
    return null
  }

  const gain = Math.round(((tuned - original) / original) * 100)
  return `${gain}% gain`
}

const hasPowerGains = (power) => {
  if (power.original !== 0 && power.stageOne !== 0) {
    return true
  }

  return false
}

const LookupResults = (props) => {
  const { vehicle, otherVehicles, vrm } = props

  const [branding] = useContext(BrandingContext)
  const { logoUrl } = branding

  const vpWidth = useWindowWidth()
  const hasGains = hasPowerGains(vehicle.power)

  return (
    <div>
      <div className="bg-white rounded-lg border border-gray-100 shadow p-8">
        <h2 className="text-xl sm:text-2xl font-bold tracking-tight">
          {vehicle.make} {vehicle.model} {vehicle.startYear} {vehicle.fuel}{' '}
          {vehicle.engineSize}cc {vehicle.power.original}HP {vrm && `(${vrm})`}
        </h2>
        {vpWidth >= 450 && hasGains && (
          <div className="flex mt-4 mb-10 items-center space-x-6">
            <div className="flex items-center space-x-2">
              <span className="rounded-full block h-1 w-5 bg-pink-600" />
              <span className="text-xs text-gray-700 uppercase block font-medium">
                Power
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="rounded-full block h-1 w-5 bg-teal-600" />
              <span className="text-xs text-gray-700 uppercase block font-medium">
                Torque
              </span>
            </div>
          </div>
        )}
        <div
          className={cn(
            'md:flex md:items-start md:justify-between md:space-x-4',
            { 'pt-4 md:pt-8': !hasGains }
          )}
        >
          {hasGains ? (
            <div className="relative flex-1">
              <Chart vehicle={vehicle} vpWidth={vpWidth} />
              <span
                style={
                  logoUrl ? { backgroundImage: `url('${logoUrl}')` } : undefined
                }
                className={cn(
                  'bg-no-repeat bg-center bg-[length:50%] inset-0 absolute',
                  {
                    "bg-[url('/src/assets/logos/logo@3x.png')] opacity-5":
                      !logoUrl,
                    'opacity-20': logoUrl,
                  }
                )}
              />
            </div>
          ) : (
            <div>
              <h5 className="text-lg font-semibold tracking-tight">
                Chart not available
              </h5>
              <p className="text-gray-600 text-sm mt-2 max-w-sm">
                We are unable to display a performance chart as we do not have
                gains data for this vehicle.
              </p>
            </div>
          )}
          <div className="pt-4 space-y-6 md:pt-0 md:min-w-[240px] md:max-w-[300px]">
            {vpWidth >= 450 && (
              <div className="mx-auto">
                <h5 className="text-sm font-semibold tracking-tight uppercase border-b border-gray-100 pb-1 mb-3">
                  Vehicle
                </h5>
                <VehicleSpecItem label="Make" value={vehicle.make} />
                <VehicleSpecItem label="Model" value={vehicle.model} />
                <VehicleSpecItem label="Year" value={vehicle.startYear} />
                <VehicleSpecItem label="Fuel type" value={vehicle.fuel} />
                <VehicleSpecItem
                  label="Engine size"
                  value={`${vehicle.engineSize}cc`}
                />
              </div>
            )}
            <div className="mx-auto">
              <h5 className="text-sm font-semibold tracking-tight uppercase border-b border-gray-100 pb-1 mb-3">
                Power
              </h5>
              <VehicleSpecItem
                label="Original"
                value={formatPerfValue(vehicle.power.original, 'BHP')}
              />
              <VehicleSpecItem
                label="Stage 1"
                gain={calculateGain(
                  vehicle.power.original,
                  vehicle.power.stageOne
                )}
                value={formatPerfValue(vehicle.power.stageOne, 'BHP')}
              />
              <VehicleSpecItem
                label="Stage 2"
                gain={calculateGain(
                  vehicle.power.original,
                  vehicle.power.stageTwo
                )}
                value={formatPerfValue(vehicle.power.stageTwo, 'BHP')}
              />
              <VehicleSpecItem
                label="Eco"
                gain={calculateGain(vehicle.power.original, vehicle.power.eco)}
                value={formatPerfValue(vehicle.power.eco, 'BHP')}
              />
            </div>
            <div className="mx-auto">
              <h5 className="text-sm font-semibold tracking-tight uppercase border-b border-gray-100 pb-1 mb-3">
                Torque
              </h5>
              <VehicleSpecItem
                label="Original"
                value={formatPerfValue(vehicle.torque.original, 'Nm')}
              />
              <VehicleSpecItem
                label="Stage 1"
                gain={calculateGain(
                  vehicle.torque.original,
                  vehicle.torque.stageOne
                )}
                value={formatPerfValue(vehicle.torque.stageOne, 'Nm')}
              />
              <VehicleSpecItem
                label="Stage 2"
                gain={calculateGain(
                  vehicle.torque.original,
                  vehicle.torque.stageTwo
                )}
                value={formatPerfValue(vehicle.torque.stageTwo, 'Nm')}
              />
              <VehicleSpecItem
                label="Eco"
                gain={calculateGain(
                  vehicle.torque.original,
                  vehicle.torque.eco
                )}
                value={formatPerfValue(vehicle.torque.eco, 'Nm')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-8 sm:space-y-0 sm:flex sm:space-x-12 mt-8">
        <div className="flex-1 space-y-8">
          <TechnicalInfo vehicle={vehicle} />
          {otherVehicles.length > 0 && (
            <div>
              <h4 className="text-base font-medium mb-4">
                Additional vehicles found
              </h4>
              <p className="text-gray-600 text-sm mb-2">
                There are multiple versions of this vehicle in our database,
                please see details below:
              </p>
              {otherVehicles.map((vehicle, i) => (
                <p className="text-gray-600 mb-2 text-sm" key={i}>
                  {vehicle}
                </p>
              ))}
            </div>
          )}
        </div>
        <div className="flex-1 space-y-8">
          <Disclaimer />
        </div>
      </div>
    </div>
  )
}

export default LookupResults
