import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons'

import usePrivateApi from '../../../../hooks/usePrivateApi'
import Button from '../../../../components/Button'
import Label from '../../../../components/Label'

const ChecklistItem = ({ label, ready }) => {
  return (
    <div className="flex items-center">
      {ready ? (
        <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />
      ) : (
        <FontAwesomeIcon icon={faTimesCircle} className="text-gray-500" />
      )}
      <p className="text-sm font-semibold ml-2 text-gray-900">{label}</p>
    </div>
  )
}

const WhiteLabelStatus = (props) => {
  const { dealer, whiteLabel, setWhiteLabel } = props

  const [
    { res, success, error, isLoading },
    { sendRequest, resetSuccess, resetError },
  ] = usePrivateApi()

  const items = [
    { label: 'Domain verified', ready: whiteLabel.domain.verified },
    { label: 'Email verified', ready: whiteLabel.email.verified },
    { label: 'Logo uploaded', ready: whiteLabel.logoUrl },
    { label: 'Primary colours chosen', ready: whiteLabel.primaryColorPalette },
    {
      label: 'Secondary colours chosen',
      ready: whiteLabel.secondaryColorPalette,
    },
  ]

  const handleEnable = () => {
    resetError()
    sendRequest({
      url: `/admin/dealers/${dealer.id}/white-label`,
      method: 'post',
    })
  }

  const handleDisable = () => {
    resetError()
    sendRequest({
      url: `/admin/dealers/${dealer.id}/white-label`,
      method: 'delete',
    })
  }

  useEffect(() => {
    if (success) {
      setWhiteLabel(res.data.whiteLabel)
      resetSuccess()
    }
  }, [setWhiteLabel, success, res, resetSuccess])

  return (
    <div
      className={
        'px-6 py-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start'
      }
    >
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight">Current status</h2>
      </div>
      <div className="flex-1">
        <div className="flex flex-col items-start space-y-4">
          <Label color={whiteLabel.status === 'ACTIVE' ? 'green' : 'gray'}>
            {whiteLabel.status === 'ACTIVE' ? 'Enabled' : 'Disabled'}
          </Label>
          {error && <p className="text-sm text-red-600">{error}</p>}
          {whiteLabel.status === 'ACTIVE' ? (
            <Button
              color="red-invert"
              disabled={isLoading || items.some((item) => !item.ready)}
              onClick={handleDisable}
              size="sm"
            >
              {isLoading ? 'Disabling...' : 'Disable white-label'}
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={isLoading || items.some((item) => !item.ready)}
              onClick={handleEnable}
              size="sm"
            >
              {isLoading ? 'Enabling...' : 'Enable white-label'}
            </Button>
          )}
        </div>
        <h3 className="text-sm text-gray-900 font-semibold tracking-tight mt-6 border-t border-gray-200 pt-6">
          White-label checklist
        </h3>
        <div className="space-y-3 mt-4">
          {items.map((item) => (
            <ChecklistItem {...item} key={item.label} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default WhiteLabelStatus
