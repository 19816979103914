import React, { useState, useEffect, useRef } from 'react'
import 'emoji-mart/css/emoji-mart.css'
import data from 'emoji-mart/data/apple.json'
import { NimblePicker } from 'emoji-mart'
import cn from 'classnames'

import useOnClickOutside from '../hooks/useOnClickOutside'

export const Picker = props => {
  const { isOpen, ...rest } = props

  const pickerCn = cn({ 'emoji-picker-open': isOpen })

  return (
    <span className={pickerCn}>
      <NimblePicker
        darkMode={false}
        color="#2B6CB0"
        showSkinTones={false}
        showPreview={false}
        set="apple"
        data={data}
        sheetSize={32}
        {...rest}
      />
    </span>
  )
}

export const EmojiPicker = ({ children, delayMount, onOpen, ...rest }) => {
  const [mountPicker, setMountPicker] = useState(!delayMount)
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  useOnClickOutside(ref, () => setIsOpen(false))

  // Due to performance issues when trying to mount all the emojis
  // from emoji-mart, we can pass the prop delayMount to only mount
  // the picker after all the other components in the parent tree have
  // rendered.
  useEffect(() => {
    const timer = setTimeout(() => setMountPicker(true), 500)
    return () => clearTimeout(timer)
  }, [])

  const togglePicker = e => {
    e.stopPropagation()

    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)

      if (onOpen) {
        onOpen()
      }
    }
  }

  return (
    <span ref={ref}>
      {React.cloneElement(children, { onClick: togglePicker })}
      {mountPicker && <Picker isOpen={isOpen} {...rest} />}
    </span>
  )
}
