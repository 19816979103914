import { objectToOptions } from './objects'
import { sub, startOfMonth, endOfMonth } from 'date-fns'

const UPLOAD = 'UPLOAD'
const SUPPORT = 'SUPPORT'
const DEALER = 'DEALER'
const USER = 'USER'
const VRM = 'VRM'
const VEHICLE = 'VEHICLE'

const reportLookup = {
  UPLOAD: 'Upload list',
  SUPPORT: 'Support ticket list',
  DEALER: 'Dealer list',
  USER: 'User list',
  VRM: 'VRM lookups',
  VEHICLE: 'Vehicle list'
}

export { UPLOAD, SUPPORT, DEALER, USER, VRM, VEHICLE }

export const getReport = key => getReport[key]
export const getReportOptions = () => objectToOptions(reportLookup)

export const getStartLastMonth = () => {
  return startOfMonth(sub(new Date(), { months: 1 }))
}

export const getEndLastMonth = () => {
  return endOfMonth(sub(new Date(), { months: 1 }))
}
