import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const SubdealerPlaceOnHoldModal = ({ isOpen, setIsOpen, subdealer }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/subdealers/${subdealer.id}`,
      method: 'patch',
      data: { status: 'ON_HOLD' },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not disable subdealer account. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Subdealer on hold"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Successfully placed <strong>{subdealer.name}</strong> on hold.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Place subdealer on hold"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Sending...' : 'Place on hold',
        color: 'red',
      }}
    >
      <p className="text-gray-600 text-sm mt-2">
        Are you sure you wish to place <strong>{subdealer.name}</strong> on
        hold?
      </p>
      <p className="text-gray-600 text-sm mt-2">
        This subdealer will still be able to view their existing uploads but
        will be prevented from creating new ones. You can re-activate their
        account in the future if required.
      </p>
    </Dialog>
  )
}

export default SubdealerPlaceOnHoldModal
