import React, { useCallback } from 'react'
import {
  faHistory,
  faSeedling,
  faBalanceScale,
  faTachometerAlt,
  faTachometerAltFastest,
  faFlagCheckered,
} from '@fortawesome/pro-regular-svg-icons'

import { getToolOptions } from '../../../utils/uploads'
import RadioCard from '../../RadioCard'
import CheckBox from '../../CheckBox'
import InputGroup from '../../InputGroup'
import Textarea from '../../Textarea'

const NewUploadTuneForm = (props) => {
  const { isLoading, modsData, setModsData, tuneData, setTuneData } = props

  const TuneContainer = useCallback(
    ({ children }) => (
      <div className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-2 lg:grid-cols-3">
        {children}
      </div>
    ),
    []
  )

  return (
    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-6 py-8">
      <div className="flex-[2]">
        <h3 className="text-xl font-semibold tracking-tight">ECU/Tune</h3>
        <div className="space-y-2 mt-1 text-gray-700 text-sm">
          <p>Enter the vehicle ECU type and tune required.</p>
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm px-6 py-5 flex-[3] space-y-4">
        <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
          <InputGroup
            label="ECU Type"
            id="ecuType"
            required
            className="flex-1"
            placeholder="Enter ECU type"
            disabled={isLoading}
            onChange={(e) => setTuneData({ ecuType: e.target.value })}
            value={tuneData.ecuType}
          />
          <InputGroup
            type="select"
            className="flex-1"
            label="Tuning tool used"
            id="tuningTool"
            onChange={(e) =>
              setTuneData({ tuningTool: e.target.value || null })
            }
            disabled={isLoading}
            required
            value={tuneData.tuningTool || ''}
            renderOptions={() => (
              <>
                <option>Select tuning tool</option>
                {getToolOptions()}
              </>
            )}
          />
        </div>
        {tuneData.tuningTool === 'OTHER' && (
          <InputGroup
            required
            className="col-6 pr-12"
            label="Other tuning tool used"
            id="tuningToolOther"
            placeholder="Enter tuning tool used"
            disabled={isLoading}
            onChange={(e) => setTuneData({ tuningToolOther: e.target.value })}
            value={tuneData.tuningToolOther}
          />
        )}
        <div>
          <p className="font-medium text-sm mb-2">
            File options. Select all that apply.
          </p>
          <div className="grid sm:grid-cols-2 gap-2 items-start">
            <CheckBox
              checked={modsData.dpf}
              onChange={() => setModsData({ dpf: !modsData.dpf })}
              label="DPF off"
              renderContent={() => (
                <InputGroup
                  label="P codes"
                  id="dpfPCodes"
                  disabled={isLoading}
                  onChange={(e) => setModsData({ dpfPCodes: e.target.value })}
                  value={modsData.dpfPCodes}
                />
              )}
            />
            <CheckBox
              checked={modsData.egr}
              onChange={() => setModsData({ egr: !modsData.egr })}
              label="EGR off"
              renderContent={() => (
                <InputGroup
                  label="P codes"
                  id="egrPCodes"
                  disabled={isLoading}
                  onChange={(e) => setModsData({ egrPCodes: e.target.value })}
                  value={modsData.egrPCodes}
                />
              )}
            />
            <CheckBox
              checked={modsData.o2}
              onChange={() => setModsData({ o2: !modsData.o2 })}
              label="O2 off"
              renderContent={() => (
                <InputGroup
                  label="P codes"
                  id="o2PCodes"
                  disabled={isLoading}
                  onChange={(e) => setModsData({ o2PCodes: e.target.value })}
                  value={modsData.o2PCodes}
                />
              )}
            />
            <CheckBox
              checked={modsData.ppf}
              onChange={() => setModsData({ ppf: !modsData.ppf })}
              label="PPF/OPF/GPF off"
              renderContent={() => (
                <InputGroup
                  label="P codes"
                  id="ppfPCodes"
                  disabled={isLoading}
                  onChange={(e) => setModsData({ ppfPCodes: e.target.value })}
                  value={modsData.ppfPCodes}
                />
              )}
            />
            <CheckBox
              checked={modsData.swirlFlap}
              onChange={() => setModsData({ swirlFlap: !modsData.swirlFlap })}
              label="Swirl flap off"
              renderContent={() => (
                <InputGroup
                  label="P codes"
                  id="swirlFlapPCodes"
                  disabled={isLoading}
                  onChange={(e) =>
                    setModsData({ swirlFlapPCodes: e.target.value })
                  }
                  value={modsData.swirlFlapPCodes}
                />
              )}
            />
            <CheckBox
              checked={modsData.hardcut}
              onChange={() => setModsData({ hardcut: !modsData.hardcut })}
              label="Hardcut limiter"
              renderContent={() => (
                <InputGroup
                  label="RPM limiter when driving"
                  id="hardRpm"
                  disabled={isLoading}
                  onChange={(e) => setModsData({ hardcutRpm: e.target.value })}
                  value={modsData.hardcutRpm}
                />
              )}
            />
            <CheckBox
              checked={modsData.adblue}
              onChange={() => setModsData({ adblue: !modsData.adblue })}
              label="Adblue delete"
              renderContent={() => (
                <>
                  <InputGroup
                    label="P codes"
                    id="adbluePCodes"
                    disabled={isLoading}
                    onChange={(e) =>
                      setModsData({ adbluePCodes: e.target.value })
                    }
                    value={modsData.adbluePCodes}
                  />
                  <p className="text-xs uppercase text-gray-900 font-semibold mt-3">
                    Important
                  </p>
                  <p className="text-xs text-gray-700 mt-1">
                    Dealer must carry out full resets.
                  </p>
                </>
              )}
            />
            <CheckBox
              checked={modsData.egt}
              onChange={() => setModsData({ egt: !modsData.egt })}
              label="EGT off"
              renderContent={() => (
                <>
                  <InputGroup
                    label="P codes"
                    id="egtPCodes"
                    required
                    disabled={isLoading}
                    onChange={(e) => setModsData({ egtPCodes: e.target.value })}
                    value={modsData.egtPCodes}
                  />
                  <p className="text-xs uppercase text-gray-900 font-semibold mt-3">
                    Important
                  </p>
                  <p className="text-xs text-gray-700 mt-1">
                    Please always unplug sensors.
                  </p>
                </>
              )}
            />
            <CheckBox
              checked={modsData.vmax}
              onChange={() => setModsData({ vmax: !modsData.vmax })}
              label="VMAX off"
            />
            <CheckBox
              checked={modsData.revLimiter}
              onChange={() => setModsData({ revLimiter: !modsData.revLimiter })}
              label="Rev limiter raised"
            />
          </div>
        </div>
        <div>
          <p className="font-medium text-sm mb-1 required">Tune required</p>
          <RadioCard
            label="Tune required"
            selected={tuneData.tuneRequired}
            setSelected={(item) => {
              setTuneData({ tuneRequired: item })
            }}
            Container={TuneContainer}
            items={[
              {
                label: 'Stock',
                description: 'Request a stock ECU file',
                icon: faHistory,
                value: 'STOCK',
              },
              {
                label: 'Eco (diesels)',
                description: 'Tune for fuel efficiency, up to 20% saving',
                icon: faSeedling,
                value: 'ECO',
              },
              {
                label: 'Blend (diesels)',
                description: 'Hybrid of more power and more MPG',
                icon: faBalanceScale,
                value: 'BLEND',
              },
              {
                label: 'Stage 1',
                description: 'Max performance with no hardware mods',
                icon: faTachometerAlt,
                value: 'STAGE1',
              },
              {
                label: 'Stage 2',
                description: 'For decat, intake and intercooler upgrades',
                icon: faTachometerAltFastest,
                value: 'STAGE2',
              },
              {
                label: 'Stage 3 (POA)',
                description: 'For hybrid turbos, data logs must be provided',
                icon: faFlagCheckered,
                value: 'STAGE3',
              },
            ]}
          />
        </div>
        <Textarea
          minRows={6}
          label="Details on tune required"
          id="tuneRequiredCustom"
          placeholder="Provide details of tune required including hardware modifications and any other requirements we should know about."
          onChange={(e) => setTuneData({ tuneRequiredCustom: e.target.value })}
          value={tuneData.tuneRequiredCustom}
          disabled={isLoading}
          required={tuneData.tuneRequired === 'CUSTOM'}
        />
      </div>
    </div>
  )
}

export default NewUploadTuneForm
