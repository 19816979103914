import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const SubdealerArchiveModal = ({ isOpen, setIsOpen, subdealer }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/subdealers/${subdealer.id}`,
      method: 'patch',
      data: { status: 'ARCHIVED' },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not archive subdealer account. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Subdealer archived"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Successfully archived <strong>{subdealer.name}</strong>.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Archive subdealer"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Archiving...' : 'Archive',
        color: 'red',
      }}
    >
      <p className="text-gray-600 text-sm mt-2">
        Are you sure you wish to archive <strong>{subdealer.name}</strong>?
      </p>
      <p className="text-gray-600 text-sm mt-2">
        This subdealer will no longer be able to log into their account. You can
        re-activate their account in the future if required.
      </p>
    </Dialog>
  )
}

export default SubdealerArchiveModal
