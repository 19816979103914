import React from 'react'

import usePrivateApi from '../../../../../hooks/usePrivateApi'
import Button from '../../../../../components/Button'
import Dialog from '../../../../../components/Dialog'

const UsersMakeOwnerModal = (props) => {
  const { dealerId, isOpen, setIsOpen, membership } = props

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/admin/dealers/${dealerId}/users/${membership.id}`,
      method: 'patch',
      data: { isOwner: true },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not transfer dealer ownership. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Made user the dealer owner"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          You have successfully transferred dealer ownership to{' '}
          <strong>
            {membership.user.firstName} {membership.user.lastName}
          </strong>{' '}
          ({membership.user.email}).
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Transfer dealer ownership"
      buttonProps={{
        disabled: isLoading,
        children: isLoading ? 'Transferring...' : 'Transfer',
        color: 'primary',
        onClick: handleSubmit,
      }}
    >
      <p className="mt-2 text-gray-600 text-sm">
        Are you sure you wish to transfer dealer ownership to{' '}
        <strong>
          {membership.user.firstName} {membership.user.lastName}
        </strong>
        ?
      </p>
      <p className="mt-2 text-gray-600 text-sm">
        We may restrict certain system features for non-owners in the future.
      </p>
    </Dialog>
  )
}

export default UsersMakeOwnerModal
