import React from 'react'

import usePrivateApi from '../../../hooks/usePrivateApi'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

const SubdealerReactivateModal = ({ isOpen, setIsOpen, subdealer }) => {
  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    sendRequest({
      url: `/settings/subdealers/${subdealer.id}`,
      method: 'patch',
      data: { status: 'ACTIVE' },
    })
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Could not re-activate subdealer account. Please try again later.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Account re-activated"
        buttonProps={null}
      >
        <p className="text-gray-600 text-sm mt-2">
          Successfully re-activated <strong>{subdealer.name}'s</strong> account.
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Re-activate subdealer account"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Activating...' : 'Activate account',
        color: 'primary',
      }}
    >
      <p className="text-gray-600 text-sm mt-2">
        <strong>
          Are you sure you wish to re-activate {subdealer.name}'s account?
        </strong>
      </p>
      <p className="text-gray-600 text-sm mt-2">
        This subdealer will regain full access to their account and will be able
        to create new uploads.
      </p>
    </Dialog>
  )
}

export default SubdealerReactivateModal
