import React, { useCallback, useEffect } from 'react'

import ColorPaletteOption from './ColorPaletteOption'
import useEditableForm from '../../../../hooks/useEditableForm'
import { colorKeys } from './ColorPaletteKeys'
import { RadioGroup } from '@headlessui/react'
import Button from '../../../../components/Button'

const colors = Object.keys(colorKeys)

const ColorPalettePrimary = ({ dealer, whiteLabel, setWhiteLabel }) => {
  const mapResData = useCallback(
    (data) => ({
      primaryColorPalette: data.whiteLabel.primaryColorPalette,
    }),
    []
  )

  const [
    {
      formValues,
      hasSaved,
      showNoChanges,
      showChangesMade,
      showChangesSaving,
      showChangesSaved,
      saveError,
    },
    { setFormValues, resetFormValues, saveFormValues },
  ] = useEditableForm({
    initialValues: {
      primaryColorPalette: whiteLabel.primaryColorPalette,
    },
    mapResData,
  })

  const handleSubmit = () => {
    saveFormValues({
      url: `/admin/dealers/${dealer.id}/white-label`,
      method: 'patch',
      data: formValues,
    })
  }

  const handleCancel = () => {
    resetFormValues()
  }

  useEffect(() => {
    if (hasSaved) {
      setWhiteLabel({ primaryColorPalette: formValues.primaryColorPalette })
    }
  }, [hasSaved, setWhiteLabel, formValues])

  return (
    <>
      <div
        className={
          'border-b border-gray-200 px-6 py-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start'
        }
      >
        <div className="max-w-sm flex-1">
          <h2 className="text-lg font-semibold tracking-tight">
            Colour palette (primary)
          </h2>
          <div className="text-gray-600 text-sm space-y-2 max-w-prose">
            <p>
              Please select the most appropriate primary colour palette for this
              dealer.
            </p>
            <p>
              A colour palette is a pre-defined list of colours that will be
              used across the portal. These pre-defined colours are tested to
              ensure the portal looks great no matter the branding style of the
              master dealer.
            </p>
          </div>
        </div>
        <RadioGroup
          className="space-y-4"
          value={formValues.primaryColorPalette}
          onChange={(color) => setFormValues({ primaryColorPalette: color })}
        >
          {colors.map((color, index) => (
            <RadioGroup.Option
              value={color}
              key={color}
              className="focus:outline-none"
            >
              {({ checked, active }) => (
                <ColorPaletteOption
                  isLast={index === colors.length - 1}
                  color={color}
                  checked={checked}
                  active={active}
                />
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
      <div className="py-4 px-6 sm:flex sm:items-center sm:justify-between">
        <div className="text-sm">
          {saveError ? (
            <p className="text-red-600">
              Error saving changes - please try again
            </p>
          ) : (
            <p className="text-gray-600">
              {showChangesSaved && 'Your changes have been saved'}
              {showChangesSaving && 'Saving your changes...'}
              {showChangesMade && 'Please save your changes'}
              {showNoChanges && 'No changes have made'}
            </p>
          )}
        </div>
        <div className="mt-4 flex flex-col-reverse sm:block sm:mt-0 sm:space-x-3">
          <Button
            className="mt-3 w-full justify-center sm:w-auto sm:mt-0"
            disabled={!showChangesMade}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="w-full justify-center sm:w-auto"
            disabled={!showChangesMade || showChangesSaving}
            onClick={handleSubmit}
          >
            {showChangesSaved && 'Changes saved'}
            {showChangesSaving && 'Saving...'}
            {(showNoChanges || showChangesMade) && 'Save changes'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default ColorPalettePrimary
