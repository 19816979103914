import React, { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'

import BillingVat from './Vat'
import BillingPaymentMethodList from './PaymentMethodList'
import BillingDocs from './DocList'
import AuthContext from '../../../context/AuthContext'
import Button from '../../../components/Button'

const Billing = () => {
  const [fetchedVat, setFetchedVat] = useState(false)
  const [fetchedMethods, setFetchedMethods] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [auth] = useContext(AuthContext)
  const { toolsConfirmationNeeded } = auth.membership.dealer

  const isLoading = toolsConfirmationNeeded
    ? !fetchedMethods
    : !fetchedVat || !fetchedMethods

  const showContent = !isLoading && !fetchError

  return (
    <>
      {isLoading && !fetchError && (
        <div className="flex items-center justify-center p-12 flex-1">
          <span className="spinner" />
        </div>
      )}
      {fetchError && (
        <div className="flex items-center justify-center flex-1 flex-col p-18">
          <FontAwesomeIcon
            icon={faWifiSlash}
            className="text-gray-600"
            size="2x"
          />
          <h3 className="text-xl font-semibold tracking-tighter mt-4">
            Could not retrieve account settings
          </h3>
          <p className="text-sm text-gray-600 mt-1 mb-6">
            Please refresh the page to try again.
          </p>
          <Button color="white" onClick={() => window.location.reload()}>
            Refresh page
          </Button>
        </div>
      )}
      {!toolsConfirmationNeeded && (
        <BillingVat
          setFetchedVat={setFetchedVat}
          setFetchError={setFetchError}
          showContent={showContent}
        />
      )}
      <BillingPaymentMethodList
        setFetchedMethods={setFetchedMethods}
        setFetchError={setFetchError}
        showContent={showContent}
      />
      {!toolsConfirmationNeeded && (
        <BillingDocs setFetchError={setFetchError} showContent={showContent} />
      )}
    </>
  )
}

export default Billing
