import React, { useState } from 'react'

import Dialog from '../../../Dialog'
import PaymentMethod from '../../../PaymentMethod'

const NewUploadPaymentMethodModal = (props) => {
  const { isOpen, setIsOpen, chosenMethodIndex, paymentMethods, onConfirm } =
    props

  const [selected, setSelected] = useState(chosenMethodIndex)

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Change add-on payment method"
      buttonProps={{
        onClick: () => onConfirm(selected),
        disabled: selected === null,
        children: 'Use this card',
        color: 'primary',
      }}
    >
      <div className="mt-2 space-y-3">
        {paymentMethods.map((method, index) => (
          <PaymentMethod
            selected={selected === index}
            method={method}
            onSelect={() => setSelected(index)}
            noActions
            key={method.id}
          />
        ))}
      </div>
    </Dialog>
  )
}

export default NewUploadPaymentMethodModal
