import React from 'react'

import InputGroup from '../../InputGroup'

const AccountEmail = ({
  formValues,
  setFormValues,
  disabled,
  emailError,
  resetEmailError,
}) => {
  const handleEmailChange = (e) => {
    resetEmailError()
    setFormValues({ email: e.target.value })
  }

  return (
    <div className="border-b border-gray-200 px-6 pt-8 pb-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-8 sm:items-start">
      <div className="max-w-sm flex-1">
        <h2 className="text-lg font-semibold tracking-tight mb-2">
          Your email
        </h2>
        <div className="text-gray-600 text-sm space-y-2 max-w-prose">
          <p>
            Change the email address linked to your account. Once changed you
            must use your new email address to login.
          </p>
          <p>All communications from us will be sent to the new address.</p>
        </div>
      </div>
      <div className="flex-1 space-y-4">
        <InputGroup
          required
          className="flex-1"
          error={emailError}
          label="Email"
          id="email"
          disabled={disabled}
          onChange={handleEmailChange}
          value={formValues.email}
        />
      </div>
    </div>
  )
}

export default AccountEmail
