import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

import useLocalStorage from '../../../hooks/useLocalStorage'

const API_URL = import.meta.env.VITE_API_URL

const PayPalButtons = (props) => {
  const { setPayPalSuccess, creditPackageId, discountId, addCreditsToDealer } =
    props

  const [getAuthToken] = useLocalStorage('authToken')

  const PayPalButton = window.paypal.Buttons.driver('react', {
    React,
    ReactDOM,
  })

  const createOrder = async () => {
    try {
      const authToken = getAuthToken()

      const res = await axios({
        url: '/billing/credits/checkout/paypal',
        method: 'post',
        data: { creditPackageId, discountId },
        baseURL: API_URL,
        headers: { Authorization: `Bearer ${authToken}` },
      })

      return res.data.payPalOrderId
    } catch (err) {
      return
    }
  }

  const onApprove = async (data, actions) => {
    await actions.order.capture()
    setPayPalSuccess(true)
    addCreditsToDealer()
  }

  return (
    <div className="bg-white rounded-md border border-gray-200 px-3 py-3 z-10">
      <PayPalButton
        createOrder={createOrder}
        onApprove={onApprove}
        style={{
          layout: 'vertical',
          color: 'blue',
          tagline: false,
          label: 'pay',
          height: 40,
        }}
      />
      <p className="text-xs text-gray-600 pb-3">
        An additional fee of 1.5% plus 50p will be added to the credit bundle
        cost stated below if you decide to pay via PayPal or any "Powered by
        PayPal" methods above.
      </p>
    </div>
  )
}

export default PayPalButtons
