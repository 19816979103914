import React from 'react'

import { getShippingCountryOptions } from '../../../../utils/address'
import TextButton from '../../../TextButton'
import CheckBox from '../../../CheckBox'
import InputGroup from '../../../InputGroup'

const NewUploadAddonFilter = (props) => {
  const {
    isLoading,
    dealerAddress,
    copyWarrantyData,
    showCopyWarrantyData,
    vehicle,
    filterData,
    setFilterData,
  } = props

  const getLabel = () => {
    if (vehicle.filtersNeeded === 1) {
      return (
        <>
          High-flow TGT filter (£24.99){' '}
          <span className="text-xs text-gray-600">RRP £49.99</span>
        </>
      )
    } else if (vehicle.filtersNeeded === 2) {
      return (
        <>
          2x High-flow TGT filter (£49.99){' '}
          <span className="text-xs text-gray-600">RRP £99.99</span>
        </>
      )
    }
  }

  const copyDealerAddress = () => {
    setFilterData({ shippingAddress: dealerAddress })
  }

  const onAddressChange = (change) => {
    setFilterData({
      shippingAddress: { ...filterData.shippingAddress, ...change },
    })
  }

  return (
    <CheckBox
      checked={filterData.required}
      hideDetails={!filterData.required}
      onChange={() => setFilterData({ required: !filterData.required })}
      label={getLabel()}
      description="Order a Topgear Tuning designed performance air filter (UK and IE only)."
      renderContent={() => (
        <>
          <p className="text-uppercase text-xs font-semibold uppercase mb-2">
            Customer details{' '}
            {showCopyWarrantyData && (
              <TextButton size="xs" className="ml-2" onClick={copyWarrantyData}>
                Copy from warranty
              </TextButton>
            )}
          </p>
          <div className="space-y-4">
            <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
              <InputGroup
                className="flex-1"
                required
                label="Name"
                placeholder="Enter customer's name"
                id="filterName"
                disabled={isLoading}
                onChange={(e) =>
                  setFilterData({ customerName: e.target.value })
                }
                value={filterData.customerName}
              />
              <InputGroup
                className="flex-1"
                required
                label="Email"
                placeholder="Enter customer's email"
                id="filterEmail"
                disabled={isLoading}
                onChange={(e) =>
                  setFilterData({ customerEmail: e.target.value })
                }
                value={filterData.customerEmail}
              />
            </div>
            <div>
              <p className="text-uppercase text-xs font-semibold uppercase mb-2">
                Shipping address
                <TextButton
                  size="xs"
                  className="ml-2"
                  onClick={copyDealerAddress}
                >
                  Use my business address
                </TextButton>
              </p>
              <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
                <InputGroup
                  className="flex-1"
                  required
                  label="Address line one"
                  placeholder="Enter address line one"
                  id="filterAddressLineOne"
                  disabled={isLoading}
                  onChange={(e) => onAddressChange({ line1: e.target.value })}
                  value={filterData.shippingAddress.line1}
                />
                <InputGroup
                  className="flex-1"
                  label="Address line two"
                  placeholder="Enter address line two"
                  id="filterAddressLineTwo"
                  disabled={isLoading}
                  onChange={(e) => onAddressChange({ line2: e.target.value })}
                  value={filterData.shippingAddress.line2}
                />
              </div>
            </div>
            <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
              <InputGroup
                className="flex-1"
                label="City/town"
                placeholder="Enter city/town"
                id="filterAddressCity"
                disabled={isLoading}
                onChange={(e) => onAddressChange({ city: e.target.value })}
                value={filterData.shippingAddress.city}
              />
              <InputGroup
                className="flex-1"
                label="Region/county"
                placeholder="Enter region/county"
                id="filterAddressRegion"
                disabled={isLoading}
                onChange={(e) => onAddressChange({ region: e.target.value })}
                value={filterData.shippingAddress.region}
              />
            </div>
            <div className="space-y-4 lg:space-y-0 lg:flex lg:flex-1 lg:space-x-3">
              <InputGroup
                className="flex-1"
                required
                label="Postcode"
                placeholder="Enter postcode"
                id="filterAddressPostcode"
                disabled={isLoading}
                onChange={(e) => onAddressChange({ postcode: e.target.value })}
                value={filterData.shippingAddress.postcode}
              />
              <InputGroup
                required
                className="flex-1"
                disabled={isLoading}
                type="select"
                label="Country"
                id="filterAddressCountry"
                onChange={(e) => onAddressChange({ country: e.target.value })}
                renderOptions={getShippingCountryOptions}
                value={filterData.shippingAddress.country}
              />
            </div>
          </div>
        </>
      )}
    />
  )
}

export default NewUploadAddonFilter
