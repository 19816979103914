import React from 'react'

import CheckBox from '../../../CheckBox'

const NewUploadAddonPriority = (props) => {
  const { isLoading, priority, setPriority } = props

  return (
    <CheckBox
      checked={priority}
      disabled={isLoading}
      description="Mark this upload as a priority file and receive a faster turnaround."
      onChange={() => setPriority(!priority)}
      label={<>Priority file (0.5 credit)</>}
    />
  )
}

export default NewUploadAddonPriority
