import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Title from '../../components/Title'
import BoxNav from '../../components/BoxNav'
import StandardPackages from './packages'
import DiscountCodes from './discounts'

const boxNavLinks = [
  { to: '/credits/packages', label: 'Standard packages' },
  { to: '/credits/discounts', label: 'Discount codes' },
]

const Credits = ({ location }) => {
  return (
    <>
      <Title title="Credits" />
      <div className="max-w-5xl mx-auto px-4">
        <div className="bg-white rounded-lg border border-gray-200 flex flex-col min-h-[500px] shadow-sm">
          <div className="border-b border-gray-200 rounded-tr-lg rounded-tl-lg">
            <BoxNav current={location.pathname} links={boxNavLinks} />
          </div>
          <Switch>
            <Route path="/credits/packages" component={StandardPackages} />
            <Route path="/credits/discounts" component={DiscountCodes} />
            <Redirect to="/credits/packages" />
          </Switch>
        </div>
      </div>
    </>
  )
}

export default Credits
