import React from 'react'

const ViewUploadDataItem = ({ label, value, renderValue }) => {
  return (
    <div className="pr-2 pt-2 mb-2 sm:flex sm:items-start">
      <p className="text-gray-600 text-sm flex-1">{label}</p>
      <p className="font-medium text-sm flex-1">
        {renderValue ? renderValue() : value}
      </p>
    </div>
  )
}

export default ViewUploadDataItem
