import React from 'react'

import usePrivateApi from '../../hooks/usePrivateApi'
import Button from '../Button'
import Dialog from '../Dialog'

const PaymentMethodPrimaryModal = (props) => {
  const { admin, dealerId, isOpen, setIsOpen, method } = props

  const [{ isLoading, success, error }, { sendRequest }] = usePrivateApi()

  const handleSubmit = () => {
    if (admin) {
      sendRequest({
        url: `/admin/dealers/${dealerId}/payment-methods/${method.id}`,
        method: 'patch',
        data: { primary: true },
      })
    } else {
      sendRequest({
        url: `/billing/payment-methods/${method.id}`,
        method: 'patch',
        data: { primary: true },
      })
    }
  }

  if (error) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="An error occurred"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          Could not change primary payment method. Please try again later.
        </p>
        <Button onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  if (success) {
    return (
      <Dialog
        isOpen={isOpen}
        setIsOpen={() => window.location.reload()}
        title="Changed primary payment method"
        buttonProps={null}
      >
        <p className="mt-2 text-gray-600 text-sm">
          <strong>
            {method.card.brand.toUpperCase()} ending {method.card.last4}
          </strong>{' '}
          {admin
            ? "is now the dealer's primary payment method."
            : 'is now your primary payment method.'}
        </p>
        <Button className="mt-4" onClick={() => window.location.reload()}>
          Finish and close
        </Button>
      </Dialog>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      title="Change primary method"
      buttonProps={{
        disabled: isLoading,
        onClick: handleSubmit,
        children: isLoading ? 'Saving...' : 'Make primary',
      }}
    >
      {admin ? (
        <>
          <p className="mt-2 text-gray-600 text-sm">
            <strong>
              Are you sure you wish to make this card the dealer's primary
              payment method?
            </strong>
          </p>
          <p className="mt-2 text-gray-600 text-sm">
            This payment method will be the dealer's default payment method when
            purchasing credit packages and any add-ons selected while uploading
            an ECU file.
          </p>
        </>
      ) : (
        <>
          <p className="mt-2 text-gray-600 text-sm">
            <strong>
              Are you sure you wish to make this card your primary payment
              method?
            </strong>
          </p>
          <p className="mt-2 text-gray-600 text-sm">
            This payment method will be your default payment method when
            purchasing credit packages and will be used for any add-ons you
            purchase while uploading an ECU file.
          </p>
        </>
      )}
    </Dialog>
  )
}

export default PaymentMethodPrimaryModal
