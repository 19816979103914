import React from 'react'

import ViewDealerCreditBalance from './Balance'
import ViewDealerCreditBaseCost from './BaseCost'
import ViewDealerCreditPackages from './Packages'

const ViewDealerCredits = ({ dealer, setDealer, socket }) => {
  const isMaster = dealer.type === 'MASTER'

  return (
    <div className="divide-y divide-gray-200">
      <ViewDealerCreditBalance
        dealer={dealer}
        setDealer={setDealer}
        socket={socket}
      />
      {isMaster && (
        <>
          <ViewDealerCreditBaseCost
            dealer={dealer}
            setDealer={setDealer}
            socket={socket}
          />
          <ViewDealerCreditPackages
            dealer={dealer}
            setDealer={setDealer}
            socket={socket}
          />
        </>
      )}
    </div>
  )
}

export default ViewDealerCredits
